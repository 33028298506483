import { Box, Modal } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  AddAgencyBooking,
  AddAppoientmenttype,
  AddClientRequirement,
  AddInsurance,
  AddProviderType,
  DeleteClientRequirement,
  FetchAppointmentType,
  GetAgencyBooking,
  GetClientRequirement,
  GetInsurance,
  GetProviderType,
} from "../../../api/helper";
import { useSelector } from "react-redux";
import Inputbox from "../../Components/Inputbox";
import { ShowToast } from "../../../helpers/ToastService";
import { DropDownManuCustomMulti } from "../../Components/DropDownButton";

const validationSchema = Yup.object().shape({
  providerTypeId:Yup.array()
  .of(Yup.string().required("A Provider Type must be selected"))
  .min(1, "At least one Provider Type is required")
  .required("Provider Type is required"),
  insuranceId:Yup.array()
  .of(Yup.string().required("A Insurance must be selected"))
  .min(1, "At least one Insurance is required")
  .required("Insurance is required"),
  treatmentTypesId:Yup.array()
  .of(Yup.string().required("A Service Type must be selected"))
  .min(1, "At least one Service Type is required")
  .required("Service Type is required"),
  name: Yup.string().required("Requirement is required"),
  issueDate: Yup.string().required("Issue Date is required"),
  expirationDate: Yup.string().required("Expiration Date is required"),
});
const ClientRequirements = () => {
  const [requirementdata, setRequirementdata] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [providertypedata, setprovidertypedata] = useState("");
  const [insuranceinput, setinsuranceinput] = useState("");
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [data, setdata] = useState([]);
  const [insurancedata, setinsurancedata] = useState([]);
  const [servicedata, setservicedata] = useState([]);
  const [serviceinput, setserviceinput] = useState("");
  const [checkboxValues, setCheckboxValues] = useState({
    ClientIntakeForm: false,
    ConsentForm: false,
    TreatmentAuthorization: false,
    History: false,
    SuicideRiskAssessment: false,
    ViolenceRiskAssessment: false,
    SubstanceAbuseRiskAssessment: false,
    MentalHealthAssessment: false,
    PatientHealthQuestionnaire: false,
    GeneralAnxietyDisorder: false,
    MoodDisorderQuestionaire: false,
    IndividualizedTreatmentPlan: false,
    CareCoordinationPlan: false,
    CrisisManagementPlan: false,
    NewManagementPlan: false,
    HIPAAAgreement: false,
    InformedConsent: false,
    ConsentReleasingInformation: false,
    FinancialAgreement: false,
    InsuranceClaimAgreement: false,
    CreditCardAuthorization: false,
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxValues((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "73%",
    height: "95vh",
    overflowY: "auto", // Set a fixed height for the modal
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    outline: "none",
    // Enable vertical scrolling
  };
  const GetAppoientmenttype = async () => {
    const response = await GetProviderType(`?id=${profiledata?._id}`);
    console.log(response?.data?.data, "data=====");
    setdata(response?.data?.data);
  };
  const Getinsurancedata = async () => {
    const response = await GetInsurance(`?id=${profiledata?._id}`);
    // console.log(response?.data?.data, "GetInsurance=====");
    let resData = response?.data?.data;
    resData = resData?.map((e) =>
      e.adminInsurenceId ? { ...e, name: e.adminInsurenceId.name } : e
    );
    setinsurancedata(resData);
  };
  const Getservicedata = async () => {
    const response = await GetAgencyBooking(`?id=${profiledata?._id}`);
    // console.log(response?.data?.data, "GetInsurance=====");
    setservicedata(response?.data?.data);
  };
  const handleDelete = async (id) => {
    console.log("Deleted ID:", id);

    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) {
      return;
    }

    try {
      // const response = await DeleteCredentials(`?id=${id}`);
      const response = await  DeleteClientRequirement(`?id=${id}`);

      if (response?.data?.success) {
        ShowToast(response?.data?.message);
        FetchAddresslist();
      } else {
        ShowToast("Error: Unable to delete address.");
      }
    } catch (error) {
      console.error("Delete operation failed:", error);
      ShowToast("An error occurred while deleting the address.");
    }
  };

  const FetchAddresslist = async () => {
    // console.log(profiledata?._id,"profiledata?._id")
    try {
      // const response = await GetCredentials(`?id=${profiledata?._id}`);
      const response = await GetClientRequirement(`?id=${profiledata?._id}`);

      console.log(response?.data?.data, "GetClientRequirement======");
      setRequirementdata(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    FetchAddresslist();
  }, []);
  useEffect(() => {
    GetAppoientmenttype();
    Getinsurancedata();
    Getservicedata();
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = async(data) => {
    data.agencyId = profiledata?._id;
  const mergedData = {
    ...data,
    ...checkboxValues, 
  
  };
  // mergedData.agencyId =
  console.log("Merged Data:", mergedData);

  try {
    // const response = await AddCredentials(data);
    const response = await AddClientRequirement(data);
console.log(response?.data?.data,"data=====rep======")
    ShowToast(response?.data?.message);
    reset();
    FetchAddresslist();
    setShowModal(false);
   setCheckboxValues({
      ClientIntakeForm: false,
      ConsentForm: false,
      TreatmentAuthorization: false,
      History: false,
      SuicideRiskAssessment: false,
      ViolenceRiskAssessment: false,
      SubstanceAbuseRiskAssessment: false,
      MentalHealthAssessment: false,
      PatientHealthQuestionnaire: false,
      GeneralAnxietyDisorder: false,
      MoodDisorderQuestionaire: false,
      IndividualizedTreatmentPlan: false,
      CareCoordinationPlan: false,
      CrisisManagementPlan: false,
      NewManagementPlan: false,
      HIPAAAgreement: false,
      InformedConsent: false,
      ConsentReleasingInformation: false,
      FinancialAgreement: false,
      InsuranceClaimAgreement: false,
      CreditCardAuthorization: false,
    });
    
  } catch (error) {
    console.log(error);
  }
  };

  const onProvidertypeSubmit = async (data) => {
    if (data.name === "") {
      ShowToast("Please Enter Provider Type");
      return;
    }
    data.agencyId = profiledata?._id;
    console.log(data, "hiiii======");

    try {
      const response = await AddProviderType(data);
      console.log(response?.data?.data, "respones====");
      ShowToast(response?.data?.message);
      setprovidertypedata("");
      GetAppoientmenttype();
    } catch (error) {
      console.log(error);
    }
  };
  const oninsuranceSubmit = async (data) => {
    if (data.name === "") {
      ShowToast("Please Enter Insurances ");
      return;
    }
    data.agencyId = profiledata?._id;
    console.log(data, "hiiii======");

    try {
      const response = await AddInsurance(data);
      console.log(response?.data?.data, "respones====");
      ShowToast(response?.data?.message);
      setinsuranceinput("");
      Getinsurancedata();
    } catch (error) {
      console.log(error);
    }
  };
  const ondervicetypeSubmit = async (data) => {
    if (data.name === "") {
      ShowToast("Please Enter Service Type ");
      return;
    }
    data.agencyId = profiledata?._id;
    console.log(data, "hiiii======");

    try {
      const response = await AddAgencyBooking(data);
      console.log(response?.data?.data, "respones====");
      ShowToast(response?.data?.message);
      setservicedata("");
      Getservicedata();
    } catch (error) {
      console.log(error);
    }
  };
  const handleProvider = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("providerTypeId", ids, { shouldValidate: true });
  };
  const handleinsurance = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("insuranceId", ids, { shouldValidate: true });
  };
  const handleservicedata = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("treatmentTypesId", ids, { shouldValidate: true });
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <div></div>
        <div
          className="Add_button__"
          style={{ width: 150 }}
          onClick={openModal}
        >
          Add Client Requirements
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Provider Type</th>
            <th>Requirement</th>
            <th>Description</th>
            <th>Issue Date</th>
            <th>Expiration Date</th>
            <th>Within Validity</th>

            {/* <th>Years of Experience</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {requirementdata.length === 0 ? (
            <tr>
              <td colSpan="11" style={{ textAlign: "center", padding: "20px" }}>
                No Requirement found.
              </td>
            </tr>
          ) : (
            requirementdata.map((row, index) => (
              <tr key={index} style={tableRowStyle}>
          <td style={tableCellStyle}>  {row?.providerTypeId?.map((item) => item.name).join(", ")}</td>
                <td style={tableCellStyle}>{row?.name}</td>
                <td style={tableCellStyle}>{row?.description}</td>
                <td style={tableCellStyle}>
                  {" "}
                  {moment(row?.issueDate).format("YYYY-MM-DD")}
                </td>
                <td style={tableCellStyle}>
                  {" "}
                  {moment(row?.expirationDate).format("YYYY-MM-DD")}{" "}
                </td>
                <td style={tableCellStyle}>
                  {row?.validity ? "True" : "False"}
                </td>
                <td style={tableCellStyle}>
                  <button
                  onClick={() => handleDelete(row._id)}
                  style={deleteButtonStyle}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-title"
      >
        <Box sx={modalStyle} className="Custom_servicer_modalMain">
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              alignItems: "center",
              justifyItems: "center",
              marginBottom: 20,
            }}
          >
            <h1 style={{ textAlign: "left" }}>New Client Requirement</h1>
            <div
              style={{ width: "100%", marginLeft: 17, fontWeight: "bold" }}
            ></div>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div style={{ height: 20 }} />
              <DropDownManuCustomMulti
                label="Select Provider Type(s)"
                placeholder="Select Provider Type"
                options={data}
                name="providerTypeId"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                // onSelect={(value) => setValue("language", value)}
                onSelect={handleProvider}
              />
                 {errors.providerTypeId && (
                      <p className="errorMessage">{errors.providerTypeId.message}</p>
                    )}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Inputbox
                  className="Custom_input"
                  type="text"
                  placeholder="Enter New Provider Type"
                  name="Providertype"
                  style={{ marginBottom: -20 }}
                  value={providertypedata}
                  onChange={(e) => setprovidertypedata(e.target.value)}
                />
                <div
                  style={{
                    alignSelf: "center",
                    width: "40%",
                    height: "50px",
                    marginLeft: 10,
                    marginBottom: 15,
                    cursor: "pointer",
                  }}
                  className="Save_buttonnn"
                  onClick={() =>
                    onProvidertypeSubmit({ name: providertypedata })
                  }
                >
                  Add New Provider Type
                </div>
              </div> */}
              <div style={{ height: 15 }} />
              <DropDownManuCustomMulti
                label="Select Insurances or Payment Types"
                placeholder="Select Provider Type"
                options={insurancedata}
                name="insuranceId"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                // onSelect={(value) => setValue("language", value)}
                onSelect={handleinsurance}
              />
              {errors.insuranceId && (
                      <p className="errorMessage">{errors.insuranceId.message}</p>
                    )}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Inputbox
                  className="Custom_input"
                  type="text"
                  placeholder="Enter New Insurance "
                  name="adminInsurenceId"
                  style={{ marginBottom: -20 }}
                  value={insuranceinput}
                  onChange={(e) => setinsuranceinput(e.target.value)}
                />
                <div
                  style={{
                    alignSelf: "center",
                    width: "40%",
                    height: "50px",
                    marginLeft: 10,
                    marginBottom: 15,
                    cursor: "pointer",
                  }}
                  className="Save_buttonnn"
                  onClick={() => oninsuranceSubmit({ name: insuranceinput })}
                >
                  Add New Insurance
                </div>
              </div> */}
              <div style={{ height: 15 }} />
              <DropDownManuCustomMulti
                label="Select Service Type(s)"
                placeholder="Select Service Type(s)"
                options={servicedata}
                name="treatmentTypesId"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                // onSelect={(value) => setValue("language", value)}
                onSelect={handleservicedata}
              />
              {errors.treatmentTypesId && (
                      <p className="errorMessage">{errors.treatmentTypesId.message}</p>
                    )}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Inputbox
                  className="Custom_input"
                  type="text"
                  placeholder="Enter New Service Type"
                  name="Providertype"
                  style={{ marginBottom: -20 }}
                  value={serviceinput}
                  onChange={(e) => setserviceinput(e.target.value)}
                />
                <div
                  style={{
                    alignSelf: "center",
                    width: "40%",
                    height: "50px",
                    marginLeft: 10,
                    marginBottom: 15,
                    cursor: "pointer",
                  }}
                  className="Save_buttonnn"
                  onClick={() => ondervicetypeSubmit({ name: serviceinput })}
                >
                  Add New Service Type
                </div>
              </div> */}
              <h1 style={{ marginTop: 10 }}>
                Select Documents ( Library Depends on Agency, Clinic, or Group
                Type )
              </h1>

              <div
                style={{
                  width: "100%",
                }}
                className="document-container"
              >
                <div style={{}}>
                  <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Intake and History
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.ClientIntakeForm}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="ClientIntakeForm"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Client Intake Form (Webform)
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.ConsentForm}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="ConsentForm"
                    />
                    <div style={{ fontWeight: "600" }}>Consent Form</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.TreatmentAuthorization}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="TreatmentAuthorization"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Treatment Authorization
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.History}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="History"
                    />
                    <div style={{ fontWeight: "600" }}>History</div>
                  </div>
                </div>
                <div style={{}}>
                  <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Assessment and Evaluation
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.MentalHealthAssessment}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="MentalHealthAssessment"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Mental Health Assessment (Webform)
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.PatientHealthQuestionnaire}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="PatientHealthQuestionnaire"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Patient Health Questionnaire-9
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.GeneralAnxietyDisorder}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="GeneralAnxietyDisorder"
                    />
                    <div style={{ fontWeight: "600" }}>
                      General Anxiety Disorder-7
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.MoodDisorderQuestionaire}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="MoodDisorderQuestionaire"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Mood Disorder Questionaire (MDQ)
                    </div>
                  </div>
                </div>
                <div style={{}}>
                  <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Legal and Compliance
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.HIPAAAgreement}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="HIPAAAgreement"
                    />
                    <div style={{ fontWeight: "600" }}>
                      HIPAA Agreement (PDF)
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.InformedConsent}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="InformedConsent"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Informed Consent (Webform)
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.ConsentReleasingInformation}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="ConsentReleasingInformation"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Consent for Releasing Information
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: 20,
                }}
                className="document-container"
              >
                <div style={{}}>
                  <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Risk Assessments
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.SuicideRiskAssessment}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="SuicideRiskAssessment"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Suicide Risk Assessment (Webform)
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.ViolenceRiskAssessment}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="ViolenceRiskAssessment"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Violence Risk Assessment (PDF)
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.SubstanceAbuseRiskAssessment}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="SubstanceAbuseRiskAssessment"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Substance Abuse Risk Assessment (PDF)
                    </div>
                  </div>
                </div>
                <div style={{}}>
                  <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Treatment Plans
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.IndividualizedTreatmentPlan}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="IndividualizedTreatmentPlan"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Individualized Treatment Plan (Webform)
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.CareCoordinationPlan}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="CareCoordinationPlan"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Care Coordination Plan (PDF)
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.CrisisManagementPlan}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="CrisisManagementPlan"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Crisis Management Plan
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.NewManagementPlan}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="NewManagementPlan"
                    />
                    <div style={{ fontWeight: "600" }}>New Management Plan</div>
                  </div>
                </div>
                <div style={{}}>
                  <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Financial Responsibility
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.FinancialAgreement}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="FinancialAgreement"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Financial Agreement (Webform)
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.InsuranceClaimAgreement}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="InsuranceClaimAgreement"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Insurance Claim Agreement (Webform)
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={checkboxValues.CreditCardAuthorization}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="CreditCardAuthorization"
                    />
                    <div style={{ fontWeight: "600" }}>
                      Credit Card Authorization (Webform)
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ fontWeight: "bold", fontSize: 23,marginTop:15 }}>
                Add New Requirement
              </div>
              <Inputbox
                label="Requirement"
                className="Custom_input"
                type="text"
                placeholder="Enter Requirement"
                name="name"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.name?.message}
              </p>

              <Inputbox
                label="Description"
                className="Custom_input"
                type="text"
                placeholder="Enter Description"
                name="description"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.description?.message}
              </p>
              <Inputbox
                label="Issue Date"
                className="Custom_input"
                type="date"
                placeholder="Enter Requirement"
                name="issueDate"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.issueDate?.message}
              </p>

              <Inputbox
                label="Expiration Date"
                className="Custom_input"
                type="date"
                placeholder="Enter Description"
                name="expirationDate"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.expirationDate?.message}
              </p>
              {/* Within Validity */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <label style={{ marginLeft: 8 }}>Within Validity</label>
                <input
                  type="checkbox"
                  id="validity"
                  name="validity"
                  {...register("validity")}
                />
              </div>
              <button
                type="submit"
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  alignSelf: "center",
                  width: "100%",
                  height: "50px",
                }}
                className="Save_buttonnn"
              >
                Submit
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ClientRequirements;

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};

const tableRowStyle = {
  borderBottom: "1px solid #ddd",
};

const deleteButtonStyle = {
  backgroundColor: "red",
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
