import React from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar, { ProfileNavbar } from "../../Components/Navbar";
import { useSelector } from "react-redux";
import Inputbox from "../../Components/Inputbox";

const MyProfileAgency = () => {
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const categoryNames =
  profiledata?.categoryId?.map((e) => e.name).join(", ") || "";
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <ProfileNavbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30, flexDirection: "column" }}
        >
          <div className="flex-row">
            {profiledata?.image ? (
              <img
                src={profiledata?.image}
                style={{ height: 105, width: 105, borderRadius: 60 }}
              />
            ) : (
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                style={{ height: 105, width: 105, borderRadius: 60 }}
              />
            )}
            <div style={{ marginLeft: 70 }}>
              <div className="Email_Address_txt"> Clinic Name</div>
              <div className="clicnic-name">
                {/* {profiledata?.firstName + " " + profiledata?.lastName} */}
                {profiledata?.companyName}
              </div>
              <div className="Email_Address_txt">Unique ID</div>
              <span style={{ display: "flex" }}>
                (C-{profiledata?.uniqueId})
              </span>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              marginTop: 20,
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            <div className="Contact_Information_hhh">Contact Information :</div>
          </div>
          <div>
          <div className="space-div" style={{marginTop:20}}>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Contact First Name</div>
                <div className="emaill">{profiledata?.firstName}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Contact Last Name</div>
                <div className="emaill">
                  {profiledata?.lastName}
                </div>
              </div>
            </div>
            <div className="space-div" style={{marginTop:20}}>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Email Address</div>
                <div className="emaill">{profiledata?.email}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Phone Number</div>
                <div className="emaill">
                  <Inputbox
                    style={{
                      width: "100%",
                      height: 43,
                      fontSize: "20px",
                    }}
                    type="text"
                    placeholder="Enter Number"
                    name="phone"
                    value={profiledata?.phone}
                    hideBorders={true}
                    disabled
                    disableDropdown={false}
                    // onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <div className="Email_Address_txt">Website</div>
              
              <div className="emaill">{profiledata?.webLink}</div>
            </div>
            <div style={{ marginTop: 20 }}>
              <div className="Email_Address_txt">Specialities</div>
              <div className="emaill">{categoryNames}</div>
            </div>
            <div style={{ marginTop: 20 }}>
              <div
                className="Contact_Information_hhh"
                style={{ marginTop: 40, marginBottom: 30 }}
              >
                Address :
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <div className="Email_Address_txt">Street</div>
              <div className="emaill">{profiledata?.address?.street}</div>
            </div>
            <div className="space-div">
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">State</div>
                <div className="emaill">{profiledata?.address?.state}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">City</div>
                <div className="emaill">{profiledata?.address?.city}</div>
              </div>
            </div>
            <div className="space-div">
            <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Zip Code</div>
                <div className="emaill">{profiledata?.address?.zipCode}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt" >
                  Country
                </div>
                <div className="emaill">{profiledata?.address?.country}</div>
              </div>
            </div>

            <div className="space-div">
            <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">EIN</div>
                <div className="emaill">{profiledata?.EIN}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt" >
                DBA
                </div>
                <div className="emaill">{profiledata?.DBA}</div>
              </div>
            </div>
            <div style={{ width: "50%",marginTop:30,marginBottom:50 }}>
                <div className="Email_Address_txt" >
                NPI 2
                </div>
                <div className="emaill">{profiledata?.NPI2}</div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfileAgency;
