import React, { useEffect, useState } from "react";
import Inputbox from "../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { FetchDegreetType, GetProviderReq, GetProviderType, GetServiceParactiseaddress, updateAgencyDoctor } from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";
import { DropDownManuCustom, DropDownMultiStringSelect } from "../../Components/DropDownButton";
import { useSelector } from "react-redux";
import { DropDownManuCustomMulti } from "../../Components/DropDownButton";

const licenseTypes = [
  { _id: 'Nursing License', name: 'Nursing License' },
  { _id: 'Medical License', name: 'Medical License' },
  { _id: 'Psychology License', name: 'Psychology License' },
  { _id: "Bachelor's in Nursing", name: "Bachelor's in Nursing" },
  { _id: "Master's in Psychology", name: "Master's in Psychology" },
  { _id: 'Doctorate in Psychiatry', name: 'Doctorate in Psychiatry' },
  { _id: 'CPR Certification', name: 'CPR Certification' },
  { _id: 'Mental Health Certification', name: 'Mental Health Certification' },
  { _id: 'Addiction Treatment Certification', name: 'Addiction Treatment Certification' },

  { _id: 'Cognitive Behavioral (CBT)', name: 'Cognitive Behavioral (CBT)' },
  { _id: 'Dialectical Behavioral (DBT)', name: 'Dialectical Behavioral (DBT)' },
  { _id: 'Acceptance & Commitment (ACT)', name: 'Acceptance & Commitment (ACT)' },
  { _id: 'Mindfulness-Based (MBCT)', name: 'Mindfulness-Based (MBCT)' },
  { _id: 'Motivational Interviewing', name: 'Motivational Interviewing' },
  { _id: 'Psychodynamic Therapy', name: 'Psychodynamic Therapy' },
  { _id: 'Solution Focused Brief Therapy (SFBT)', name: 'Solution Focused Brief Therapy (SFBT)' },
  { _id: 'Trauma-Focused Therapy', name: 'Trauma-Focused Therapy' },

  { _id: 'Individual Therapy', name: 'Individual Therapy' },
  { _id: 'Group Therapy', name: 'Group Therapy' },
  { _id: 'Family Therapy', name: 'Family Therapy' },
  { _id: 'Couples Therapy', name: 'Couples Therapy' },
  { _id: 'Crisis Intervention', name: 'Crisis Intervention' },
  { _id: 'Medication Management', name: 'Medication Management' },

  { _id: 'Elders (65 and above)', name: 'Elders (65 and above)' },
  { _id: 'Adults (18 to 64)', name: 'Adults (18 to 64)' },
  { _id: 'Teenagers (13 to 17)', name: 'Teenagers (13 to 17)' },
  { _id: 'Children (6 to 12)', name: 'Children (6 to 12)' },

  { _id: 'Anxiety', name: 'Anxiety' },
  { _id: 'Depression', name: 'Depression' },
  { _id: 'ADHD', name: 'ADHD' },
  { _id: 'Bipolar Disorder', name: 'Bipolar Disorder' },
  { _id: 'PTSD', name: 'PTSD' },
  { _id: 'Eating Disorders', name: 'Eating Disorders' },
  { _id: 'Substance Use Disorders', name: 'Substance Use Disorders' },
  { _id: 'Sleep Disorders', name: 'Sleep Disorders' },
  { _id: 'Anger Management', name: 'Anger Management' },
  { _id: 'Grief and Loss', name: 'Grief and Loss' },
  { _id: 'Trauma', name: 'Trauma' },
  { _id: 'Relationship Issues', name: 'Relationship Issues' },
];
// const data = [
//   {
//     requirement: "Education",
//     description: "Need Degree",
//     issueDate: "2024-11-01",
//     expirationDate: "2024-12-06",
//     withinValidity: "Yes",
//     providerTypes: "Psychiatrist",
//     status: "Pending",
//   },
//   {
//     requirement: "License",
//     description: "State License Required",
//     issueDate: "2024-01-10",
//     expirationDate: "2026-01-10",
//     withinValidity: "Yes",
//     providerTypes: "Psychiatrist, Counselor",
//     status: "Approved",
//   },
//   {
//     requirement: "Board Certification",
//     description: "Specialty Board Certification",
//     issueDate: "2024-02-15",
//     expirationDate: "2027-02-15",
//     withinValidity: "Yes",
//     providerTypes: "Psychiatrist",
//     status: "Pending",
//   },
// ];


const Credentialdata = ({ info, getDteails }) => {
  // console.log(info, "===info");
  // const [saveInfo , setSaveInfo] = useState(null)
  const [servicelocation, setservicelocation] = useState([]);
  const [selectedTreatmentTypes, setSelectedTreatmentTypes] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [Degreetypedata, setDegreetypedata] = useState([])
  const [providerType, setproviderType] = useState([]);
const [providerdeault, setproviderdeault] = useState([])
  const [data, setdata] = useState([])
  const GetDegreetype = async (id) => {
    const response = await FetchDegreetType(`?id=${profiledata._id}`);
    console.log(response?.data?.data, "data=====");
    setDegreetypedata(response?.data?.data);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      id: info._id,
      // Education: info.Education || "",
      licenseNumber: info?.licenseNumber || "",
      BoardCertifications: info?.BoardCertifications || "",
      NPI: info?.NPI || "",
      // NPIType: info?.NPIType || "",
      FacilityAffiliation: info?.FacilityAffiliation || "",
      Reference: info?.Reference || "",
      DEA: info?.DEA || "",
      ServiceType: info?.ServiceType || "",
      practiceLocation: info?.practiceLocation || "",
      PrimaryLicenseNo: info?.PrimaryLicenseNo || "",
      primaryStateOfPractice: info?.primaryStateOfPractice || "",
      EnumerationDate: info?.EnumerationDate || "",

    },
  });
  useEffect(() => {
    // console.log(info.LicenseType, '===info.LicenseType')
    const defaultVal = info?.degreeTypeId|| [];
const defaultprovider = info?.ProviderType ||[];
const defaultservice = info?.ServiceType || [];
setSelectedTreatmentTypes(defaultservice);
    setSelectedValues(defaultVal);
    setproviderdeault(defaultprovider)
  }, [])

  const FetchAddresslist = async () => {
    try {
      const response = await GetServiceParactiseaddress(profiledata._id);
      setservicelocation(
        Array.isArray(response?.data?.data?.data)
          ? response?.data?.data?.data
          : []
      );
      // setservicelocation(response);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchProviderType = async (id) => {
    const response = await GetProviderType(`?id=${profiledata._id}`);
    setproviderType(response?.data?.data || []);
  };
  useEffect(()=>{
    fetchProviderType()
    FetchAddresslist()
    GetDegreetype()
  },[])
  const onSubmit = (data) => {
    data.id = info._id
    dataToSave(data);
  };

  const dataToSave = async (data) => {
    try {
      const response = await updateAgencyDoctor(data);
      ShowToast(response.data.message, "success");
      getDteails();
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(info?.ProviderType,"info?.agencyId[0]===")
  const getProviderTyperequriment = async () => {
    const data = {};
    data.agencyId = info?.agencyId;
    data.providerTypeId = info?.ProviderType;
    try {
      const response = await GetProviderReq(data);
      console.log(response?.data?.data,'response?.data====')
      setdata(response?.data?.data)
    } catch (error) {
      console.log(error);
    }
  };
useEffect(() => {
  if (info?.agencyId && Array.isArray(info?.ProviderType) && info?.ProviderType.length > 0) {
    getProviderTyperequriment();
  }
}, [info?.agencyId, info?.ProviderType]);


  const handleSelectlin= (selectedOptions) => {
      let ids = selectedOptions.map((e) => e?._id);
      setValue("degreeTypeId", ids, { shouldValidate: true });
    };
    const handleSelectProviderType = (selectedOptions) => {
      let ids = selectedOptions?.map((e) => e?._id);
      setValue("ProviderType", ids, { shouldValidate: true });
    };
console.log(info,"nfo?.ServiceLocationId?.name")
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-row">
          {/* <Inputbox
            label="Education"
            type="text"
            placeholder="Enter Education"
            name="Education"
            register={register}
          />
          <div style={{ width: "40px" }} /> */}
          {/* <Inputbox
            type="text"
            label="License Number"
            placeholder="Enter License Number"
            name="licenseNumber"
            register={register}
          /> */}
          {/* <div style={{ width: "40px" }} />
          <Inputbox
            type="text"
            label="Board Certifications"
            placeholder="Enter Certifications"
            name="BoardCertifications"
            register={register}
          /> */}
        </div>
          {/* <div className="input-row">
        <Inputbox
            label="Facility Affiliation"
            type="text"
            placeholder="Enter Facility"
            name="FacilityAffiliation"
            register={register}
          /> 
          <div style={{ width: "40px" }} />
          <Inputbox
            type="text"
            label="DEA"
            placeholder="Enter DEA"
            name="DEA"
            register={register}
          />
          <div style={{ width: "40px" }} />
          <Inputbox
            type="text"
            label="NPI Number"
            placeholder="Enter NPI"
            name="NPI"
            register={register}
          />
        </div>
        */}
        <div
          className="input-row"
          style={{ width: "100%", alignSelf: "flex-start" }}
        >

          <DropDownManuCustomMulti
            label="Degree Type"
            placeholder="Select option"
            options={Degreetypedata}
            name="degreeTypeId"
            register={register}
            id="_id"
            clearErrors={clearErrors}
            onSelect={handleSelectlin}
            defaultValue={selectedValues}
          />
          {/* <DropDownManuCustom
            label=" License Type"
            placeholder="Select option"
            options={licenseTypes}
            defaultValue={info?.LicenseType}
            name="LicenseType"
            dropdownicon
            register={register}
          /> */}
<div style={{ width: "100px" }} />
              {/*       <Inputbox
            type="text"
            label="Reference"
            placeholder="Enter Reference"
            name="Reference"
            register={register}
          /> */}
          {/* <Inputbox
            label="Practice Location"
            type="text"
            placeholder="Select Practice Location"
            name="practiceLocation"
            register={register}
          /> */}
            <Inputbox
            label="Primary State License Number"
            type="text"
            placeholder="Enter Primary State License Number"
            name="PrimaryLicenseNo"
            register={register}
          />
          
        </div>

        <div
          className="input-row"
          style={{ width: "66%", alignSelf: "flex-start" }}
        >
              <DropDownManuCustomMulti
                label="Provider Type"
                placeholder="Select option"
                options={providerType}
                name="ProviderType"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={handleSelectProviderType}
                defaultValue={providerdeault}
              />
           {/* <DropDownManuCustom
                    label="Provider Primary Pratice Address"
                    placeholder="Select Practice Location"
                    options={servicelocation}
                    name="ServiceLocationId"
                    dropdownicon
                    register={register}
                    id="_id"
                    clearErrors={clearErrors}
                    defaultValue={info?.ServiceLocationId}
                  />
          <div style={{ width: "40px" }} />
          <DropDownManuCustom
            label="Primary State Of Practice"
            placeholder="Select option"
            options={primaryStatesOfPractice}
            name="primaryStateOfPractice"
            defaultValue={info?.primaryStateOfPractice}
            dropdownicon
            register={register}
          /> */}
          {/* <div style={{}}> */}
          {/* <Inputbox
                label="Enumeration Date"
                type="date"
                name="EnumerationDate"
                register={register}
                style={{
                  borderColor: errors.EnumerationDate ? "red" : "initial",marginTop:-3
                }}
              /> */}
          {/* </div> */}
        </div>




        <div className="custom-req-table-container">
      <table className="custom-req-table">
        <thead>
          <tr>
            <th>Requirement</th>
            <th>Description</th>
            <th>Issue Date</th>
            <th>Expiration Date</th>
            <th>Within Validity</th>
            {/* <th>Provider Type(s)</th> */}
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {data.length === 0 ? (
    <tr>
      <td colSpan="8" style={{ textAlign: 'center' }}>
        No data found
      </td>
    </tr>
  ) : (
    data.map((item, index) => {
      const issueDate = new Date(item.issueDate).toLocaleDateString();
      const expirationDate = new Date(item.expirationDate).toLocaleDateString();
      const validity = item.validity ? 'Yes' : 'No';
      const providerTypes = item.providerTypeId.map(provider => provider.name).join(', ');

      return (
        <tr key={index}>
          <td>{item.name}</td>
          <td>{item.description}</td>
          <td>{issueDate}</td>
          <td>{expirationDate}</td>
          <td>{validity}</td>
          {/* <td>{providerTypes}</td> */}
          <td>Pending</td>
          <td>
            <button className="custom-req-upload-button">Upload</button>
            <button className="custom-req-download-button">Download</button>
            <button className="custom-req-mark-exempt-button">Mark Exempt</button>
            <button className="custom-req-send-request-button">Send Request</button>
          </td>
        </tr>
      );
    })
  )}
</tbody>

      </table>
    </div>




        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 30,
          }}
        >
          <div className="cancelButton">Cancel</div>
          <button className="savebuttonn" style={{ width: 340 }} type="submit">
            Save
          </button>
          {/* <div className="savebuttonn" style={{ width: 340 }}  >
          Save
        </div> */}
        </div>
      </form>
    </div>
  );
};

export default Credentialdata;
