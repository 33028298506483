import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { useSelector } from "react-redux";
import { Addcptcode, FetchCptcodes } from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";

const PayRate = ({info}) => {
 
  // console.log(info?.stateOfPractice,'===>>info')
  const [activeState, setActiveState] = useState("");
  const [csvData, setCsvData] = useState([]); 
  const profileData = useSelector((state) => state?.cookies?.userDetails);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Total pages from the API
  const [totalCount, setTotalCount] = useState(0);
const Getcptcodes =async()=>{
  try {
    const response = await FetchCptcodes(`agencyId=${profileData?._id}&doctorId=${info?._id}&insurers=${activeState}&page=${currentPage}`)
    console.log(response?.data?.data,"data========")
 
    setCsvData(response?.data?.data?.data)
    setTotalPages(response?.data?.data?.totalPages);
    setTotalCount(response?.data?.data?.totalCount);
  } catch (error) {
    console.log(error)
  }
}
// console.log(csvData,"csvData======")
// console.log(currentPage,"currentPage=====")
// console.log(totalCount,"totalCount====")
// console.log(totalPages,"totalPages=====")

useEffect(()=>{
  Getcptcodes()
},[activeState,currentPage])

const handlePageChange = (page) => {
  console.log(page,"page=======")
  if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
  }
};

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const rawData = result.data;
        const formattedData = rawData.map((item) => {
          const insurers = Object.keys(item).reduce((acc, key) => {
            if (key !== "CPT Code") {
              acc[key] = parseInt(item[key]); 
            }
            return acc;
          }, {});

          return {
            CPTCode: item["CPT Code"],
            agencyId: profileData?._id, 
            // doctor id in request models
            doctorId:info?._id,
            Insurers: insurers,
          };
        });

        // console.log(formattedData,'===>>formattedData')
        setCsvData(formattedData); 
        // setCurrentPage(1);
      },
      error: (err) => {
        console.error("Error parsing CSV:", err);
      },
    });
  }
};


  const headers = ["CPT Code", ...Object.keys(csvData[0]?.Insurers || {})]; // Dynamic headers
  
const onsubmit=async()=>{
  if (csvData.length === 0) {
    ShowToast("No data to send! Please add CPT codes."); 
    return; 
  }
  

  try {
    const response = await Addcptcode(csvData)
    ShowToast(response.data.message, "success");
  } catch (error) {
    console.log(error)
  }
}

  return (
    <div className="rates-container">
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >  
        <label
          htmlFor="file-upload"
          className="state-button"
          style={{ marginBottom: 15, cursor: "pointer" }}
        >
          Import
        </label>
        <input
          id="file-upload" 
          type="file"
          accept=".csv"
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
      </div>

      {/* State Buttons */}
      <div className="states-buttons">
        {info?.stateOfPractice?.map((state) => (
          <button
            key={state}
            className={`state-button ${activeState === state ? "active" : ""}`}
            onClick={() => setActiveState(state)}
          >
            {state}
          </button>
        ))}
      </div>

      {/* Main Table Section */}
      <div style={{ backgroundColor: "white", padding: 10, borderRadius: 10 }}>
        <h2 className="state-title">{activeState === ""? "All":activeState} Rates</h2>
        <div className="rates-table">
        <table>
  <thead>
    <tr>
      {headers.map((header, idx) => (
        <th key={idx}>
          <div className="header-content">
            <span>{header}</span>
            {header !== "CPT Code" && (
              <button className="view-contract">View Contract</button>
            )}
          </div>
        </th>
      ))}
    </tr>
  </thead>
  <tbody>
    {csvData.map((row, rowIdx) => (
      <tr key={rowIdx}>
        {/* CPT Code Column */}
        <td>{row.CPTCode || "-"}</td>

        {/* Insurers Columns */}
        {Object.keys(row.Insurers).map((insurer, colIdx) => (
          <td key={colIdx}>{row.Insurers[insurer] || "-"}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>
<div style={{ textAlign: "center", margin: "20px 0" }}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            margin: "5px",
            padding: "10px 20px",
            backgroundColor: currentPage === 1 ? "#ccc" : "#007BFF",
            color: "#fff",
            border: "1px solid #007BFF",
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          Previous
        </button>

        <span
          style={{
            margin: "5px",
            padding: "10px 20px",
            backgroundColor: "#fff",
            color: "#000",
            border: "1px solid #007BFF",
          }}
        >
          {currentPage} of {totalPages}
        </span>

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          style={{
            margin: "5px",
            padding: "10px 20px",
            backgroundColor: currentPage === totalPages ? "#ccc" : "#007BFF",
            color: "#fff",
            border: "1px solid #007BFF",
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          Next
        </button>
      </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 30,
          backgroundColor:'white',
          padding:10,
          borderRadius:8
        }}
      >
        <div className="cancelButton">Cancel</div>
        <button type="submit" className="savebuttonn" style={{ width: 340 }} onClick={onsubmit}>
          Save
        </button>
      </div>
    </div>
  );
};

export default PayRate;
