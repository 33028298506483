import React, { useEffect, useRef, useState } from "react";
import { DropDownManuCustom, DropDownManuCustomMulti } from "../Components/DropDownButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { createAgencyDoctor, DoctorSignup, FetchMODALITIES, FetchPopulationServed, GetAgencyBooking, GetDeliveryMode, GetInsurance, GetSpecialties, uploadImage } from "../../api/helper";
import { ShowToast } from "../../helpers/ToastService";
import { Store } from "../../redux";
import { setHeaderToken, setLoginType, setUserDetails } from "../../redux/cookiesReducer";
import { useNavigate } from "react-router-dom";
import { clearEmptyData } from "../../helpers/helper";
const therapyOptions = [
  { _id: "Acceptance and Commitment", name: "Acceptance and Commitment" },
  { _id: "Solution Focus", name: "Solution Focus" },
  { _id: "Group Therapy", name: "Group Therapy" },
  { _id: "Individual Therapy", name: "Individual Therapy" },
  { _id: "Family Therapy", name: "Family Therapy" },
  { _id: "Cognitive Behavioral Therapy (CBT)", name: "Cognitive Behavioral Therapy (CBT)" },
  { _id: "Dialectical Behavior Therapy (DBT)", name: "Dialectical Behavior Therapy (DBT)" },
  { _id: "Eye Movement Desensitization and Reprocessing (EMDR)", name: "Eye Movement Desensitization and Reprocessing (EMDR)" },
];
const expertiseOptions = [
  { _id: "Veterans & Military", name: "Veterans & Military" },
  { _id: "LGBTQIA+ Individuals", name: "LGBTQIA+ Individuals" },
  { _id: "Geriatric Population", name: "Geriatric Population" },
  { _id: "Adolescents", name: "Adolescents" },
  { _id: "Postpartum Mental Health Support", name: "Postpartum Mental Health Support" },
  { _id: "Expertise", name: "Expertise" },
  { _id: "Adolescent Psychiatry", name: "Adolescent Psychiatry" },
  { _id: "Adult Psychiatry", name: "Adult Psychiatry" },
  { _id: "Geriatric Psychiatry", name: "Geriatric Psychiatry" },
  { _id: "Child Psychiatry", name: "Child Psychiatry" },
  { _id: "Family Psychiatry", name: "Family Psychiatry" },
];
const populationOptions = [
  { _id: "Children", name: "Children" },
  { _id: "Adolescents", name: "Adolescents" },
  { _id: "Young Adults (18-25)", name: "Young Adults (18-25)" },
  { _id: "Adults (26-60)", name: "Adults (26-60)" },
  { _id: "Seniors (60+)", name: "Seniors (60+)" },
];
const insuranceOptions = [
  { _id: "Medicare", name: "Medicare" },
  { _id: "Medicaid", name: "Medicaid" },
  { _id: "Blue Cross Blue Shield", name: "Blue Cross Blue Shield" },
  { _id: "Aetna", name: "Aetna" },
  { _id: "Cigna", name: "Cigna" },
  { _id: "United Healthcare", name: "United Healthcare" },
  { _id: "Humana", name: "Humana" },
  { _id: "TRICARE", name: "TRICARE" },
  { _id: "Kaiser Permanente", name: "Kaiser Permanente" },
  { _id: "Other", name: "Other" },
];
const deliveryModeOptions = [
  { _id: "Telehealth", name: "Telehealth" },
  { _id: "In-Office", name: "In-Office" },
  { _id: "Telehealth and In-Office", name: "Telehealth and In-Office" },
];
const treatmentTypeOptions = [
  { _id: "Medication Management", name: "Medication Management" },
  { _id: "Psychotherapy", name: "Psychotherapy" },
  { _id: "Both", name: "Both" },
];

const validationSchema = Yup.object().shape({
  // Modalities: Yup.array()
  //   .of(Yup.string().required("At least one modality is required"))
  //   .min(1, "Modalities (Treatment Approaches) is required"),

  // SpecialPopulationExpertise: Yup.array()
  //   .of(Yup.string().required("At least one special population expertise is required"))
  //   .min(1, "Special Populations and Expertise is required"),

  // Population: Yup.array()
  //   .of(Yup.string().required("At least one population is required"))
  //   .min(1, "Population (Ages Served) is required"),

  // Insurance: Yup.array()
  //   .of(Yup.string().required("At least one insurance is required"))
  //   .min(1, "Insurance Currently Accepted is required"),

  // ServiceType: Yup.array()
  //   .of(Yup.string().required("At least one treatment type is required"))
  //   .min(1, "Treatment Type (Service Type) is required"),

  // DeliveryMode: Yup.array()
  //   .of(Yup.string().required("At least one delivery mode is required"))
  //   .min(1, "Delivery Mode is required"),
});



export const DoctorSignupScreen = ({ data, setShowDoctorSignup }) => {
  useEffect(() => {
    console.log("Data received in DoctorSignupScreen===>>>>:", data);
  }, [data]);
  const [showModal, setShowModal] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [imaeg, setImages] = useState(null);
  const fileInputRef = useRef(null);
  const [formSubmit, setFormSubmit] = useState(null);
  const navigate = useNavigate();
  const [deleveryMode, setDeleveryMode] = useState([])
  const [Insurance, setInsurance] = useState([])
  const [Treatment, setTreatment] = useState([])
  const [PopulationServed, setPopulationServed] = useState()
  const [Modalities, setModalities] = useState()
  const [Specialties, setSpecialties]= useState()
  const [insuransedata, setinsuransedata] = useState([])


  const DeliveryMode = async () => {
    try {
      const response = await GetDeliveryMode(`?id=${data?.agencyId}`);
      // console.log(response?.data?.data, "GetDeliveryMode======");
      setDeleveryMode(response?.data?.data || [])
    } catch (error) {
      console.log(error);
    }
  };

  const InsuranceType = async () => {
    try {
      const response = await GetInsurance(`?id=${data?.agencyId}`);
      console.log(response?.data?.data, "GetInsurance=====");
      let resData = response?.data?.data
      resData = resData?.map((e)=>e.adminInsurenceId ? {...e, name:e.adminInsurenceId.PayerName}:e)
      setInsurance(resData);
    } catch (error) {
      console.log(error, '===e')
    }
  };

  const TreatmentTypeslist = async () => {
    try {
      const response = await GetAgencyBooking(`?id=${data?.agencyId}`);
      // console.log(response?.data?.data, "GetAgencyBooking======");
      setTreatment(response?.data?.data || [])
    } catch (error) {
      console.log(error);
    }
  };

  const PopulationServedGet = async () => {
    const response = await FetchPopulationServed(`?id=${data?.agencyId}`);
    // console.log(response?.data?.data, "data=====");
    setPopulationServed(response?.data?.data);
  };

  const getModalities = async () => {
    try {
      const response = await FetchMODALITIES(`?id=${data?.agencyId}`);
      setModalities(response?.data?.data);
    } catch (error) {
      console.log(error)
    }
  }


  const GetSpecialtiesList = async () => {
    try {
        const response = await GetSpecialties(`?id=${data?.agencyId}`);
        // console.log(response?.data?.data, "GetSpecialties======");
        setSpecialties(response?.data?.data || [])

    } catch (error) {
        console.log(error);
    }
};


useEffect(() => {
  if (data?.agencyId) {
    DeliveryMode();
    InsuranceType();
    TreatmentTypeslist();
    PopulationServedGet();
    getModalities();
    GetSpecialtiesList();
  }
}, [data?.agencyId]);


  const changePgae = () => {
    setShowDoctorSignup(false)
  }

  const clickImageData = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const imagePicker = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        setImages(response.data.data[0].URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error, "===");
      }
    }
  };
  const handleCloseModal = async (data) => {
    if (data == "Upload") {
      if (!imagePreview) {
        return ShowToast("Please Select Image", "error");
      }
    }
    setFormSubmit(data);
    setShowModal(false);
  };
  const onSubmit = async (formData) => {
    const mergedData = { ...data, ...formData };
    const savedata = clearEmptyData(mergedData)
    await handleSignUp(savedata);
  };
  const handleSignUp = async (data) => {
    formSubmit ? submitData(data) : setShowModal(true);
  };

  const submitData = async (data) => {
    if (imaeg) {
      data.image = imaeg;
    }
    console.log(data,"submitdata========")
    // return
    try {
      console.log(data,"submitdata========")
      const resposne = await DoctorSignup(data);
      console.log(resposne?.data?.data,"responsive========")
      ShowToast(resposne.data.message, "success");
      Store.dispatch(setHeaderToken(resposne?.data?.data));
      Store.dispatch(setUserDetails(resposne?.data?.data));

      if (data.agencyId) {
        let saveToData = {
          doctorId: resposne?.data?.data?._id,
          agencyId: data.agencyId,
          status: "request",
          ...data,
        };

        await createAgencyDoctor(saveToData);
      }

      Store.dispatch(setLoginType("Doctor"));
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const selectTreatmentType = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("ServiceType", ids, { shouldValidate: true });
  };

  const selectDeliveryMode = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("DeliveryMode", ids, { shouldValidate: true });
  };

  const  selectPopulationServed =(selectedOptions)=>{
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("PopulationServed", ids, { shouldValidate: true });
  }
  
  const  selectModalities =(selectedOptions)=>{
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("Modalities", ids, { shouldValidate: true });
  }
  
  const  selectInsurance =(selectedOptions)=>{
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("Insurance", ids, { shouldValidate: true });
  }

  
  const  selectSpecialties =(selectedOptions)=>{
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("Specialties", ids, { shouldValidate: true });
  }


  return (
    <div style={{ width: "100%", display: "flex", height: "100vh" }}>
      <div
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#041C32",
          flexDirection: "column",
        }}
      >
        <img
          src={require("../assest/Images/mindwellnewlogo.png")}
          alt="MindWell Icon"
          style={{ height: "50%", width: "50%" }}
        />
        <div
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "20px",
            marginTop: "20px",
          }}
        >
          Your trusted platform for mental health services.
        </div>
      </div>

      <div className="signup-container" style={{ padding: 50,width:'50%' }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{width:'100%'}}>
          <h2>New Provider Registration</h2>
          <div className="input-row_two_">
            <div className="form_div">
              <DropDownManuCustomMulti
                label="Specialties"
                placeholder="Select option"
                options={Specialties}
                name="Specialties"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={selectSpecialties}
              />

              {errors.SpecialPopulationExpertise && (
                <div className="errorMessage" style={{ marginTop: 5 }}>
                  {errors.SpecialPopulationExpertise.message}
                </div>
              )}
            </div>
            <div className="form_div">
              <DropDownManuCustomMulti
                label="Modalities (Treatment Approaches)"
                placeholder="Select option"
                options={Modalities}
                name="Modalities"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={selectModalities}
              />


              {errors.Modalities && (
                <div className="errorMessage">{errors.Modalities.message}</div>
              )}
            </div>
          </div>

          <div className="input-row_two_">
            <div className="form_div">
              <DropDownManuCustomMulti
                label="Population (Ages Served)"
                placeholder="Select option"
                options={PopulationServed}
                name="Population"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={selectPopulationServed}
              />

              {errors.Population && (
                <div className="errorMessage">{errors.Population.message}</div>
              )}
            </div>
            <div className="form_div">
            
              <DropDownManuCustomMulti
                label="Insurance Currently Accepted"
                placeholder="Select option"
                options={Insurance}
                name="Insurance"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={selectInsurance}
              />

              {errors.Insurance && (
                <div className="errorMessage">{errors.Insurance.message}</div>
              )}
            </div>
          </div>

          <div className="input-row_two_">
            <div className="form_div">

              <DropDownManuCustomMulti
                label="Treatment Type (Service Type)"
                placeholder="Select option"
                options={Treatment}
                name="ServiceType"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={selectTreatmentType}
              />


              {errors.ServiceType && (
                <div className="errorMessage">{errors.ServiceType.message}</div>
              )}
            </div>
            <div className="form_div">

              <DropDownManuCustomMulti
                label="Delivery Mode"
                placeholder="Select option"
                options={deleveryMode}
                name="DeliveryMode"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={selectDeliveryMode}
              />
              {errors.DeliveryMode && (
                <div className="errorMessage">{errors.DeliveryMode.message}</div>
              )}
            </div>
          </div>

          <input className="login-button" type="submit" value="SignUp" />
          {/* <a className="login-button" onClick={changePgae} >Previous</a> */}
        </form>
      </div>
      {showModal && (
        <div className="modal_div">
          <div className="logout-modal" style={{ backgroundColor: "#D9D9D9" }}>
            <div
              className="logout_txt"
              style={{ color: "#011434", borderColor: "#011434" }}
            >
              Upload Profile Picture
            </div>
            <div
              onClick={clickImageData}
              style={{
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ height: 150, width: 150, borderRadius: 80 }}
                />
              ) : (
                <img
                  src={require("../assest/Images/Cameraprofile.png")}
                  alt="Upload"
                />
              )}

              <input
                type="file"
                ref={fileInputRef}
                onChange={imagePicker}
                style={{ display: "none" }}
              />
            </div>

            <button
              className="confirm-button"
              onClick={() => handleCloseModal("Upload")}
              style={{ width: 200, color: "white", backgroundColor: "#BC9C22" }}
            >
              Upload
            </button>
            <div
              style={{ marginTop: 20, cursor: "pointer" }}
              onClick={() => handleCloseModal("Skip")}
            >
              Skip for now
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
