import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Getmyclinicpatient, Patientrequestupdate } from "../../../api/helper";
import moment from "moment";

const Requestspatients = () => {
  // <img src={require('../assest/Images/userimage@3x.png')}
  const navigation = useNavigate();
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  // console.log(profiledata, "hellloo=====")
  const [doctordata, setdoctordata] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  const Getrequest = async (page) => {
    try {
      let data = {
        limit: 50,
        page: 1,
        agencyId: profiledata?._id,
        status: "pending",
      };
      const response = await Getmyclinicpatient(data);
      console.log(response?.data.data?.response, "doctor=-=-======hello");
      setdoctordata(response?.data.data?.response);
      setTotalPages(response?.data?.data?.totalPages);
    } catch (error) {
      console.log(error, "error=====");
    }
    // console.log('helloooo')
  };
  const UpdateRequest = async (value, id) => {
console.log(value,id,"id=---")
    try {
      let data = {
        id: id,
        status: value,
      };
      console.log(data,"datat====")
      // return
      const response = await Patientrequestupdate(data);
      console.log(
        response?.data.data,
        "Patientrequestupdate=-=-======Patientrequestupdate"
      );
      Getrequest()
    } catch (error) {
      console.log(error, "error=====");
    }
  };
  //
  // console.log(doctordata,"doctordatadoctordatadoctordatadoctordata")
  useEffect(() => {
    Getrequest(currentPage);
  }, [currentPage]);
  const getAge = (birthDate) => {
    const birthMoment = moment(birthDate);
    const now = moment();
    const years = now.diff(birthMoment, "Years");
    if (years < 1) {
      const months = now.diff(birthMoment, "Months");
      return `${months} Month${months !== 1 ? "s" : ""}`;
    } else if (years === 1) {
      const days = now.diff(birthMoment.add(1, "Year"), "Days");
      return `${days} Day${days !== 1 ? "s" : ""}`;
    } else {
      return `${years} Year${years !== 1 ? "s" : ""}`;
    }
  };
  return (
    <div className="userTable-container">
      <table className="userTable-table">
        <thead>
          <tr>
            <th className="userTable-th">Image</th>
            <th className="userTable-th">Name</th>
            <th className="userTable-th">Gender</th>
            <th className="userTable-th">Age</th>
            <th className="userTable-th">Pay Type</th>
            <th className="userTable-th">View Details</th>
            <th className="userTable-th">Confirm / Decline</th>
          </tr>
        </thead>
        <tbody>
          {doctordata && doctordata.length > 0 ? (
            doctordata.map((clinic, index) => (
              <tr key={index}>
                <td className="userTable-td">
                  <img
                    src={
                      clinic?.patientId?.image ||
                      "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                    }
                    alt={`${clinic.patientId.companyName}`}
                    className="clinic-image"
                  />
                </td>
                <td className="userTable-td">
                  {clinic.patientId.firstName + clinic.patientId.lastName}
                </td>
                <td className="userTable-td">{clinic.patientId.gender}</td>
                <td className="userTable-td">{getAge(clinic.patientId.DOB)}</td>
                <td className="userTable-td">Not Available</td>
                <td className="userTable-td">
                  <button className="view-button" onClick={() => {
          navigation('/PatientsDetails', { state: { clinic } });
        }} 
        >View</button>
                </td>
                <td className="userTable-td">
                  <button
                    className="userTable-declineBtn"
                    onClick={() => UpdateRequest("denied", clinic?._id)}
                  >
                    Decline
                  </button>
                  <button
                    className="userTable-confirmBtn"
                    onClick={() => UpdateRequest("approved", clinic?._id)}
                  >
                    Confirm
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" style={{ textAlign: "center" }}>
                No clinics found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Requestspatients;
