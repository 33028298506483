// import React from 'react'

// const AddContract = () => {
//   return (
// <div>
//     hello
// </div>
//   )
// }

// export default AddContract
import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Inputbox from "../../Components/Inputbox";
import {
  DropDownManuCustomMulti,
} from "../../Components/DropDownButton";
import {
  AddInsuranceContract,
  AddVisitReason,
  DeleteInsuranceContract,
  DeleteVisitReason,
  EditVisitReason,
  FetchInsuranceContract,
  FetchVisitReason,
  GetProviderType,
} from "../../../api/helper";
import Papa from "papaparse";
import { ShowToast } from "../../../helpers/ToastService";

const cptCodes = [
  {
    _id: "99201  -  Office or other outpatient visit for a new patient, evaluation and management",
    name: "99201  -  Office or other outpatient visit for a new patient, evaluation and management ",
    description:
      "Office or other outpatient visit for a new patient, evaluation and management",
  },
  {
    _id: "99202  -  Office or other outpatient visit for a new patient, typically 15-29 minutes",
    name: "99202  -  Office or other outpatient visit for a new patient, typically 15-29 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 15-29 minutes",
  },
  {
    _id: "99203  -  Office or other outpatient visit for a new patient, typically 30-44 minutes",
    name: "99203  -  Office or other outpatient visit for a new patient, typically 30-44 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 30-44 minutes",
  },
  {
    _id: "99204  -  Office or other outpatient visit for a new patient, typically 45-59 minutes",
    name: "99204  -  Office or other outpatient visit for a new patient, typically 45-59 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 45-59 minutes",
  },
  {
    _id: "90834  -  Psychotherapy, 45 minutes with patient",
    name: "90834  -  Psychotherapy, 45 minutes with patient",
    description: "Psychotherapy, 45 minutes with patient",
  },
  {
    _id: "90837  -  Psychotherapy, 60 minutes with patient",
    name: "90837  -  Psychotherapy, 60 minutes with patient",
    description: "Psychotherapy, 60 minutes with patient",
  },
];
const validationSchema = Yup.object().shape({
// ProviderType: Yup.array()
// .of(Yup.string().required("A Provider Type must be selected"))
// .min(1, "At least one Provider Type is required")
// .required("Provider Type is required"),
  ProviderType:Yup.array()
  .of(Yup.string().required("A Provider Type must be selected"))
  .min(1, "At least one Provider Type is required")
  .required("Provider Type is required"),
  CPTCode: Yup.array()
    .of(Yup.string().required("A CPT Code must be selected"))
    .min(1, "At least one CPT Code is required")
    .required("CPT Code is required"),
    minRate: Yup.string().required(
    "Contracted Rate (Min) is required"
  ),
  maxRate: Yup.string().required(
    "Contracted Rate (Max) is required"
  ),
  BillingRate: Yup.string().required(
    "Billing Rate is required"
  ),
  ContractedRate: Yup.string().required(
    "Contracted Rate is required"
  ),
});
const AddContract = () => {
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState([]);
  const profileData = useSelector((state) => state?.cookies?.userDetails);
  const [providerType, setproviderType] = useState([]);
  const [activeInsurance, setActiveInsurance] = useState("Cigna"); 
  const [activeState, setActiveState] = useState("Florida");
  const [csvData, setCsvData] = useState([]); 

  const handleTabClick = (label, type) => {
    if (type === "insurance") {
      setActiveInsurance(label);
    } else {
      setActiveState(label);
    }
  };
  const openModal = () => {
    setShowModal(true);
    setIsEditing(false); // Default to add mode
    reset(); // Clear form data
  };

  const closeModal = () => setShowModal(false);

  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const GetVisitReason = async () => {
    const response = await FetchInsuranceContract(`?id=${profileData?._id}`);
    console.log(response?.data?.data?.insuranceContracts,"response?.data?.data-=====")
    setData(response?.data?.data?.insuranceContracts || []); // Assuming the data is in `insuranceContracts`
  };
  console.log(data,"data======")
  const fetchProviderType = async (id) => {
    const response = await GetProviderType(`?id=${profileData?._id}`);
    setproviderType(response?.data?.data || []);
  };

  useEffect(() => {
    GetVisitReason();
    fetchProviderType()
  }, []);



  const onSubmit = async (formData) => {
    formData.CPTCode = formData.CPTCode.join(",");
    formData.agencyId = profileData?._id;
    formData.Insurance=activeInsurance;
    formData.stateArea =activeState;
    console.log(formData,"formData====")
    // closeModal()
  // return
    try {

        // Add case
        const response = await AddInsuranceContract(formData);
        ShowToast(response?.data?.message);
     
      reset();
      GetVisitReason();
      setShowModal(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  
  const handleExportToCSV = () => {
    if (data?.length > 0) {
      // Format the data for CSV export
      const formattedData = data.map((row) => ({
        "Provider Type": row.ProviderType?.map((type) => type.name).join(", ") || "N/A",
        "CPT Code": row.CPTCode,
        "Minimum Completion Time": row.minRate,
        "Maximum Completion Time": row.maxRate,
        "Billing Rate ($)": row.BillingRate,
        "Contracted Rate ($)": row.ContractedRate,
      }));

      // Convert JSON to CSV
      const csv = Papa.unparse(formattedData);

      // Trigger file download
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "data_export.csv"; // File name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("No data to export!");
    }
  };
  

  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to remove this Contract?");
    if (!confirmed) return;

    try {
      const response = await DeleteInsuranceContract(`?id=${id}`);
      if (response?.data?.success) {
        ShowToast(response?.data?.message);
        GetVisitReason();
      } else {
        ShowToast("Error: Unable to delete address.");
      }
    } catch (error) {
      console.error("Delete operation failed:", error);
      ShowToast("An error occurred while deleting the address.");
    }
  };

  const handleLanguage = (selectedOptions) => {
    console.log(selectedOptions,"selectedOptions=====CPTCode")
    const ids = selectedOptions?.map((e) => e?._id);
    setValue("CPTCode", ids, { shouldValidate: true });
  };
  const handleSelectProviderType = (selectedOptions) => {
    console.log(selectedOptions,"selectedOptions=====ProviderType")
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("ProviderType", ids, { shouldValidate: true });
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          setCsvData(result.data); 
        },
        error: (err) => {
          console.error("Error parsing CSV:", err);
        },
      });
    }
  };
  
  return (
    <div style={{ width: "100%", alignItems: "center", marginBottom: 20 }}>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <h1>Contract</h1>
        <div
          style={{
            backgroundColor: "#bc9c22",
            color: "white",
            padding: 10,
            borderRadius: 6,
            cursor: "pointer",
          }}
          onClick={openModal}
        >
           Add Contract
        </div>
      </div>
      <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead style={{ backgroundColor: "yellow" }}>
            <tr>
              <th style={tableHeaderStyle}>Provider Type</th>
              <th style={tableHeaderStyle}>CPT Code</th>
              <th style={tableHeaderStyle}>Minimum Completion Time
              </th>
              <th style={tableHeaderStyle}>Maximum Completion Time              </th>
              <th style={tableHeaderStyle}>Billing Rate ($)</th>
              <th style={tableHeaderStyle}>Contracted Rate ($)</th>
              <th style={tableHeaderStyle}>Action</th>
            </tr>
          </thead>
          <tbody>
          {data?.length === 0 ? (
            <tr>
              <td colSpan="5" style={{ textAlign: "center", padding: "10px" }}>
                No Contract Found
              </td>
            </tr>
          ) : (
            data.map((row) => (
              <tr key={row._id} style={tableRowStyle}>
                <td style={tableCellStyle}> {row.ProviderType?.map((type, index) => (
    <span key={type._id}>
      {type.name}
      {index < row.ProviderType.length - 1 && ", "}
    </span>
  )) || "N/A"}</td>
                <td style={tableCellStyle}>{row.CPTCode}</td>
                <td style={tableCellStyle}>{row.minRate}</td>
                <td style={tableCellStyle}>{row.maxRate}</td>
                <td style={tableCellStyle}>{row.BillingRate}</td>
                <td style={tableCellStyle}>{row.ContractedRate}</td>
                <td style={tableCellStyle}>
                  <button style={deleteButtonStyle} onClick={() => handleDelete(row._id)}>
                    Remove
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
        </table>

   <div>
  

      <label
        htmlFor="file-upload"
        style={{
          width: "100%",
          padding: "10px",
          backgroundColor: "#011434",
          color: "white",
          borderRadius: 10,textAlign:'center',
          marginBottom: 20,
          cursor: "pointer", // Add pointer cursor for a button-like feel
        }}
      >
        Import CSV
      </label>
      
      {/* Hidden input for file upload */}
      <input
        id="file-upload"
        type="file"
        accept=".csv"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
    </div>
    <div style={{width:'100%',padding:'10px',backgroundColor:'#011434',color:'white',borderRadius:10}}        onClick={handleExportToCSV}
   > Export to CSV</div>
      </div>
      <Modal open={showModal} onClose={closeModal} aria-labelledby="modal-title">
        <Box sx={modalStyle}>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tabs-container_two">
      <div className="insurance-tabs-row">
        {["Blue Cross Blue Shield", "United Healthcare", "Aetna", "Cigna"].map((insurance) => (
          <button
            key={insurance}
            className={`insurance-tab ${activeInsurance === insurance ? "insurance-active" : ""}`}
            onClick={() => handleTabClick(insurance, "insurance")}
          >
            {insurance}
          </button>
        ))}
      </div>
      <div className="state-tabs-row">
        {["Florida", "Arizona", "New Mexico", "Montana"].map((state) => (
          <button
            key={state}
            className={`state-tab ${activeState === state ? "state-active" : ""}`}
            onClick={() => handleTabClick(state, "state")}
          >
            {state}
          </button>
        ))}
      </div>
    </div>
            <h1 style={{ textAlign: "center",marginTop:10 }}>Add Contract
            </h1>
            <DropDownManuCustomMulti
                label="Provider Type"
                placeholder="Select option"
                options={providerType}
                name="ProviderType"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={handleSelectProviderType}
              />
            <p style={{ color: "red" }}>{errors.ProviderType?.message}</p>

            <DropDownManuCustomMulti
              label="CPT Code (Select Multiple)"
              options={cptCodes}
              name="CPTCode"
              register={register}
              id="_id"
              clearErrors={clearErrors}
              onSelect={handleLanguage}
              // defaultValue={row&&row?.CPTCode}
            />
            <p style={{ color: "red" }}>{errors.CPTCode?.message}</p>

            <Inputbox
              label="Minimum Completion Time"
              type="number"
              name="minRate"
              register={register}
              placeholder="Enter Duration in Minutes"
            />
            <p style={{ color: "red" }}>{errors.minRate?.message}</p>
            <Inputbox
              label="Maximum Completion Time"
              type="number"
              name="maxRate"
              register={register}
              placeholder="Enter Duration in Minutes"
            />
            <p style={{ color: "red" }}>{errors.maxRate?.message}</p>
            <Inputbox
              label="Billing Rate ($)"
              type="number"
              name="BillingRate"
              register={register}
              placeholder="Enter Duration in Minutes"
            />
            <p style={{ color: "red" }}>{errors.BillingRate?.message}</p>
            <Inputbox
              label="Contracted Rate ($)"
              type="number"
              name="ContractedRate"
              register={register}
              placeholder="Enter Duration in Minutes"
            />
            <p style={{ color: "red" }}>{errors.ContractedRate?.message}</p>

            <button type="submit" className="Save_buttonnn">
              {"Save"}
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddContract;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "78vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  outline: "none",
  overflowY: "auto",
};
const tableHeaderStyle = {
  padding: "10px",
  borderBottom: "2px solid #ddd",
  textAlign: "left",
  fontWeight: "bold",
  backgroundColor: "#f4f4f4",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};

const tableRowStyle = {
  borderBottom: "1px solid #ddd",
};
const editButtonStyle ={
  backgroundColor: "darkblue",
  marginBottom:3,
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
}
const deleteButtonStyle = {
  backgroundColor: "red",
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
