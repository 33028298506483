import React, { useState } from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar, { Navtopbar } from "../../Components/Navbar";
import AppointmentSettingsclinic from "./AppointmentSettingsclinic";
import GeneralSettingsAgency from "./GeneralSettingsAgency";
import UserSettings from "./UserSettings";
import ClinicalSettings from "./ClinicalSettings";
import { useNavigate } from "react-router-dom";
import Credentials from "./Credentials";
import AddAddress from "./AddAddress";
import ProviderType from "./ProviderType";
import Services from "./Services";
import BookingServices from "./BookingServices";
import ConditionTreated from "./ConditionTreated";
import Specialties from "./Specialties";
import DeliveryMode from "./DeliveryMode";
import PaymentSources from "./PaymentSources";
import PopulationServed from "./PopulationServed";
import ModalitiesScreen from "./ModalitiesScreen";
import InsuranceScreen from "./InsuranceScreen";
import LanguageScreen from "./LanguageScreen";
import SubscriptionScreen from "./SubscriptionScreen";
import AgencyPlanManagement from "./AgencyPlanManagement";
import NewAppointment from "./NewAppointment";
import AppointmentVisitStatus from "./AppointmentVisitStatus";
import VisitReason from "./VisitReason";
import AgencySubscriptionModules from "./AgencySubscriptionModules";
import ClientRequirements from "./ClientRequirements";
import DegreeType from "./DegreeType";
import AddContract from "./AddContract";

const Settingss = () => {
  const [activeTab, setActiveTab] = useState("general");

  // Function to toggle active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const navigate = useNavigate();
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navtopbar
          onClick={() => {
            navigate("/MyProfileAgency");
          }}
          title="Settings"
        />

        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30, flexDirection: "column" }}
        >
          <div className="admin_cards" style={{ marginBottom: 20 }}>
            <div
              className={
                activeTab === "general"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("general")}
            >
              General Settings
            </div>
            <div
              className={
                activeTab === "UserSettings"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("UserSettings")}
            >
              User Settings
            </div>
            <div
              className={
                activeTab === "appointment"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("appointment")}
            >
              Appointment Settings
            </div>
            <div
              className={
                activeTab === "ClinicalSettings"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("ClinicalSettings")}
            >
              Clinical Settings
            </div>
            <div
              className={
                activeTab === "Credentials"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("Credentials")}
            >
              Provider Requirement
            </div>
            <div
              className={
                activeTab === "ServiceAddress"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("ServiceAddress")}
            >
              Service Address
            </div>
            <div
              className={
                activeTab === "ProviderType"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("ProviderType")}
            >
              Provider Type
            </div>
            <div
              className={
                activeTab === "Services"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("Services")}
            >
              Services
            </div>
            {/* <div
              className={
                activeTab === "BookingServices"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("BookingServices")}
            >
          Booking Services
            </div> */}
            <div
              className={
                activeTab === "ConditionTreated"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("ConditionTreated")}
            >
              Condition Treated
            </div>

            <div
              className={
                activeTab === "Specialties"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("Specialties")}
            >
              Specialities
            </div>

            <div
              className={
                activeTab === "DeliveryMode"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("DeliveryMode")}
            >
              Delivery Mode
            </div>

            <div
              className={
                activeTab === "PaymentSources"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("PaymentSources")}
            >
              Payment Sources
            </div>
            <div
              className={
                activeTab === "InsuranceScreen"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("InsuranceScreen")}
            >
              Insurance
            </div>
            <div
              className={
                activeTab === "Modalities"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("Modalities")}
            >
              Modalities
            </div>

            <div
              className={
                activeTab === "PopulationServed"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("PopulationServed")}
            >
              Population Served
            </div>
            <div
              className={
                activeTab === "Languages"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("Languages")}
            >
              Languages
            </div>
            {/* <div
              className={
                activeTab === "addressesAPI"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("addressesAPI")}
            >
              State ( addresses API)
            </div> */}
            <div
              className={
                activeTab === "SubscriptionScreen"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("SubscriptionScreen")}
            >
              Subscription
            </div>
            <div
              className={
                activeTab === "AgencyPlanManagement"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("AgencyPlanManagement")}
            >
              Agency Plan Management
            </div>
            {/*  */}
            <div
              className={
                activeTab === "NewAppointment"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("NewAppointment")}
            >
               Appointment Types
            </div>
            <div
              className={
                activeTab === "AppointmentVisitStatus"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("AppointmentVisitStatus")}
            >
              Appointment and Visit Status
            </div>
            <div
              className={
                activeTab === "VisitReason"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("VisitReason")}
            >
             Visit Reason
            </div>
            <div
              className={
                activeTab === "AgencySubscriptionModules"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("AgencySubscriptionModules")}
            >
           Agency Subscription Modules
            </div>
            <div
              className={
                activeTab === "ClientRequirements"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("ClientRequirements")}
            >
         Client Requirements
            </div>
            <div
              className={
                activeTab === "DegreeType"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("DegreeType")}
            >
         Degree Type
            </div>
            <div
              className={
                activeTab === "AddContract"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("AddContract")}
            >
       Payer Contract
            </div>
            {/*  */}
          </div>
      
          
          <div
            className="dashboard-cards"
            style={{
              padding: 30,
              // paddingTop: 50,
              flexDirection: "column",
              paddingBottom: 0,
            }}
          >
            {activeTab === "general" && <GeneralSettingsAgency />}
            {activeTab === "appointment" && <AppointmentSettingsclinic />}
            {activeTab === "UserSettings" && <UserSettings />}
            {activeTab === "ClinicalSettings" && <ClinicalSettings />}
            {activeTab === "Credentials" && <Credentials />}
            {activeTab === "ServiceAddress" && <AddAddress />}
            {activeTab === "ProviderType" && <ProviderType />}
            {activeTab === "BookingServices" && <BookingServices />}
            {activeTab === "Services" && <Services />}
            {activeTab === "ConditionTreated" && <ConditionTreated />}
            {activeTab === "Specialties" && <Specialties />}
            {activeTab === "DeliveryMode" && <DeliveryMode />}
            {activeTab === "PaymentSources" && <PaymentSources />}
            {/* AgencyPlanManagement */}
            {activeTab === "Modalities" && <ModalitiesScreen />}
            {activeTab === "PopulationServed" && <PopulationServed />}
            {activeTab === "InsuranceScreen" && <InsuranceScreen />}
            {activeTab === "Languages" && <LanguageScreen />}
            {activeTab === "SubscriptionScreen" && <SubscriptionScreen />}
            {activeTab === "AgencyPlanManagement" && <AgencyPlanManagement />}
            {activeTab === "NewAppointment" && <NewAppointment />}
            {activeTab === "AppointmentVisitStatus" && <AppointmentVisitStatus />}
            {activeTab === "VisitReason" && <VisitReason />}
            {activeTab === "AgencySubscriptionModules" && <AgencySubscriptionModules />}
            {activeTab === "ClientRequirements" && <ClientRequirements />}
            {activeTab === "DegreeType" && <DegreeType />}
            {activeTab === "AddContract" && <AddContract />}
            {/*  */}
            {/* {activeTab === "addressesAPI" && (
              <>
                <h1>DegreeType</h1>
              </>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settingss;
