import React, { useState } from "react";
import Inputbox from "../../Components/Inputbox";
import { updateAgencyDoctor } from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";

const PaymentAccepted = ({ info, getDteails }) => {
  // console.log(info, "infoo======");
  const [isChecked, setIsChecked] = useState(info?.toggleOnOff || false);

  const onSubmit = async (data) => {
    data.id = info?._id;
    // console.log(data, "datadata===");
    // return
    try {
      const response = await updateAgencyDoctor(data);
      // console.log(response?.data?.data, "respon-=======");
      ShowToast(response.data.message, "success");
      getDteails();
    } catch (error) {
      console.log(error);
    }
  };
  const handleCheckboxChange = (event) => {
    const newValue = event.target.checked;
    setIsChecked(newValue);
    onSubmit({ toggleOnOff: newValue });
  };
  return (
    <div className="unique-container">
      <div className="unique-payors-section">
        <div
          style={{
            width: "100%",
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <h3 style={{ color: "#1a3a73", fontWeight: "bold" }}>
            Payors - Select the payors that you would like to accept in each
            state:
          </h3>
        </div>
        <div className="unique-payors-list">
          {info.Insurance && info.Insurance.length > 0 ? (
            info.Insurance.map((insurance, index) => (
              <div key={index} className="unique-payor-item">
                <label htmlFor={`payor-${index}`}>
                  {insurance.InsuranceType}
                </label>
                {/* <input type="checkbox" id={`payor-${index}`} /> */}
              </div>
            ))
          ) : (
            <div>No Insurance Found</div>
          )}
        </div>
      </div>

      <div className="unique-referrals-section">
        <h3 style={{ color: "#1a3a73", fontWeight: "bold", textAlign: "left" }}>
          Referrals
        </h3>
        <div className="unique-payor-item" style={{ border: "none" }}>
          <p>You are accepting referrals</p>
          <input
          type="checkbox"
          id="accept-referrals"
          name="toggleOnOff"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        </div>
        <p style={{ textAlign: "left" }}>
          Control the visibility of your profile on the Grow Therapy marketplace
        </p>
        <div className="unique-checkbox-container">
          <label htmlFor="accept-referrals"></label>
        </div>
      </div>
      <h3 style={{ fontWeight: "bold", textAlign: "left", marginTop: 15 }}>
        Private Pay Rates
      </h3>
      <div className="pay-rates-container">
        <div className="pay-rates-box">
          <div className="pay-rates-item">
            <h4>Cost per Cash Session (New Patient)</h4>
            {/* <p>250</p> */}
            <Inputbox
              inputstyle={{ marginTop: -13 }}
              type="number"
              name="address.zipCode"
            />
          </div>
          <div className="pay-rates-item">
            <h4>Cost per Cash Session (Follow-up)</h4>
            <Inputbox
              inputstyle={{ marginTop: -13 }}
              type="number"
              name="address.zipCode"
            />
          </div>
          <div className="pay-rates-item">
            <h4>No-Show Fee</h4>
            <Inputbox
              inputstyle={{ marginTop: -13 }}
              type="number"
              name="address.zipCode"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 30,
        }}
      >
        <div className="cancelButton">Cancel</div>
        <button type="submit" className="savebuttonn" style={{ width: 340 }}>
          Save
        </button>
      </div>
    </div>
  );
};

export default PaymentAccepted;
