import React, { useEffect, useState } from 'react'
import Inputbox from '../../Components/Inputbox'
import DropDownButton, { DropDownManuCustom, DropDownManuCustomMulti } from '../../Components/DropDownButton'
import { useForm } from 'react-hook-form';
import { ShowToast } from '../../../helpers/ToastService';
import { GetDeliveryMode, GetInsurance, updateAgencyDoctor } from '../../../api/helper';
import { useSelector } from 'react-redux';
const Specialtiesdata = ({ info, special ,getDteails}) => {
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [Insurance, setInsurance] = useState([]);
  const [deleveryMode, setDeleveryMode] = useState([])
  const [selectedValues, setSelectedValues] = useState([]);
  const [Insurancedefault, setInsurancedefault] = useState([])
  const [DeliveryModedefault, setDeliveryModedefault] = useState([])

  // console.log(special,'===special')
  // console.log(info, '===info')
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      licenseNumber:info?.licenseNumber,
      BoardCertifications:info?.BoardCertifications,
    }
  });

  useEffect(() => {
    console.log(info, '===info.LicenseType')
    const defaultVal = info?.categoryId?.map((e) => e._id) || [];
    const defaultinsurance = info?.Insurance || []; // This will be [] if no data
    const defaultdelivery = info?.DeliveryMode || [];


    setDeliveryModedefault(defaultdelivery)
    setInsurancedefault(defaultinsurance)
    setSelectedValues(defaultVal);
  }, [])
  useEffect(() => {
    console.log("Updated default values:", {
      DeliveryModedefault,
      Insurancedefault,
      selectedValues,
    });
  }, [DeliveryModedefault, Insurancedefault, selectedValues]);
  const handleSelect = (selectedOptions) => {
    // console.log('helloooo===',selectedOptions)
        let ids = selectedOptions.map((e) => e?._id);
      
        setValue("categoryId", ids, { shouldValidate: true });
      };
      const onSubmit = async (data) => {
        data.id = info?._id
        console.log(data, "datadata===");
        // return
        try {
          const response = await updateAgencyDoctor(data);
          console.log(response?.data?.data,"respon-=======")
          ShowToast(response.data.message, "success");
          getDteails();
        } catch (error) {
          console.log(error);
        }
      };
      const InsuranceType = async (id) => {
        // console.log(id, "id========");
        try {
          const response = await GetInsurance(`?id=${profiledata._id}`);
          // console.log(response?.data?.data, "GetInsurance=====");
          let resData = response?.data?.data
          resData = resData?.map((e)=>e.adminInsurenceId ? {...e, name:e.adminInsurenceId.name}:e)
          console.log(resData,'====>>>resData')
          setInsurance(resData);
    
        } catch (error) {
          console.log(error, "===e");
        }
      };

  const DeliveryMode = async () => {
    try {
      const response = await GetDeliveryMode(`?id=${profiledata._id}`);
      // console.log(response?.data?.data, "GetDeliveryMode======");
      setDeleveryMode(response?.data?.data || [])
    } catch (error) {
      console.log(error);
    }
  };
 useEffect(()=>{
  InsuranceType()
  DeliveryMode()
 },[])
 const selectDeliveryMode = (selectedOptions) => {
  let ids = selectedOptions?.map((e) => e?._id);
  setValue("DeliveryMode", ids, { shouldValidate: true });
};
const selectInsurance = (selectedOptions) => {
  let ids = selectedOptions?.map((e) => e?._id);
  setValue("Insurance", ids, { shouldValidate: true });
};
//  const selectInsurance = (selectedOption) => {
//   console.log("Payment Source changed to:", selectedOption.name);

//   setValue("Insurance", selectedOption._id);
//   clearErrors("Insurance");
// };
  return (
    <div>
     <form onSubmit={handleSubmit(onSubmit)} className="doctor-form">
      <div className="input-row" style={{alignItems:'center'}}>
    
           <DropDownManuCustomMulti
                label="Specialty"
                placeholder="Select option"
                options={special}
                name="categoryId"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={handleSelect} 
                defaultValue={selectedValues}
              />

        {/* <div style={{ width: "40px" }} />
        <Inputbox
          type="text"
          label="License "
          placeholder="Institute Name"
          register={register}
          name="licenseNumber"
        />
        <div style={{ width: "40px" }} />
        <Inputbox
          type="text"
          label="Board Certifications"
          placeholder="Enter Certifications"
          register={register}
          name="BoardCertifications"
        />*/}
      </div> 
      <div className="input-row" style={{alignItems:'center'}}>

     {/* <DropDownManuCustomMulti
                label="Insurance Currently Accepted"
                placeholder="Select option"
                options={Insurance}
                name="Insurance"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={selectInsurance}
                defaultValue={Insurancedefault}
              /> */}

 {/* <div style={{ width: "40px" }} /> */}
 {/* <Inputbox
   type="text"
   label="Service Types"
   placeholder="Add Service Types"
   register={register}
   name="licenseNumber"
 /> */}

 <DropDownManuCustomMulti
                label="Delivery Mode"
                placeholder="Select option"
                options={deleveryMode}
                name="DeliveryMode"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={selectDeliveryMode}
                defaultValue={DeliveryModedefault}
              />
</div>
<div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: 30,
                      }}
                    >
                      <div className="cancelButton">Cancel</div>
                      <button
                        type="submit"
                        className="savebuttonn"
                        style={{ width: 340 }}
                      >
                        Save
                      </button>
                    </div>
      </form>
    </div>
  )
}

export default Specialtiesdata