import React, { useEffect, useState } from "react";
import Inputbox from "../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { DropDownManuCustomMulti } from "../../Components/DropDownButton";
import {
  FetchMODALITIES,
  FetchPopulationServed,
  GetAgencyBooking,
  GetConditionTreated,
  GetDeliveryMode,
  updateAgencyDoctor,
} from "../../../api/helper";
import { useSelector } from "react-redux";
import { ShowToast } from "../../../helpers/ToastService";

const TreatmentMethods = ({ info, getDteails, special }) => {
  const [conditionTreated, setConditionTreated] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [PopulationServed, setPopulationServed] = useState();
  const [Modalities, setModalities] = useState();
  const [Treatment, setTreatment] = useState([]);
  const [selectedModalities, setSelectedModalities] = useState([]);
  const [selectedPopulation, setSelectedPopulation] = useState([]);
  const [selectedTreatmentTypes, setSelectedTreatmentTypes] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [deleveryMode, setDeleveryMode] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [DeliveryModedefault, setDeliveryModedefault] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    console.log(info, "===info.LicenseType");
    const defaultinsurance = info?.PopulationServed || [];
    const defaultModalities = info?.Modalities || [];
    const defaultservice = info?.ServiceType || [];
    const defaultcondi = info?.ConditionTreatedId || [];
    const defaultVal =
      info?.categoryId?.map((e) => e._id) || info?.categoryId || [];
    const defaultdelivery = info?.DeliveryMode || [];

    setDeliveryModedefault(defaultdelivery);
    setSelectedValues(defaultVal);
    setSelectedTreatmentTypes(defaultservice);
    setSelectedConditions(defaultcondi);
    setSelectedModalities(defaultModalities);
    setSelectedPopulation(defaultinsurance);
  }, []);
  useEffect(() => {
    console.log("Updated default values:", {});
  }, [
    selectedModalities,
    selectedPopulation,
    selectedTreatmentTypes,
    selectedConditions,
    DeliveryModedefault,
    selectedValues,
  ]);
  const fetchConditionTreated = async () => {
    const response = await GetConditionTreated(`?id=${profiledata?._id}`);
    console.log(response.data, "====>>>");
    setConditionTreated(response?.data?.data || []);
  };
  const PopulationServedGet = async () => {
    const response = await FetchPopulationServed(`?id=${profiledata?._id}`);
    // console.log(response?.data?.data, "data=====");
    setPopulationServed(response?.data?.data);
  };
  const getModalities = async () => {
    try {
      const response = await FetchMODALITIES(`?id=${profiledata?._id}`);
      setModalities(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const TreatmentTypeslist = async () => {
    try {
      const response = await GetAgencyBooking(`?id=${profiledata?._id}`);
      // console.log(response?.data?.data, "GetAgencyBooking======");
      setTreatment(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchConditionTreated();
    PopulationServedGet();
    getModalities();
    TreatmentTypeslist();
    DeliveryMode();
  }, []);
  const DeliveryMode = async () => {
    try {
      const response = await GetDeliveryMode(`?id=${profiledata._id}`);
      // console.log(response?.data?.data, "GetDeliveryMode======");
      setDeleveryMode(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(info);
  const onSubmit = async (data) => {
    data.id = info?._id;
    console.log(data, "datadata===");
    try {
      const response = await updateAgencyDoctor(data);
      ShowToast(response.data.message, "success");
      console.log(response?.data?.data, "respon-=======");
      getDteails();
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelect = (selectedOptions) => {
    let ids = selectedOptions.map((e) => e?._id);
    setValue("categoryId", ids, { shouldValidate: true });
  };
  const selectDeliveryMode = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("DeliveryMode", ids, { shouldValidate: true });
  };
  const handleSelectConditionTreated = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("ConditionTreatedId", ids, { shouldValidate: true });
  };
  const selectPopulationServed = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("PopulationServed", ids, { shouldValidate: true });
  };
  const selectModalities = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("Modalities", ids, { shouldValidate: true });
  };
  const selectTreatmentType = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("ServiceType", ids, { shouldValidate: true });
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="doctor-form">
        <div className="input-row">
          <DropDownManuCustomMulti
            label="Treatment Methods (Modalities)"
            placeholder="Add Treatment Methods (Modalities)"
            options={Modalities}
            name="Modalities"
            register={register}
            id="_id"
            clearErrors={clearErrors}
            onSelect={selectModalities}
            defaultValue={selectedModalities}
          />
          <div style={{ width: "40px" }} />
          <DropDownManuCustomMulti
            label="Population Treated (Ages Served) "
            placeholder="Add Population Treated (Ages Served)"
            options={PopulationServed}
            name="Population"
            register={register}
            id="_id"
            clearErrors={clearErrors}
            onSelect={selectPopulationServed}
            defaultValue={selectedPopulation}
          />
          <div style={{ width: "40px" }} />
          <DropDownManuCustomMulti
            label="Treatment Types (Service Types)"
            placeholder="Add Treatment Types (Service Types)"
            options={Treatment}
            name="ServiceType"
            register={register}
            id="_id"
            clearErrors={clearErrors}
            onSelect={selectTreatmentType}
            defaultValue={selectedTreatmentTypes}
          />
        </div>
        <div className="input-row">
          <DropDownManuCustomMulti
            label="Condition Treated"
            placeholder="Select option"
            options={conditionTreated}
            name="ConditionTreatedId"
            register={register}
            id="_id"
            clearErrors={clearErrors}
            onSelect={handleSelectConditionTreated}
            defaultValue={selectedConditions}
          />{" "}
          <div style={{ width: "40px" }} />
          <DropDownManuCustomMulti
            label="Specialty"
            placeholder="Select option"
            options={special}
            name="categoryId"
            register={register}
            id="_id"
            clearErrors={clearErrors}
            onSelect={handleSelect}
            defaultValue={selectedValues}
          />
          <div style={{ width: "40px" }} />
          <DropDownManuCustomMulti
            label="Delivery Mode"
            placeholder="Select option"
            options={deleveryMode}
            name="DeliveryMode"
            register={register}
            id="_id"
            clearErrors={clearErrors}
            onSelect={selectDeliveryMode}
            defaultValue={DeliveryModedefault}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 30,
          }}
        >
          <div className="cancelButton">Cancel</div>
          <button type="submit" className="savebuttonn" style={{ width: 340 }}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default TreatmentMethods;
