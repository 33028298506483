import React, { useState } from "react";

function PatientHistory() {
  const [modalVisible, setModalVisible] = useState(false);
const [ismedicalHistory, setismedicalHistory] = useState(false)
const [isfamilyHistory, setisfamilyHistory] = useState(false)
const [ispsychiatricHistory, setispsychiatricHistory] = useState(false)
const [islifestyleDeterminants, setislifestyleDeterminants] = useState(false)

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const saveChanges = () => {
    alert("Changes saved successfully!");
    closeModal();
  };
  console.log(modalVisible, "hellooooo===-=-=-=-=-=-=");
  return (
    <div className="container_history">
      <div className="history-sections_history">
        <div className="history-section_history">
          <h3>
            Medical History
            <button className="open-modal-btn_history" onClick={openModal}>
              Edit
            </button>
          </h3>
        </div>
 
        <div className="history-section_history">
          <h3>
            Surgical History
            <button className="open-modal-btn_history" onClick={()=>{setismedicalHistory(true)}}>
              Edit
            </button>
          </h3>
        </div>
        <div className="history-section_history">
          <h3>
            Family History
            <button className="open-modal-btn_history" onClick={() => { setisfamilyHistory(true); }}>
              Edit
            </button>
          </h3>
        </div>
        <div className="history-section_history">
          <h3>
            Psychiatric History
            <button className="open-modal-btn_history"onClick={() => { setispsychiatricHistory(true); }}>
              Edit
            </button>
          </h3>
        </div>
        <div className="history-section_history">
          <h3>
            Lifestyle Determinants
            <button className="open-modal-btn_history" onClick={openModal}>
              Edit
            </button>
          </h3>
        </div>
      </div>

      {modalVisible && (
        <div
          className={`modal_history ${modalVisible ? "" : "modal_hidden"}`}
          id="modal"
        >
          <div className="modal-content_history">
            <div className="modal-header_history">
              <div className="modal-title">{"Medical History"}</div>
              <button className="close-btn_history" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body_history" id="modal-body">
              {[
                "Head",
                "Eyes",
                "Ears",
                "Nose/Sinuses",
                "Mouth/Throat/Teeth",
                "Cardiovascular",
                "Respiratory",
                "Neurological",
                "Musculoskeletal",
                "Skin",
                "Endocrine",
                "Infectious Diseases",
              ].map((category, index) => (
                <div className="category_history" key={index}>
                  <h4>{category}</h4>
                  {(() => {
                    switch (category) {
                      case "Head":
                        return [
                          "Trauma",
                          "Migraines",
                          "Concussion",
                          "Skull Fracture",
                        ];
                      case "Eyes":
                        return [
                          "Blindness",
                          "Cataracts",
                          "Glaucoma",
                          "Wears glasses/contacts",
                          "Eye Infection",
                        ];
                      case "Ears":
                        return [
                          "Hearing Loss",
                          "Tinnitus",
                          "Ear Infections",
                          "Hearing Aids",
                        ];
                      case "Nose/Sinuses":
                        return [
                          "Sinus Infection",
                          "Allergic Rhinitis",
                          "Nasal Fracture",
                          "Nasal Polyps",
                        ];
                      case "Mouth/Throat/Teeth":
                        return [
                          "Gum Disease",
                          "Tooth Decay",
                          "Tonsillitis",
                          "Dentures",
                        ];
                      case "Cardiovascular":
                        return [
                          "Hypertension",
                          "Angina",
                          "Heart Attack",
                          "Dysrhythmia",
                        ];
                      case "Respiratory":
                        return ["Asthma", "COPD", "Pneumonia", "Bronchitis"];
                      case "Neurological":
                        return [
                          "Seizures",
                          "Stroke",
                          "Migraines",
                          "Multiple Sclerosis",
                        ];
                      case "Musculoskeletal":
                        return [
                          "Arthritis",
                          "Gout",
                          "Fractures",
                          "Muscle Strain",
                        ];
                      case "Skin":
                        return ["Psoriasis", "Eczema"];
                      case "Endocrine":
                        return ["Diabetes Type 1", "Diabetes Type 2"];
                      case "Infectious Diseases":
                        return ["Tuberculosis", "HIV"];
                      default:
                        return [];
                    }
                  })().map((item, i) => (
                    <label key={i}>
                      <input type="checkbox" /> {item}
                    </label>
                  ))}
                </div>
              ))}
            </div>
            <div className="modal-footer_history">
              <button onClick={closeModal}>Cancel</button>
              <button onClick={closeModal}>Save</button>
            </div>
          </div>
        </div>
      )}
  {ismedicalHistory && (
  <div className={`modal_history ${ismedicalHistory ? "" : "modal_hidden"}`} id="modal">
    <div className="modal-content_history">
      <div className="modal-header_history">
        <div className="modal-title">{"Surgical History"}</div>
        <button className="close-btn_history" onClick={() => { setismedicalHistory(false); }}>
          &times;
        </button>
      </div>
      <div className="modal-body_history" id="modal-body">
        {["Cardiovascular Surgeries", "Common Surgeries", "Ophthalmology Surgeries", "Gynecological Surgeries", "Urological Surgeries", "Orthopedic Surgeries", "ENT Surgeries", "Skin and Cancer Surgeries", "Miscellaneous Surgeries"].map((category, index) => (
          <div className="category_history" key={index}>
            <h4>{category}</h4>
            {(() => {
              switch (category) {
                case "Cardiovascular Surgeries":
                  return [
                    "Aneurysm repair",
                    "CABG (Coronary Artery Bypass Graft)",
                    "Carotid endarterectomy/stent",
                    "Pacemaker/defibrillator",
                    "PTCA/PCI"
                  ];
                case "Common Surgeries":
                  return [
                    "Appendectomy",
                    "Back surgery",
                    "Bariatric surgery/gastric bypass",
                    "Bilateral tubal ligation",
                    "Breast resection/mastectomy",
                    "Carpal tunnel release surgery"
                  ];
                case "Ophthalmology Surgeries":
                  return [
                    "Cataract/lens surgery",
                    "LASIK"
                  ];
                case "Gynecological Surgeries":
                  return [
                    "Cesarean section",
                    "Dilation and curettage",
                    "Hysterectomy",
                    "TAH-BSO (Total Abdominal Hysterectomy with Bilateral Salpingo-Oophorectomy)"
                  ];
                case "Urological Surgeries":
                  return [
                    "Prostate surgery",
                    "Prostatectomy",
                    "TURP (Trans Resection of the Prostate)",
                    "Vasectomy"
                  ];
                case "Orthopedic Surgeries":
                  return [
                    "Hip arthroplasty",
                    "Hip replacement",
                    "Knee arthroplasty",
                    "Rotator cuff surgery",
                    "Spinal fusion"
                  ];
                case "ENT Surgeries":
                  return [
                    "Sinus surgery",
                    "Tonsillectomy/Adenoidectomy",
                    "Nasal surgery"
                  ];
                case "Skin and Cancer Surgeries":
                  return [
                    "Skin cancer excision",
                    "Hemorrhoid surgery"
                  ];
                case "Miscellaneous Surgeries":
                  return [
                    "Cholecystectomy/bile duct surgery",
                    "Laminectomy",
                    "Spinal fusion"
                  ];
                default:
                  return [];
              }
            })().map((item, i) => (
              <label key={i}>
                <input type="checkbox" /> {item}
              </label>
            ))}
          </div>
        ))}
      </div>
      <div className="modal-footer_history">
        <button onClick={() => { setismedicalHistory(false); }}>Cancel</button>
        <button onClick={() => { setismedicalHistory(false); }}>Save</button>
      </div>
    </div>
  </div>
)}
{isfamilyHistory && (
  <div className={`modal_history ${isfamilyHistory ? "" : "modal_hidden"}`} id="modal">
    <div className="modal-content_history">
      <div className="modal-header_history">
        <div className="modal-title">{"Family History"}</div>
        <button className="close-btn_history" onClick={() => { setisfamilyHistory(false); }}>
          &times;
        </button>
      </div>
      <div className="modal-body_history" id="modal-body">
        {["General - Health Conditions", "General - Cardiovascular and Others", "Cancer"].map((category, index) => (
          <div className="category_history" key={index}>
            <h4>{category}</h4>
            {(() => {
              switch (category) {
                case "General - Health Conditions":
                  return [
                    "No Health Concern",
                    "Arthritis",
                    "Asthma",
                    "Bleeding Disorder",
                    "CAD < age 55 (Coronary Artery Disease)",
                    "COPD",
                    "Diabetes"
                  ];
                case "General - Cardiovascular and Others":
                  return [
                    "Heart Attack",
                    "Heart Disease",
                    "High Cholesterol",
                    "Hypertension",
                    "Mental Illness",
                    "Osteoporosis",
                    "Stroke"
                  ];
                case "Cancer":
                  return [
                    "Breast CA",
                    "Colon CA",
                    "Ovarian CA",
                    "Prostate CA",
                    "Uterine CA",
                    "Other CA"
                  ];
                default:
                  return [];
              }
            })().map((item, i) => (
              <label key={i}>
                <input type="checkbox" /> {item}
              </label>
            ))}
          </div>
        ))}
      </div>
      <div className="modal-footer_history">
        <button onClick={() => { setisfamilyHistory(false); }}>Cancel</button>
        <button onClick={() => { setisfamilyHistory(false); }}>Save</button>
      </div>
    </div>
  </div>
)}
{ispsychiatricHistory && (
  <div className={`modal_history ${ispsychiatricHistory ? "" : "modal_hidden"}`} id="modal">
    <div className="modal-content_history">
      <div className="modal-header_history">
        <div className="modal-title">{"Psychiatric History"}</div>
        <button className="close-btn_history" onClick={() => { setispsychiatricHistory(false); }}>
          &times;
        </button>
      </div>
      <div className="modal-body_history" id="modal-body">
        {[
          {
            category: "Psychiatric Conditions",
            items: [
              "Anxiety Disorders",
              "Depression",
              "Bipolar Disorder",
              "Schizophrenia",
              "Obsessive-Compulsive Disorder",
              "Post-Traumatic Stress Disorder",
              "Substance Use Disorders",
              "Eating Disorders",
              "Autism Spectrum Disorder",
              "Attention-Def/Hyperactivity Disorder",
              "Personality Disorders",
            ],
          },
          {
            category: "Neurocognitive Disorders",
            items: [
              "Dementia",
              "Alzheimer’s Disease",
              "Mild Cognitive Impairment (MCI)",
              "Parkinson’s Disease",
              "Huntington’s Disease",
            ],
          },
          {
            category: "Developmental Disorders",
            items: [
              "Intellectual Disability",
              "Speech and Language Disorders",
              "Learning Disorders",
              "Tourette Syndrome",
            ],
          },
          {
            category: "Symptoms",
            items: [
              "Suicidal Thoughts",
              "Self-Harm",
              "Hearing/Seeing Things",
              "Delusions (False Beliefs)",
              "Anxiety or Panic Attacks",
              "Mood Swings",
              "Paranoia",
              "Other: ________________",
            ],
          },
          {
            category: "Mental Health Treatment History",
            items: [
              "Hospitalized for Psychiatric Condition",
              "Therapy for Mental Health Concerns",
              "Prescribed Psychiatric Medications",
              "History of Electroconvulsive Therapy",
              "Other: ________________",
            ],
          },
          {
            category: "Substance Use",
            items: [
              "Alcohol Use",
              "Marijuana Use",
              "Illicit Drug Use",
              "Intravenous Drug Use",
              "No Substance Use",
            ],
          },
        ].map((section, index) => (
          <div className="category_history" key={index}>
            <h4>{section.category}</h4>
            {section.items.map((item, i) => (
              <label key={i}>
                <input type="checkbox" /> {item}
              </label>
            ))}
          </div>
        ))}
      </div>
      <div className="modal-footer_history">
        <button onClick={() => { setispsychiatricHistory(false); }}>Cancel</button>
        <button onClick={() => { setispsychiatricHistory(false); }}>Save</button>
      </div>
    </div>
  </div>
)}
 {islifestyleDeterminants && (
        <div
          className={`modal_history ${islifestyleDeterminants ? "" : "modal_hidden"}`}
          id="modal"
        >
          <div className="modal-content_history">
            <div className="modal-header_history">
              <div className="modal-title">{"Medical History"}</div>
              <button className="close-btn_history" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body_history" id="modal-body">
              {[
                "Head",
                "Eyes",
                "Ears",
                "Nose/Sinuses",
                "Mouth/Throat/Teeth",
                "Cardiovascular",
                "Respiratory",
                "Neurological",
                "Musculoskeletal",
                "Skin",
                "Endocrine",
                "Infectious Diseases",
              ].map((category, index) => (
                <div className="category_history" key={index}>
                  <h4>{category}</h4>
                  {(() => {
                    switch (category) {
                      case "Head":
                        return [
                          "Trauma",
                          "Migraines",
                          "Concussion",
                          "Skull Fracture",
                        ];
                      case "Eyes":
                        return [
                          "Blindness",
                          "Cataracts",
                          "Glaucoma",
                          "Wears glasses/contacts",
                          "Eye Infection",
                        ];
                      case "Ears":
                        return [
                          "Hearing Loss",
                          "Tinnitus",
                          "Ear Infections",
                          "Hearing Aids",
                        ];
                      case "Nose/Sinuses":
                        return [
                          "Sinus Infection",
                          "Allergic Rhinitis",
                          "Nasal Fracture",
                          "Nasal Polyps",
                        ];
                      case "Mouth/Throat/Teeth":
                        return [
                          "Gum Disease",
                          "Tooth Decay",
                          "Tonsillitis",
                          "Dentures",
                        ];
                      case "Cardiovascular":
                        return [
                          "Hypertension",
                          "Angina",
                          "Heart Attack",
                          "Dysrhythmia",
                        ];
                      case "Respiratory":
                        return ["Asthma", "COPD", "Pneumonia", "Bronchitis"];
                      case "Neurological":
                        return [
                          "Seizures",
                          "Stroke",
                          "Migraines",
                          "Multiple Sclerosis",
                        ];
                      case "Musculoskeletal":
                        return [
                          "Arthritis",
                          "Gout",
                          "Fractures",
                          "Muscle Strain",
                        ];
                      case "Skin":
                        return ["Psoriasis", "Eczema"];
                      case "Endocrine":
                        return ["Diabetes Type 1", "Diabetes Type 2"];
                      case "Infectious Diseases":
                        return ["Tuberculosis", "HIV"];
                      default:
                        return [];
                    }
                  })().map((item, i) => (
                    <label key={i}>
                      <input type="checkbox" /> {item}
                    </label>
                  ))}
                </div>
              ))}
            </div>
            <div className="modal-footer_history">
              <button onClick={()=>{setislifestyleDeterminants(false)}}>Cancel</button>
              <button onClick={()=>{setislifestyleDeterminants(false)}}>Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PatientHistory;
