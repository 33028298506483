export const BASE_URL = "https://api.ennhealth.app/api/";
export const IMAGE_BASE_URL = "https://api.ennhealth.app/";

export const POST_METHOD = "POST";
export const GET_METHOD = "GET";
export const PUTMETHOD = "PUT";
export const DELETE = "DELETE";
export const REGISTER = "auth/user/register";
export const UPLOAD_IMAGE ="fileUpload"
export const SLOT = "slots";
export const AVAILABLE_DAYS = "availableDays";
export const CREATE_AVAILABLE_DAYS = "availableDays/createAvailableDays";



// agency flow 
export const AGENCY_LOGIN ='agency/login'
export const AGENCY_SIGNUP="agency/signUp"
export const Agency_CHANGE_PASSWORD="agency/changePassword"
export const CLINIC_UPDATE="agency/updateUser"
export const CLINIC_PROFILE_GET="agency/getProfile"
export const DOCTOR_REQUETS="doctor/ClinicList"
export const PATIENT_LIST="doctor/getAllPatientRequest?"
export const GET_AGENCY_REQUEST_LIST="doctor/getAgencyRequestList"
export const FORGET = "forget"
export const RESET_PASSWORD ="RestePassword"




// doctor flow 
export const DOCTOR_LOGIN ='doctor/login'
export const DOCTOR_SIGNUP="doctor/signUp"
export const GET_CATEGORY ="category"
export const DOCTOR_CHANGE_PASSWORD="doctor/changePassword"
export const GET_DOCTOR_PROFILE = "doctor/getProfile"
export const UPDATE_DOCTOR_PROFILE = "doctor/updateUser"
export const AGENCY_LIST = "agency/List"
export const GET_PATIENT_DETAIL = "doctor/requestPatinetDetails?id="
export const GET_Clinic_DETAIL = "doctor/ClinicDetails?id="
export const APPOINTMENTS_CONFIRM="doctor/appointmentChangeStatus"
export const ADD_PROBLEM="doctor/addProblems"
export const GET_PROBLEM="doctor/getProblemsList?patientId="
export const REQUEST="request"
export const REQUEST_UPDATE="request?requestId="
export const PATIENT_REQUEST = "doctor/patientRequest"
export const AGENCY_DOCTOR_REQUEST_UPDATE="request/updateAgencyDoctor"
export const AGENCY_DOCTOR_REQUEST_DETAILS="request/getAgencyDoctorDetails"


// patient flow
export const PATIENT_LOGIN ='patient/login'
export const PATIENT_SIGNUP = "patient/signUp"
export const PATIENT_GETPROFILE = "patient/getProfile"
export const PATIENT_UPDATE="patient/updateUser"
export const GET_PROVIDER_LIST="patient/getProviderList"
export const APPOINTMENTS="appointments"
export const CLINIC_LIST="doctor/clinicRequestList?limit=100"
export const GetJoinRequest="agency/patinetGetJoinRequest"
export const PATIENTUPDATEJOINREQUEST="agency/patinetUpdateJoinRequest"
//
export const SERVICECODE="serviceCode"
export const SERVICELOCATION="ServiceLocation"
export const GET_PROVIDER_TYPE="providerType"
export const GET_SERVICE_BOOKING='agency/BookingServices'
export const GET_AGENCY_BOOKING='agency/TreatmentTypes'
export const GET_CREDENTIALS='agency/Credentials'
export const PROVIDERREQUIREMENT='agency/providerRequirement'
export const CLIENTREQUIREMENT='agency/ClientRequirement'
export const GET_CONDITION_TREATED='agency/ConditionTreated'
export const GET_SPECIALTUES='agency/Specialties'
export const GET_DELIVERY_MODE='agency/DeliveryMode'
export const GET_PAYMENT_SOURCES='agency/PaymentSources'
export const MODALITIES='Modalities'
export const LANGUAGES='Languages'
export const INSURANCE='Insurance'
export const SUBSCRIPTION='subscriptions'
export const AGENCY_PLAN_MANAGEMENT ='agency/AgencyPlanManagement'
export const ADMIN_INSURENCE = "adminInsurence"
export const CPTCODE ='cptCode'

export const PATIENT_UPDATE_REQUEST ='patient/patientRequestUpdate'