import React from 'react'

const ClientTeam = () => {
  return (
    <div className="client-history">
<div className="client-history__buttons">
      <button className="client-history__button client-history__button--cancel">
        Cancel
      </button>
      <button className="client-history__button client-history__button--save">
        Save Changes
      </button>
    </div>
</div>
  )
}

export default ClientTeam