import React, { useEffect, useState } from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar from "../../Components/Navbar";
import { useLocation } from "react-router-dom";
import Clientinfomartion from "./Patientinfoeditscreens/Clientinfomartion";
import Addresses from "./Patientinfoeditscreens/Addresses";
import ClientTeam from "./Patientinfoeditscreens/ClientTeam";
import Consents from "./Patientinfoeditscreens/Consents";
import ClientHistory from "./Patientinfoeditscreens/ClientHistory";
import ClientIdentification from "./Patientinfoeditscreens/ClientIdentification";
import EmergencyContacts from "./Patientinfoeditscreens/EmergencyContacts";
import ClientPayment from "./Patientinfoeditscreens/ClientPayment";
import Notification from "./Patientinfoeditscreens/Notification";
import { Getagencypatientdetails } from "../../../api/helper";

const ClientEditbyagency = () => {
  const location = useLocation();
  const [info, setInfo] = useState(location.state.data);
  console.log(info,'==>>>info')
  const [activeTab, setActiveTab] = useState("info");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const getDteails = async () => {
    const response = await Getagencypatientdetails(info._id);
    // console.log(response.data.data, "===response>>>>>");
    setInfo(response.data.data);
  };
  useEffect(()=>{
    getDteails()
  },[location.state.data])
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="doctor-details-container">
            <div className="Doctor_Details_txtt">Patient Details</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: 20,
              }}
            >
              <div className="doctor-header">
                <img
                  className="doctor-image"
                  src={
                    info?.patientId?.image
                      ? info?.patientId?.image
                      :  "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                  }
                  alt="Doctor"
                />
                <div className="doctor-info">
                  <h3 style={{ textAlign: "left" }}>
                   {info?.patientId?.firstName +' '+ info?.patientId?.lastName}
                  </h3>
           

           
            

                  <div className="rating">{/* <span>⭐⭐⭐⭐☆</span> */}</div>
                  {/* <p style={{ textAlign: 'left', marginTop: 5 }}>(0 Reviews)</p> */}
                </div>
              </div>
            
            </div>
            <div className="tabs-container" style={{ paddingRight: 20 }}>
              <button
                className={`tab-button ${activeTab === "info" ? "active" : ""}`}
                onClick={() => handleTabClick("info")}
              >
               Information
              </button>
              <button
                className={`tab-button ${
                  activeTab === "Addresses" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Addresses")}
              >
              Addresses

              </button>
              {/* <button
                className={`tab-button ${
                  activeTab === "specialties" ? "active" : ""
                }`}
                onClick={() => handleTabClick("specialties")}
              >
                Specialties and Conditions Treated
              </button> */}
              <button
                className={`tab-button ${
                  activeTab === "Payment" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Payment")}
              >
              Payment
              </button>
              <button
                className={`tab-button ${
                  activeTab === "Notification" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Notification")}
              >
              Notification
              </button>
              <button
                className={`tab-button ${
                  activeTab === "EmergencyContacts" ? "active" : ""
                }`}
                onClick={() => handleTabClick("EmergencyContacts")}
              >
               Emergency Contacts

              </button>
              <button
                className={`tab-button ${
                  activeTab === "Identification" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Identification")}
              >
               Identification

              </button>
              <button
                className={`tab-button ${
                  activeTab === "History" ? "active" : ""
                }`}
                onClick={() => handleTabClick("History")}
              >
               History
              </button>
              <button
                className={`tab-button ${
                  activeTab === "Consents" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Consents")}
              >
               Consents

              </button>
              <button
                className={`tab-button ${
                  activeTab === "Team" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Team")}
              >
               Team
              </button>
        
            </div>
            {activeTab === "info" && <Clientinfomartion info={info} getDteails={getDteails}  />}
              {activeTab === "Addresses" && <Addresses info={info} getDteails={getDteails}  />}
              {activeTab === "Payment" && <ClientPayment info={info} getDteails={getDteails}  />}
              {activeTab === "Notification" && <Notification info={info} getDteails={getDteails}  />}
              {activeTab === "EmergencyContacts" && <EmergencyContacts info={info} getDteails={getDteails} />}
              {activeTab === "Identification" && <ClientIdentification info={info} getDteails={getDteails} />}
              {activeTab === "History" && <ClientHistory info={info} getDteails={getDteails}  />}
              {activeTab === "Consents" && <Consents info={info} getDteails={getDteails} />}
              {activeTab === "Team" && <ClientTeam info={info} getDteails={getDteails}  />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientEditbyagency;
