// import { View, Text } from 'react-native'
// import React from 'react'

// const AddPatientpopupagency = () => {
//   return (
//     <View>
//       <Text>AddPatientpopupagency</Text>
//     </View>
//   )
// }

// export default AddPatientpopupagency
import React, { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { GoogleMap, useLoadScript, Autocomplete } from '@react-google-maps/api';
import Inputbox from "../../Components/Inputbox";
import { DropDownManuCustom, DropDownManuCustomMulti } from "../../Components/DropDownButton";
import { Clinicrequestlist, createAgencyPatient, GetAgencyBooking, GetClinic, GetInsurance, GetPaymentSources, PatientRequest, PatientSignup } from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  dateOfBirth: Yup.date()
    .typeError("Date of Birth must be a valid date")
    .required("Date of Birth is required")
    .max(new Date(), "Date of Birth cannot be in the future"),
  gender: Yup.string().required("Gender is required"),
  ssn: Yup.string().required("SSN is required").matches(/^[0-9]+$/, "Ssn must be digits").min(4, 'Ssn must be at least 4 digits')
    .max(4, 'Ssn cannot exceed 4 digits'),
  phone: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]+$/, "Phone number must be digits"),
  email: Yup.string().email("Invalid email").required("Email is required"),

  address: Yup.object().shape({
    // street: Yup.string().required('Street is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    zipCode: Yup.string()
      .required('Zip code is required')
      // .matches(/^[0-9]+$/, 'Zip code must be digits')
      .min(5, 'Zip code must be at least 5 digits')
      .max(10, 'Zip code cannot exceed 10 digits'),
  }),

});


const AddPatientpopupagency = ({ onClose }) => {
  const profiledata = useSelector(state => state?.cookies?.userDetails);
  const [autocomplete, setAutocomplete] = useState(null);
  const [servicedata, setservicedata] = useState([]);
  const [paymentSourcedata, setpaymentSource] = useState([]);
  const [selectedPaymentSource, setSelectedPaymentSource] = useState("");
  const [Insurance, setInsurance] = useState([]);
  const [clinicdata, setClinicData] = useState([]);
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleChange = (e) => {
    reset({
      phone: e,
    });
  };
  const FetchDatalist = async (id) => {
    try {
      const response = await GetAgencyBooking(`?id=${id}`);
      setservicedata(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const FetchPaymentSource = async (id) => {
    try {
      const response = await GetPaymentSources(`?id=${id}`);
      setpaymentSource(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const InsuranceType = async (id) => {
    try {
      const response = await GetInsurance(`?id=${id}`);
      let resData = response?.data?.data
      resData = resData?.map((e)=>e.adminInsurenceId ? {...e, name:e.adminInsurenceId.PayerName}:e)
      setInsurance(resData);
    } catch (error) {
      console.log(error, "===e");
    }
  };


  const handlePaymentSourceChange = (selectedOption) => {
    console.log("Payment Source changed to:",selectedOption);
    setSelectedPaymentSource(selectedOption.name);
    setValue("PaymentSourcesId", selectedOption._id);
    clearErrors("PaymentSourcesId");
  };

  const Selectservice = (selectedOptions) => {
    let ids = selectedOptions.map((e) => e?._id);
    setValue("TreatmentTypesId", ids, { shouldValidate: true });
  };

  const selectInsurance = (selectedOption) => {
    console.log("Payment Source changed to:", selectedOption.name);
    setValue("PaymentSourcesId", selectedOption._id);
    clearErrors("PaymentSourcesId");
  };


  const getClinician = async () => {
    try {
      const response = await Clinicrequestlist();
      const data = response?.data?.data?.data?.map((e) => {
        return {
          name: `${e.companyName} / ${e.uniqueId}`,
          ...e,
        };
      });
      setClinicData(data || []);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getClinician();
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM', // Replace with your Google Maps API key
    libraries: ['places'], // Include 'places' library for autocomplete
  });
  if (!isLoaded) return <div>Loading...</div>;
  // const handlePlaceSelect = () => {
  //   const place = autocomplete.getPlace();
  //   const addressComponents = place.address_components;

  //   const getAddressComponent = (type) => {
  //     const component = addressComponents.find(c => c.types.includes(type));
  //     return component ? component.long_name : '';
  //   };

  //   setValue('address.country', getAddressComponent('country'));
  //   setValue('address.state', getAddressComponent('administrative_area_level_1'));
  //   setValue('address.city', getAddressComponent('locality'));
  //   setValue('address.street', getAddressComponent('route'));
  //   setValue('address.zipCode', getAddressComponent('postal_code'));
  // };
  const handlePlaceSelect = async () => {
    const place = autocomplete.getPlace();
    const addressComponents = place.address_components;

    // Function to get address component by type
    const getAddressComponent = (type) => {
      const component = addressComponents.find((c) => c.types.includes(type));
      return component ? component.long_name : "";
    };

    // Set country, state, and city
    setValue("address.country", getAddressComponent("country"));
    setValue("address.state", getAddressComponent("administrative_area_level_1"));
    setValue("address.city", getAddressComponent("locality"));

    // Extract street number, route, sublocality (for sectors), neighborhood, premise (building), establishment (business)
    const streetNumber = getAddressComponent("street_number");
    const route = getAddressComponent("route");
    const sublocality = getAddressComponent("sublocality");
    const neighborhood = getAddressComponent("neighborhood");
    const premise = getAddressComponent("premise"); // Building name or room number
    const establishment = getAddressComponent("establishment"); // Business names

    // Declare fullStreet and build it
    let fullStreet = "";

    // Priority handling: check if it's an establishment (business), premise, or standard street address
    if (establishment) {
      fullStreet = establishment; // Use business name if available
    } else if (premise) {
      fullStreet = `${premise}, ${route || sublocality || neighborhood}`; // Use building/room with route
    } else if (streetNumber && route) {
      fullStreet = `${streetNumber} ${route}`; // Standard street address
    } else if (sublocality) {
      fullStreet = sublocality; // For sectors
    } else if (neighborhood) {
      fullStreet = neighborhood; // If categorized as neighborhood
    }

    // Fallback: if no fullStreet found, use locality, state, country
    if (!fullStreet) {
      fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent("administrative_area_level_1")}, ${getAddressComponent("country")}`;
    }

    // Set the full street address in the form
    setValue("address.street", fullStreet);

    // Set the zip code
    setValue("address.zipCode", getAddressComponent("postal_code"));
  };
  const onSubmit = async (values) => {

    const data = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      DOB: moment(values?.dateOfBirth).format('YYYY-MM-DD'),
      gender: values?.gender,
      SSN: values?.ssn,
      phone: values?.phone,
      email: values?.email,
      doctorId: profiledata?._id,
      address: values?.address,
      password: "123456",
      agencyId: values?.agencyId
    };

    if (values?.agencyId) {
      data.agencyId = values?.agencyId
    }
    try {
      const resposne = await PatientSignup(data);
      // console.log(resposne?.data?.data._id, "respones====--")
      if (data.agencyId) {
        let saveToData = {
          patientId: resposne?.data?.data?._id,
          agencyId: data?.agencyId,
          status: "pending",
          ...data
        };
        await createAgencyPatient(saveToData);
      }

      ShowToast(resposne.data.message, "success");
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="review-popup-overlay">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="review-popup-container_three"
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <div className="service_rating_txt" style={{}}>
            Add Patient
          </div>
          <div className="textttttttt">
            A notification inviting the member to join the practice will be
            sent.
          </div>
          <div className="input-row">
            <div style={{ width: "48%" }}>
              <Inputbox
                name="firstName"
                register={register}
                label="Legal First Name"
                type="text"
                placeholder="Enter First Name"
                style={{ backgroundColor: "#D9D9D9", height: 40 }}
                lebalstyle={{ color: "white" }}
              />
              {errors.firstName && (
                <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                  {" "}
                  {errors.firstName.message}
                </p>
              )}
            </div>
            <div style={{ width: "48%" }}>
              <Inputbox
                label="Legal Last Name"
                type="text"
                placeholder="Enter Last Name"
                name="lastName"
                register={register}
                style={{ backgroundColor: "#D9D9D9", height: 40 }}
                lebalstyle={{ color: "white" }}
              />
              {errors.lastName && (
                <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                  {" "}
                  {errors.lastName.message}
                </p>
              )}
            </div>
          </div>

          <div className="input-row">
            <div style={{ width: "30%" }}>
              <Inputbox
                label="Date of Birth"
                type="date"
                placeholder="DD/MM/YY"
                name="dateOfBirth"
                register={register}
                style={{ backgroundColor: "#D9D9D9", height: 40 }}
                lebalstyle={{ color: "white" }}
              />
              {errors.dateOfBirth && (
                <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                  {errors.dateOfBirth.message}
                </p>
              )}

            </div>
            <div style={{ width: "30%" }}>
              <DropDownManuCustom
                label="Gender"
                placeholder="Select option"
                divstyle={{ width: "100%", marginTop: 20 }}
                lebalstyle={{ color: "white" }}
                style={{ backgroundColor: "#D9D9D9" }}
                options={[
                  { name: "Male" },
                  { name: "Female" },
                  { name: "Other" },
                ]}
                name="gender"
                dropdownicon
                register={register}
              />

              {errors.gender && <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>{errors.gender.message}</p>}
            </div>
            <div style={{ width: "30%" }}>
              <Inputbox
                label="SSN(Last Four Digits)"
                type="text"
                placeholder="Enter SSN"
                name="ssn"
                register={register}
                style={{ backgroundColor: "#D9D9D9", height: 40 }}
                lebalstyle={{ color: "white" }}
              />
              {errors.ssn && (
                <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                  {" "}
                  {errors.ssn.message}
                </p>
              )}
            </div>
          </div>
          <div
            className="service_rating_txt"
            style={{ fontSize: 26, fontWeight: 500 }}
          >
            Contact Information:
          </div>
          <div className="input-row">
            <div style={{ width: "48%" }}>
              <Inputbox
                label="Mobile Number"
                type="text"
                placeholder="Enter Number"
                name="phone"
                onChange={handleChange}
                style={{ backgroundColor: "#D9D9D9", height: 40 }}
                lebalstyle={{ color: "white" }}
              />
              {errors.phone && (
                <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                  {" "}
                  {errors.phone.message}
                </p>
              )}
            </div>
            <div style={{ width: "48%" }}>
              <Inputbox
                label="Email"
                type="text"
                placeholder="Enter Email"
                name="email"
                register={register}
                style={{ backgroundColor: "#D9D9D9", height: 40 }}
                lebalstyle={{ color: "white" }}
              />
              {errors.email && (
                <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                  {" "}
                  {errors.email.message}
                </p>
              )}
            </div>
          </div>

          <div className="input-row">
            <div style={{ width: "48%" }}>
              <Autocomplete
                onLoad={autocompleteInstance => setAutocomplete(autocompleteInstance)}
                onPlaceChanged={handlePlaceSelect}
              >
                <Inputbox
                  label="Street"
                  type="text"
                  placeholder="Enter Address"
                  name="address.street"
                  register={register}
                  lebalstyle={{ color: "white" }}
                  style={{ backgroundColor: "#D9D9D9", height: 40 }}
                />
              </Autocomplete>
            </div>
            <div style={{ width: "48%" }}>
              <Inputbox
                label="City"
                type="text"
                placeholder="Enter City"
                name="address.city"
                register={register}
                style={{ backgroundColor: "#D9D9D9", height: 40 }}
                lebalstyle={{ color: "white" }}
              />
              {errors.address?.city && <span className="errorMessage">{errors.address.city.message}</span>}
            </div>
          </div>

          <div>

            <div className="input-row">
              <div style={{ width: "48%" }}>
                <Inputbox
                  label="State"
                  type="text"
                  placeholder="Enter State"
                  name="address.state"
                  register={register}
                  style={{ backgroundColor: "#D9D9D9", height: 40 }}
                  lebalstyle={{ color: "white" }}
                />
                {errors.address?.state && <span className="errorMessage">{errors.address?.state.message}</span>}
              </div>
              <div style={{ width: "48%" }}>
                <Inputbox
                  label="Country"
                  type="text"
                  placeholder="Enter Country"
                  name="address.country"
                  register={register}
                  style={{ backgroundColor: "#D9D9D9", height: 40 }}
                  lebalstyle={{ color: "white" }}
                />
                {errors.address?.country && <span className="errorMessage">{errors.address?.country.message}</span>}
              </div>
            </div>

            <div className="input-row">
              <div style={{ width: "48%" }}>
                <Inputbox
                  label="Zip Code"
                  type="text"
                  placeholder="Enter Zip Code"
                  name="address.zipCode"
                  register={register}
                  style={{ backgroundColor: "#D9D9D9", height: 40 }}
                  lebalstyle={{ color: "white" }}
                />
                {errors.address?.zipCode && <span className="errorMessage">{errors.address.zipCode.message}</span>}
              </div>
              <div style={{ width: "48%" }}>
                <DropDownManuCustom
                  label="Assigned Clinician"
                  placeholder="Select option"
                  divstyle={{ width: "100%", marginTop: 20 }}
                  lebalstyle={{ color: "white" }}
                  style={{ backgroundColor: "#D9D9D9" }}
                  options={clinicdata.map((clinician) => ({
                    name: `${clinician.firstName} ${clinician.lastName}`,
                    ...clinician

                  }))}
                  name="agencyId"
                  dropdownicon
                  register={register}
                  id="_id"
                  defaultValue={profiledata?._id}
                  clearErrors={clearErrors}
                  onSelect={(selectedOption) => {
                    FetchDatalist(selectedOption?._id);
                    FetchPaymentSource(selectedOption?._id);
                    InsuranceType(selectedOption?._id)
                  }}
                />
              </div>
            </div>

            <div >

              <div className="Account_txttttt" style={{ textAlign: "left" }}>
                Service Type(s)
              </div>
              <div className="input-row">
                <DropDownManuCustomMulti
                  label="Select Service(s)"
                  placeholder="Select option"
                  options={servicedata}
                  name="TreatmentTypesId"
                  register={register}
                  style={{ backgroundColor: "#D9D9D9", height: 40 }}
                  divstyle={{ width: "100%", marginTop: 20 }}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={Selectservice}
                />
              </div>
              {errors.TreatmentTypesId && (
                <div className="errorMessage">{errors.TreatmentTypesId.message}</div>
              )}
            </div>


<div>

<div className="Account_txttttt" style={{ textAlign: "left" }}>
            Payment Type(s)
          </div>
          <div className="input-row">
            <DropDownManuCustom
              label="Payment Source"
              placeholder="Select option"
              options={paymentSourcedata}
              name="PaymentSourcesId"
              register={register}
              clearErrors={clearErrors}
              onSelect={handlePaymentSourceChange}
              dropdownicon
              id="_id"
            />
          </div>
          {errors.PaymentSourcesId && (
            <div className="errorMessage">
              {errors.PaymentSourcesId.message}
            </div>
          )}
          {selectedPaymentSource === "Insurance" && (
            <>
              <div className="Account_txttttt" style={{ textAlign: "left" }}>
                Insurance Details
              </div>
              <div className="form_div" style={{ marginTop: 10 }}>
                {/* <DropDownManuCustomMulti
              label="Insurance Name"
              placeholder="Select option"
              options={Insurance}
              name="insuranceId"
              register={register}
              id="_id"
              clearErrors={clearErrors}
              onSelect={selectInsurance}
            /> */}
                <DropDownManuCustom
                  label="Insurance Name"
                  placeholder="Select option"
                  options={Insurance}
                  name="insuranceId"
                  register={register}
                  clearErrors={clearErrors}
                  onSelect={selectInsurance}
                  dropdownicon
                  id="_id"
                />
                {errors.insuranceId && (
                  <div className="errorMessage">
                    {errors.insuranceId.message}
                  </div>
                )}
              </div>
              <div className="input-row">
                <Inputbox
                  label="Policy/Member Number"
                  type="text"
                  placeholder="Enter Policy/Member Number"
                  name="policyNo"
                  register={register}
                />
              </div>
              {errors.policyNo && (
                <div className="errorMessage">{errors.policyNo.message}</div>
              )}

              <div className="input-row">
                <Inputbox
                  label="Group Number"
                  type="text"
                  placeholder="Enter Group Number"
                  name="groupNumber"
                  register={register}
                />
              </div>
              {errors.groupNumber && (
                <div className="errorMessage">{errors.groupNumber.message}</div>
              )}

              <div className="input-row">
                <Inputbox
                  label="Subscriber Name"
                  type="text"
                  placeholder="Enter Subscriber Name"
                  name="subscriptionName"
                  register={register}
                />
              </div>
              {errors.subscriptionName && (
                <div className="errorMessage">
                  {errors.subscriptionName.message}
                </div>
              )}
            </>
          )}
</div>

          </div>
          <div className="review-popup-buttons1" style={{ marginTop: 50 }}>
            <button
              className="review-cancel-btn1"
              style={{ height: 40 }}
              onClick={onClose}
            >
              Cancel
            </button>
            <button className="review-save-btn1" style={{ height: 40 }}>
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddPatientpopupagency;
