import React, { useEffect, useRef, useState } from "react";
import Inputbox from "../../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Patientrequestupdates, uploadImage } from '../../../../api/helper';
import { ShowToast } from '../../../../helpers/ToastService';

const validationSchema = Yup.object().shape({
  TypeofID: Yup.string().required("Type of ID is required"),
  IdentificationNumber: Yup.string()
    .required("Identification Number is required"),
  ExpirationDate: Yup.string().required("Expiration Date of ID is required"),
});

const ClientIdentification = ({ info, getDteails }) => {
  const [image, setImages] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);

  const clickImageData = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const imagePicker = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        setImages(response.data.data[0].URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error, "===");
      }
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (info) {
      setValue("ExpirationDate", info?.Identification?.ExpirationDate || "");
      setValue("TypeofID", info?.Identification?.TypeofID || "");
      setValue("IdentificationNumber", info?.Identification?.IdentificationNumber || "");
      setImages(info?.Identification?.UploadCopy|| null)
    }
  }, [info, setValue]);
console.log(image,"hello==-=-=")
  const onSubmit = async (data) => {
    if (!image ) {
      ShowToast("Please upload image");
      return;
    }
    data.id = info?._id;
    const formattedData = {
      Identification: {
        IdentificationNumber: data.IdentificationNumber,
        ExpirationDate: data.ExpirationDate,
        TypeofID: data.TypeofID,
        UploadCopy :image,
        // Add file upload handling logic here if needed
      },
      id: data.id,
    };
    console.log(formattedData,"vformattedData====")
    // return
    try {
      const response = await Patientrequestupdates(formattedData);
      ShowToast(response?.data?.message);
      getDteails();
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <h2 className="notifications__title">Identification</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-row">
          <div style={{ width: '100%' }}>
            <Inputbox
              label="Identification Number"
              type="text"
              placeholder="Enter Id Name"
              name="IdentificationNumber"
              register={register}
            />
            {errors.IdentificationNumber && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {errors.IdentificationNumber.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: '100%' }}>
            <Inputbox
              label="Type of ID"
              type="text"
              placeholder="Enter Type of ID"
              name="TypeofID"
              register={register}
            />
            {errors.TypeofID && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {errors.TypeofID.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: '100%' }}>
            <Inputbox
              label="Expiration Date"
              type="date"
              placeholder=""
              name="ExpirationDate"
              register={register}
            />
            {errors.ExpirationDate && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {errors.ExpirationDate.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />

          <div style={{ width: "100%", marginTop: 18 }}>
      <label>{"Upload a Copy"}</label>
      <div
        style={{
          border: "1px solid grey",
          width: "100%",
          height: 49,
          borderRadius: 5,
          paddingLeft: 10,
          alignItems: "center",
          display: "flex",
        }}
      >
        {/* Button to trigger file input */}
        <button
         onClick={(e) => {
          e.preventDefault(); // Prevent form submission on button click
          clickImageData();
        }}
          style={{
            border: "1px solid lightgrey",
            borderRadius: 7,
            color: "black",
            fontSize: 14,
            paddingLeft: 5,
            paddingRight: 5,
            cursor: "pointer",
          }}
        >
          Choose File
        </button>

        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={imagePicker}
          style={{ display: "none" }} 
        />

        <div style={{ marginLeft: 5 }}>
          {image ? "Image Uploaded" : "No file chosen"}
        </div>
      </div>

     
    </div>
        </div>
        <div className="client-history__buttons">
          <button className="client-history__button client-history__button--cancel">
            Cancel
          </button>
          <button className="client-history__button client-history__button--save" type="submit">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientIdentification;
