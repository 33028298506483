import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { PatientUpdate } from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";

function PatinetNotification({ info }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      sms: '',
      emailNotifications: false,
      smsNotifications: false,
      therapySessionFollowUp: false,
      mentalHealthCheckIn: false,
      selfCareReminders: false,
      prescriptionRefillReminder: false,
      labTestResultsAvailable: false,
      billingPaymentReminder: false,
      lifestyleGoalReminders: false,
      crisisInterventionNotification: true,
      groupTherapyReminders: false,
      motivationalMessages: false,
      appointmentPreparationReminder: false,
      medicationAdherenceReminder: true,
      behavioralHealthProgressUpdate: false,
      healthyEatingTips: false,
      triggerManagementReminders: false,
      documentUploadReminder: false,
      carePlanUpdateNotification: false,
      testResultReminder: false,
      therapyAppointmentReminder: false,
      medicationRefillReminder: false,
      nutritionConsultationReminder: false,
      emergencyContactReminder: false,
      fitnessTrackingReminder: false,
      habitBuildingReminder: false,
    },
  });
  const [notificationStates, setNotificationStates] = useState({});

  const handleToggle = (name) => {
    setNotificationStates((prevState) => {
      const newState = { ...prevState, [name]: !prevState[name] };
      setValue(name, newState[name]);
      return newState;
    });
  };


  const onSubmit = async (data) => {
    const neWData = {
      ...data,
      id: info?._id
    }
    const response = await PatientUpdate(neWData)
    ShowToast(response?.data?.message);

  };

  return (
    <div className="notifications">
      <h2 className="notifications__title">Notifications</h2>

      <div className="notifications__section">
        <h3 className="notifications__subtitle">
          Which notification(s) should your patient receive?
        </h3>
        <div className="notifications__options_main">
          {[
            { label: "Therapy Session Follow-Up", name: "therapySessionFollowUp" },
            { label: "Mental Health Check-In", name: "mentalHealthCheckIn" },
            { label: "Self-Care Reminders", name: "selfCareReminders" },
            { label: "Prescription Refill Reminder", name: "prescriptionRefillReminder" },
            { label: "Lab/Test Results Available", name: "labTestResultsAvailable" },
            { label: "Billing/Payment Reminder", name: "billingPaymentReminder" },
            { label: "Lifestyle Goal Reminders", name: "lifestyleGoalReminders" },
            { label: "Crisis Intervention Notification", name: "crisisInterventionNotification" },
            { label: "Group Therapy Reminders", name: "groupTherapyReminders" },
            { label: "Motivational Messages", name: "motivationalMessages" },
            { label: "Appointment Preparation Reminder", name: "appointmentPreparationReminder" },
            { label: "Medication Adherence Reminder", name: "medicationAdherenceReminder" },
            { label: "Behavioral Health Progress Update", name: "behavioralHealthProgressUpdate" },
            { label: "Healthy Eating Tips", name: "healthyEatingTips" },
            { label: "Trigger Management Reminders", name: "triggerManagementReminders" },
            { label: "Document Upload Reminder", name: "documentUploadReminder" },
            { label: "Care Plan Update Notification", name: "carePlanUpdateNotification" },
            { label: "Test Result Reminder", name: "testResultReminder" },
            { label: "Therapy Appointment Reminder", name: "therapyAppointmentReminder" },
            { label: "Medication Refill Reminder", name: "medicationRefillReminder" },
            { label: "Nutrition Consultation Reminder", name: "nutritionConsultationReminder" },
            { label: "Emergency Contact Reminder", name: "emergencyContactReminder" },
            { label: "Fitness Tracking Reminder", name: "fitnessTrackingReminder" },
            { label: "Habit Building Reminder", name: "habitBuildingReminder" },
          ].map((notification) => (
            <div key={notification.name} className="notifications__option" style={{width:'23%'}}>
              <label style={{ textAlign: "left", width: "100%" }}>
                {notification.label}
              </label>
              <div className="notifications__toggle" style={{alignSelf:'flex-start'}}>
                <button
                  className={`notifications__toggle-btn ${notificationStates[notification.name] ? 'notifications__toggle-btn--on' : ''} ${notificationStates[notification.name] ? 'active' : ''}`}
                  type="button"
                  {...register(notification.name)}
                  onClick={() => handleToggle(notification.name)} // Toggle the state when clicked
                >
                  ON
                </button>
                <button
                  className={`notifications__toggle-btn ${!notificationStates[notification.name] ? 'notifications__toggle-btn--off' : ''} ${!notificationStates[notification.name] ? 'active' : ''}`}
                  type="button"
                  {...register(notification.name)}
                  onClick={() => handleToggle(notification.name)} // Toggle the state when clicked
                >
                  OFF
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="client-history__buttons">
        <button className="client-history__button client-history__button--cancel">
          Cancel
        </button>
        <button
          className="client-history__button client-history__button--save"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default PatinetNotification;
