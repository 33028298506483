import React, { useEffect, useState } from "react";
import PatientSidebar from "./PatientSidebar";
import { Navbartwo, Navtopbar } from "../../Components/Navbar";
import Inputbox from "../../Components/Inputbox";
import { useNavigate } from "react-router-dom";
import Checkbox from "./Checkbox";
import CalenderComponent from "./CalenderComponent";
import { format, addDays, subDays, addWeeks, subWeeks } from "date-fns";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import moment from "moment/moment";
const validationSchema = Yup.object().shape({
  patientName: Yup.string()
    .required("Patient Name is required")
    .min(2, "Patient Name must be at least 2 characters"),
  contactNumber: Yup.string()
    .required("Phone is required")
    .matches(/^[0-9]+$/, "Phone number must be digits")
    .min(10, "Contact Number must be at least 10 digits")
    .max(15, "Contact Number cannot exceed 15 digits"),
  visitingPurpose: Yup.string().required("Visiting Purpose is required"),
});

const Appointmentspatients = () => {
  const [view, setView] = useState("list");
  const handleViewChange = (newView) => {
    setView(newView);
  };

const navigate = useNavigate()

  return (
    <div className="dashboard-container">
      <PatientSidebar />
      <div className="dashboard-content">
        <Navbartwo      onClick= {()=>{navigate("/PatientProfile")}}/>
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30 }}
        >
          <div className="container" style={{ width: "100%" }}>
            <p className="Appointments_txt_Appointspatients">Appointments</p>
            <div className="tab-buttonstwo">
              <button
                className={`tab-buttontwo ${view === "list" ? "active" : ""}`}
                onClick={() => handleViewChange("list")}
              >
                Appointment List
              </button>
              <button
                className={`tab-buttontwo ${
                  view === "calendar" ? "active" : ""
                }`}
                onClick={() => handleViewChange("calendar")}
              >
                Calendar View
              </button>
            </div>
            <div style={{ width: "100%" }}>
              {view === "list" ? <AppointmentListView /> : <CalendarView />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointmentspatients;

const AppointmentListView = () => {
  return (
    <div>
      {/* <h2 style={{textAlign:'left'}}>List View of Appointments</h2> */}
      <table>
        <thead>
          <tr className="patient_font_size">
            <th className="Patient_thead_center">Clinic Name</th>
            <th className="Patient_thead_center">Email</th>
            <th className="Patient_thead_center">Phone Number</th>
            <th className="Patient_thead_center">Timing</th>
            <th className="Patient_thead_center">Date</th>
            <th className="Patient_thead_center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="Patient_thead_center_td">
              Birmingham Dental Clinic
            </td>
            <td className="Patient_thead_center_td">clinic@gmail.com</td>
            <td className="Patient_thead_center_td">9876543210</td>
            <td className="Patient_thead_center_td">16-08-2024</td>
            <td className="Patient_thead_center_td">Insurance</td>
            <td className="Patient_thead_center_td">
              <button className="actions-button">View</button>
            </td>
          </tr>
          <tr>
            <td className="Patient_thead_center_td">
              Birmingham Dental Clinic
            </td>
            <td className="Patient_thead_center_td">clinic@gmail.com</td>
            <td className="Patient_thead_center_td">9876543210</td>
            <td className="Patient_thead_center_td">99214</td>
            <td className="Patient_thead_center_td">Insurance</td>
            <td className="Patient_thead_center_td">
              <button className="actions-button">View</button>
            </td>
          </tr>
          <tr>
            <td className="Patient_thead_center_td">
              Birmingham Dental Clinic
            </td>
            <td className="Patient_thead_center_td">clinic@gmail.com</td>
            <td className="Patient_thead_center_td">9876543210</td>
            <td className="Patient_thead_center_td">16-08-2024</td>
            <td className="Patient_thead_center_td">Insurance</td>
            <td className="Patient_thead_center_td">
              <button className="actions-button">View</button>
            </td>
          </tr>
          <tr>
            <td className="Patient_thead_center_td">
              Birmingham Dental Clinic
            </td>
            <td className="Patient_thead_center_td">clinic@gmail.com</td>
            <td className="Patient_thead_center_td">9876543210</td>
            <td className="Patient_thead_center_td">99214</td>
            <td className="Patient_thead_center_td">Insurance</td>
            <td className="Patient_thead_center_td">
              <button className="actions-button">View</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const CalendarView = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema), 
  });

  const onSubmit = (data) => {
    console.log(data,"data====="); 
  };
  const handlenotification = () => {
    setActiveTab("paymentmethod");
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };
  const toggleChangePassword = () => {
    setActiveTab("workingHours");
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };

  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate("/InsurancePatient");
  };

  const [activeTab, setActiveTab] = useState("workingHours");
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);

  const timeSlots = [
    { starttime: "10:00", endTime: "10:30" },
    { starttime: "10:30", endTime: "11:00" },
    { starttime: "11:00", endTime: "11:30" },
    { starttime: "11:30", endTime: "12:00" },
    { starttime: "12:00", endTime: "12:30" },
    { starttime: "12:30", endTime: "01:00" },
    { starttime: "01:30", endTime: "02:00" },
    { starttime: "02:00", endTime: "02:30" },
    { starttime: "02:30", endTime: "03:00" },
    { starttime: "03:00", endTime: "03:30" },
    { starttime: "03:30", endTime: "04:00" },
    { starttime: "04:00", endTime: "04:30" },
    { starttime: "04:30", endTime: "05:00" },
    { starttime: "05:00", endTime: "05:30" },
    { starttime: "05:30", endTime: "06:00" },
  ];

  const [selectedSlot, setSelectedSlot] = useState(null);

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot.starttime); // Save starttime as unique identifier
    console.log("Start Time:", slot.starttime, "End Time:", slot.endTime);
  };

  const handlePrevWeek = () => {
    setCurrentDate(subWeeks(currentDate, 1));
  };

  const handleNextWeek = () => {
    setCurrentDate(addWeeks(currentDate, 1));
  };

  const  bookingSlot = async()=>{
    try {
      const dateFormate = moment().format("yyyy-mm-dd")
      console.log(dateFormate,'===dateFormate') 
      // const response = await getAvailableTimeSlots()
    } catch (error) {
      console.log(error,'====>>getAvailableTimeSlots')
    }
  }

  useEffect(()=>{
    bookingSlot()
  },[])

  const handleDateClick = (date) => {
    console.log(date, "helllo-==-=====");
    setSelectedDate(date);
  };
  const startOfWeek = subDays(currentDate, currentDate.getDay() - 1);
  const endOfWeek = addDays(startOfWeek, 6);
  const days = [];
  for (let date = startOfWeek; date <= endOfWeek; date = addDays(date, 1)) {
    days.push(new Date(date));
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="schedule-app" style={{ width: "68%" }}>
          <p className="Booking_Details_txt">Booking Details</p>
          <header className="schedule-header">
            <h1 className="schedule-title">
              {format(currentDate, "MMMM yyyy")}
            </h1>
            <div className="schedule-controls">
              <button className="schedule-button_two">New Payment</button>
            </div>
          </header>
          <div className="schedule-main-content">
            <div className="schedule-left-panel">
              <div className="schedule-date-picker">
                <div className="schedule-week-days">
                  <div onClick={handlePrevWeek} style={{ marginRight: 10 }}>
                    <img src={require("../../assest/Images/LeftArrow.png")} />
                  </div>

                  <div
                    className="schedule-week-days"
                    style={{ justifyContent: "space-between" }}
                  >
                    {days.map((date) => (
                      <div
                        key={date.getTime()}
                        className={`schedule-day ${
                          date.toDateString() === selectedDate.toDateString()
                            ? "schedule-day-selected"
                            : ""
                        }`}
                        onClick={() => handleDateClick(date)}
                      >
                        <div
                          style={{
                            color:
                              date.toDateString() ===
                              selectedDate.toDateString()
                                ? "white"
                                : "#697585",
                          }}
                        >
                          {format(date, "d")}
                        </div>
                        <div
                          style={{
                            color:
                              date.toDateString() ===
                              selectedDate.toDateString()
                                ? "white"
                                : "#697585",
                          }}
                        >
                          {format(date, "EEE")}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div onClick={handleNextWeek} style={{ marginLeft: 10 }}>
                    <img src={require("../../assest/Images/rightarrow.png")} />
                  </div>
                </div>
              </div>

              <h1
                className="schedule-title"
                style={{ textAlign: "left", paddingBottom: 20 }}
              >
                Slot Booking
              </h1>
              <div>
                {activeTab === "workingHours" && (
                  <div className="grid-container">
                    {timeSlots.map((slot) => (
                      <div
                        key={slot.starttime}
                        className={
                          selectedSlot === slot.starttime // Compare using starttime
                            ? "time-slot_two selected"
                            : "time-slot_two"
                        }
                        onClick={() => handleSlotClick(slot)} // Handle click event
                      >
                        {slot.starttime} - {slot.endTime}
                      </div>
                    ))}
                  </div>
                )}
                {activeTab === "notifications" && (
                  <div>
                    {isChangePasswordVisible && (
                      <div
                        style={{
                          position: "fixed",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          zIndex: 1000,
                        }}
                      ></div>
                    )}
                  </div>
                )}
              </div>
              <h1
                className="schedule-title"
                style={{ textAlign: "left", paddingTop: 20, paddingBottom: 20 }}
              >
                Appointment For
              </h1>

              <div style={{ width: "50%" }}>
                <Inputbox
                  label="Patient Name"
                  lebalstyle={{ color: "#011434" }}
                  style={{
                    color: "#011434",
                    borderColor: "#011434",
                    background: "#D9D9D94D",
                  }}
                  type="text"
                  placeholder="Enter Patient Name"
                  name="patientName"
                  register={register} // Pass the register function
                />
                {errors.patientName && (
                  <span className="error-message">
                    {errors.patientName.message}
                  </span>
                )}

                <Inputbox
                  label="Contact Number"
                  lebalstyle={{ color: "#011434" }}
                  style={{
                    color: "#011434",
                    borderColor: "#011434",
                    background: "#D9D9D94D",
                  }}
                  type="text"
                  placeholder="Enter Number"
                  name="contactNumber"
                  register={register} // Pass the register function
                />
                {errors.contactNumber && (
                  <span className="error-message">
                    {errors.contactNumber.message}
                  </span>
                )}

                <Inputbox
                  label="Visiting Purpose"
                  lebalstyle={{ color: "#011434" }}
                  style={{
                    color: "#011434",
                    borderColor: "#011434",
                    background: "#D9D9D94D",
                    height: 104,
                  }}
                  type="text"
                  placeholder="Visiting Purpose"
                  name="visitingPurpose"
                  register={register} // Pass the register function
                />
                {errors.visitingPurpose && (
                  <span className="error-message">
                    {errors.visitingPurpose.message}
                  </span>
                )}
              </div>
            </div>
            {/* <div></div> */}
          </div>
        </div>

        <div
          className="schedule-right-panel"
          style={{ width: "30%", paddingLeft: 40, paddingRight: 15 }}
        >
          <CalenderComponent />

          <p style={{ paddingTop: 50 }} className="Booking_txt">
            Booking Detail
          </p>
          <p className="Date_text">
            Date : July 16, 2024 Thursday Time : 01:00 to 1:30 PM
          </p>
          <p className="Date_text" style={{ color: "#011434", paddingTop: 10 }}>
            Booking Deposit Fee
          </p>
          <div className="display_txt_booking">
            <p className="Booking_Fee_txt" style={{ color: "gray" }}>
              Total
            </p>
            <p className="Booking_10_txt">£ 10.00</p>
          </div>
          <div className="display_txt_booking">
            <p className="Booking_Fee_txt" style={{ color: "gray" }}>
              Booking Fee
            </p>
            <p className="Booking_10_txt">£ 2.00</p>
          </div>
          <div className="display_txt_booking" style={{ paddingTop: 30 }}>
            <p className="Booking_10_txt" style={{ color: "gray" }}>
              Grand Total
            </p>
            <p className="Booking_10_txt">£ 12.00</p>
          </div>
          <p className="Date_text" style={{ color: "#011434", paddingTop: 40 }}>
            Description
          </p>
          <p className="the_booking_txt" style={{ color: "011434" }}>
            {" "}
            "The booking deposit fee, secures your appointment. Clinics will
            inform you of any further charges for assessment, and treatment
            before they are carried out. The booking deposit fee is still
            charged if you fail to attend an appointment. "
          </p>

          <button
            style={{ marginTop: 20 }}
            className={
              activeTab === "paymentmethod" ? "active-tab1" : "inactive-tab1"
            }
            // onClick={handlenotification}
            type="submit"
          >
            Book Appointment
          </button>
        </div>

        {activeTab === "paymentmethod" && (
          <div>
            {isChangePasswordVisible && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 1000,
                }}
              >
                <div
                  className="HelpSupport_div"
                  style={{
                    maxWidth: "40%",
                    paddingLeft: 50,
                    paddingRight: 50,
                  }}
                >
                  <div className="Contact_Us_txt">Payment Method</div>

                  <div
                    className="div_Payment_Method"
                    style={{ backgroundColor: "#D9D9D9" }}
                  >
                    <img
                      className="image_life-insurance"
                      src={require("../../assest/Images/life-insurance.png")}
                    />
                    <div className="div_text_payment_method">
                      <p className="text_Insurance_payment">Insurance</p>
                      <p className="text_Bill_payment">
                        Bill the client's insurance plan
                      </p>
                    </div>
                    <Checkbox />
                  </div>

                  <div
                    className="div_Payment_Method"
                    style={{ backgroundColor: "#D9D9D9" }}
                  >
                    <img
                      src={require("../../assest/Images/Payment-card.png")}
                    />
                    {/* <img src={require('../../assest/Images/subscription.png')}/> */}
                    <div className="div_text_payment_method">
                      <p className="text_Insurance_payment">Insurance</p>
                      <p className="text_Bill_payment">
                        Bill the client's insurance plan
                      </p>
                    </div>
                    <Checkbox />
                  </div>

                  <div
                    className="div_Payment_Method"
                    style={{ backgroundColor: "#D9D9D9" }}
                  >
                    <img
                      src={require("../../assest/Images/subscription.png")}
                    />
                    <div className="div_text_payment_method">
                      <p className="text_Insurance_payment">Insurance</p>
                      <p className="text_Bill_payment">
                        Bill the client's insurance plan
                      </p>
                    </div>
                    <Checkbox />
                  </div>

                  {/* <div   style={{ alignSelf: 'center', display: 'flex',width:'100%',justifyContent:'center',marginTop:10 }}>
              <div className="savebuttonn1" onClick={toggleChangePassword}>Submit</div>
            </div> */}

                  <div
                    style={{
                      alignSelf: "center",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <div className="savebuttonn1" onClick={handleSignUp}>
                      Submit
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </form>
  );
};
