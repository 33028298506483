import React from "react";

const Notification = () => {
  return (
    <div className="notifications">
      <h2 className="notifications__title">Notifications</h2>

      <div className="notifications__section">
        <h3 className="notifications__subtitle">
          Where should your patient receive notifications?
        </h3>
        <div className="notifications__row">
          <div className="notifications__input-group">
            <label className="notifications__label">Email</label>
            <input
              type="email"
              className="notifications__input"
              value="michelevens@gmail.com"
              readOnly
            />
            <div className="notifications__toggle">
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>

          <div className="notifications__input-group">
            <label className="notifications__label">Text Message</label>
            <input
              type="text"
              className="notifications__input"
              value="(407) 462-7233"
              readOnly
            />
            <div className="notifications__toggle">
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
        </div>
        <p className="notifications__consent-text">
          By adding an email/phone number, you are asserting patient consent to
          communicate through those channels.
        </p>
      </div>

      <div className="notifications__section">
        <h3 className="notifications__subtitle">
          Which notification(s) should your patient receive?
        </h3>
        <div className="notifications__options_main">
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}> Therapy Session Follow-Up </label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Mental Health Check-In</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Self-Care Reminders</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Prescription Refill Reminder</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
        </div>
        <div className="notifications__options_main">
          <div className="notifications__option">
          
            <label style={{textAlign:'left',width:'100%'}}>Lab/Test Results Available</label>
           
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Billing and Payment Reminder</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Lifestyle Goal Reminders</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Crisis Intervention Notification</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
        </div>
        <div className="notifications__options_main">
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Group Therapy or Wellness Program Reminders</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Motivational Messages</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Appointment Preparation Reminder</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Medication Adherence Reminder</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
        </div>
        <div className="notifications__options_main">
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Behavioral Health Progress Update</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Healthy Eating or Sleep Tips</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Trigger Management Reminders</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Document Upload Reminder</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
        </div>
        <div className="notifications__options_main">
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Care Plan Update Notification</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Follow-Up Reminder</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Appointment Feedback Request</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Missed Appointment Follow-Up</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
        </div>
        <div className="notifications__options_main">
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Stress Management Techniques</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Meditation and Breathing Exercise Alerts</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Positive Reinforcement Notifications</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
          <div className="notifications__option">
            <label style={{textAlign:'left',width:'100%'}}>Mindfulness Practice Alert</label>
            <div
              className="notifications__toggle"
              style={{ alignSelf: "flex-start" }}
            >
              <button className="notifications__toggle-btn">ON</button>
              <button className="notifications__toggle-btn notifications__toggle-btn--off">
                OFF
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="client-history__buttons">
        <button className="client-history__button client-history__button--cancel">
          Cancel
        </button>
        <button className="client-history__button client-history__button--save">
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default Notification;
