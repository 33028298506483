import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Inputbox from "../../Components/Inputbox";
import {
  DropDownManuCustomMulti,
} from "../../Components/DropDownButton";
import {
  AddVisitReason,
  DeleteVisitReason,
  EditVisitReason,
  FetchVisitReason,
} from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";
const cptCodes = [
  {
    _id: "99201  -  Office or other outpatient visit for a new patient, evaluation and management",
    name: "99201  -  Office or other outpatient visit for a new patient, evaluation and management ",
    description:
      "Office or other outpatient visit for a new patient, evaluation and management",
  },
  {
    _id: "99202  -  Office or other outpatient visit for a new patient, typically 15-29 minutes",
    name: "99202  -  Office or other outpatient visit for a new patient, typically 15-29 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 15-29 minutes",
  },
  {
    _id: "99203  -  Office or other outpatient visit for a new patient, typically 30-44 minutes",
    name: "99203  -  Office or other outpatient visit for a new patient, typically 30-44 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 30-44 minutes",
  },
  {
    _id: "99204  -  Office or other outpatient visit for a new patient, typically 45-59 minutes",
    name: "99204  -  Office or other outpatient visit for a new patient, typically 45-59 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 45-59 minutes",
  },
  {
    _id: "90834  -  Psychotherapy, 45 minutes with patient",
    name: "90834  -  Psychotherapy, 45 minutes with patient",
    description: "Psychotherapy, 45 minutes with patient",
  },
  {
    _id: "90837  -  Psychotherapy, 60 minutes with patient",
    name: "90837  -  Psychotherapy, 60 minutes with patient",
    description: "Psychotherapy, 60 minutes with patient",
  },
];
const validationSchema = Yup.object().shape({
  visitReason: Yup.string().required("Visit Reason is required"),
  cptCode: Yup.array()
    .of(Yup.string().required("A CPT Code must be selected"))
    .min(1, "At least one CPT Code is required")
    .required("CPT Code is required"),
  appointmentDuration: Yup.string().required(
    "Appointment Duration is required"
  ),
});
const VisitReason = () => {
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEditData, setCurrentEditData] = useState(null);
  const [data, setData] = useState([]);
  const profileData = useSelector((state) => state?.cookies?.userDetails);
  const [modifiers, setModifiers] = useState(["", "", "", ""]);

  const openModal = () => {
    setShowModal(true);
    setIsEditing(false); // Default to add mode
    reset(); // Clear form data
    setModifiers(["", "", "", ""]);
  };

  const closeModal = () => setShowModal(false);

  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const GetVisitReason = async () => {
    const response = await FetchVisitReason(`?id=${profileData?._id}`);
    setData(response?.data?.data);
  };

  useEffect(() => {
    GetVisitReason();
  }, []);

  const handleModifierChange = (index, value) => {
    const updatedModifiers = [...modifiers];
    updatedModifiers[index] = value.slice(0, 2);
    setModifiers(updatedModifiers);
    setValue("modifiers", updatedModifiers, { shouldValidate: true });
  };

  const onSubmit = async (formData) => {
    formData.cptCode = formData.cptCode.join(",");
    formData.agencyId = profileData?._id;
  
    try {
      if (isEditing) {
console.log(currentEditData,"currentEditData._id===")
        const response = await EditVisitReason(formData, currentEditData._id); 
        ShowToast(response?.data?.message);
      } else {
        // Add case
        const response = await AddVisitReason(formData);
        ShowToast(response?.data?.message);
      }
      reset();
      setModifiers(["", "", "", ""]);
      GetVisitReason();
      setShowModal(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  
  const handleEdit = (row) => {
    setIsEditing(true); 
    setCurrentEditData(row); 
    setShowModal(true); 

    const data = cptCodes.find((e)=>e._id==row?.cptCode)

    setValue("visitReason", row.visitReason);
    setValue("cptCode", data?._id);
    setValue("appointmentDuration", row.appointmentDuration);
    setModifiers(row.modifiers.length ? row.modifiers : ["", "", "", ""]);
  };
  

  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to remove this reason?");
    if (!confirmed) return;

    try {
      const response = await DeleteVisitReason(`?id=${id}`);
      if (response?.data?.success) {
        ShowToast(response?.data?.message);
        GetVisitReason();
      } else {
        ShowToast("Error: Unable to delete address.");
      }
    } catch (error) {
      console.error("Delete operation failed:", error);
      ShowToast("An error occurred while deleting the address.");
    }
  };

  const handleLanguage = (selectedOptions) => {
    const ids = selectedOptions?.map((e) => e?._id);
    setValue("cptCode", ids, { shouldValidate: true });
  };

  return (
    <div style={{ width: "100%", alignItems: "center", marginBottom: 20 }}>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <h1>Visit Reason, CPT Code, Appointment Duration, and Modifiers</h1>
        <div
          style={{
            backgroundColor: "#bc9c22",
            color: "white",
            padding: 10,
            borderRadius: 6,
            cursor: "pointer",
          }}
          onClick={openModal}
        >
          Add Visit Reason
        </div>
      </div>
      <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead style={{ backgroundColor: "yellow" }}>
            <tr>
              <th style={tableHeaderStyle}>Visit Reason</th>
              <th style={tableHeaderStyle}>CPT Code</th>
              <th style={tableHeaderStyle}>Appointment Duration</th>
              <th style={tableHeaderStyle}>Default Modifiers</th>
              <th style={tableHeaderStyle}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan="6" style={{ textAlign: "center", padding: "10px" }}>
                  No Reason Found
                </td>
              </tr>
            ) : (
              data.map((row) => (
                <tr key={row._id} style={tableRowStyle}>
                  <td style={tableCellStyle}>{row.visitReason}</td>
                  <td style={tableCellStyle}>{row.cptCode}</td>
                  <td style={tableCellStyle}>{row.appointmentDuration}</td>
                  <td style={tableCellStyle}>
                    {row.modifiers.length > 0 ? (
                      row.modifiers.map((modifier, index) => (
                        <div key={index}>
                          {`M${index + 1}: ${modifier || ""}`}
                        </div>
                      ))
                    ) : (
                      "No Modifiers"
                    )}
                  </td>
                  <td style={tableCellStyle}>
                    <button style={editButtonStyle} onClick={() => handleEdit(row)}>
                      Edit
                    </button>
                    <button style={deleteButtonStyle} onClick={() => handleDelete(row._id)}>
                      Remove
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Modal open={showModal} onClose={closeModal} aria-labelledby="modal-title">
        <Box sx={modalStyle}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1 style={{ textAlign: "left" }}>Visit Reasons and Codes</h1>
            <Inputbox
              label="Visit Reason"
              name="visitReason"
              register={register}
              placeholder="Enter Visit Reason"
            />
            <p style={{ color: "red" }}>{errors.visitReason?.message}</p>

            <DropDownManuCustomMulti
              label="CPT Code (Select Multiple)"
              options={cptCodes}
              name="cptCode"
              register={register}
              id="_id"
              clearErrors={clearErrors}
              onSelect={handleLanguage}
              // defaultValue={row&&row?.cptCode}
            />
            <p style={{ color: "red" }}>{errors.cptCode?.message}</p>

            <Inputbox
              label="Default Appointment Duration (minutes)"
              type="number"
              name="appointmentDuration"
              register={register}
              placeholder="Enter Duration in Minutes"
            />
            <p style={{ color: "red" }}>{errors.appointmentDuration?.message}</p>

            <div style={{ fontWeight: "bold", fontSize: 19 }}>Default Modifiers (Optional)</div>
            <div style={{ display: "flex", gap: "5%" }}>
              {modifiers.map((modifier, index) => (
                <Inputbox
                  key={index}
                  label={`Mod${index + 1}`}
                  placeholder="XX"
                  value={modifier}
                  onChange={(e) => handleModifierChange(index, e.target.value)}
                />
              ))}
            </div>

            <button type="submit" className="Save_buttonnn">
              {isEditing ? "Update" : "Save"}
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default VisitReason;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "78vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  outline: "none",
  overflowY: "auto",
};
const tableHeaderStyle = {
  padding: "10px",
  borderBottom: "2px solid #ddd",
  textAlign: "left",
  fontWeight: "bold",
  backgroundColor: "#f4f4f4",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};

const tableRowStyle = {
  borderBottom: "1px solid #ddd",
};
const editButtonStyle ={
  backgroundColor: "darkblue",
  marginBottom:3,
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
}
const deleteButtonStyle = {
  backgroundColor: "red",
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
