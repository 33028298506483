import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import Inputbox from '../../../Components/Inputbox';
import { PatientUpdate } from '../../../../api/helper';
import { ShowToast } from '../../../../helpers/ToastService';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  address: Yup.object().shape({
    // street: Yup.string().required('Street is required'),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    zipCode: Yup.string().required("Zip code is required"),
  }),
});
function PatientAddress({info,getprofile}) {
    const [autocomplete, setAutocomplete] = useState(null);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM",
        libraries: ["places"],
      });
console.log(info,"info=====")
    const handlePlaceSelect = async () => {
        const place = autocomplete.getPlace();
        const addressComponents = place.address_components;
    
        const getAddressComponent = (type) => {
          const component = addressComponents.find((c) => c.types.includes(type));
          return component ? component.long_name : "";
        };
    
        // Set country, state, and city
        setValue("address.country", getAddressComponent("country"));
        setValue(
          "address.state",
          getAddressComponent("administrative_area_level_1")
        );
        setValue("address.city", getAddressComponent("locality"));
    
        const streetNumber = getAddressComponent("street_number");
        const route = getAddressComponent("route");
        const sublocality = getAddressComponent("sublocality");
        const neighborhood = getAddressComponent("neighborhood");
        const premise = getAddressComponent("premise"); // Building name or room number
        const establishment = getAddressComponent("establishment"); // Business names
    
        let fullStreet = "";
    
        if (establishment) {
          fullStreet = establishment;
        } else if (premise) {
          fullStreet = `${premise}, ${route || sublocality || neighborhood}`;
        } else if (streetNumber && route) {
          fullStreet = `${streetNumber} ${route}`;
        } else if (sublocality) {
          fullStreet = sublocality;
        } else if (neighborhood) {
          fullStreet = neighborhood;
        }
    
        if (!fullStreet) {
          fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent(
            "administrative_area_level_1"
          )}, ${getAddressComponent("country")}`;
        }
    
        // Set the full street address in the form
        setValue("address.street", fullStreet);
    
        // Set the zip code
        setValue("address.zipCode", getAddressComponent("postal_code"));
      };
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
    });
    useEffect(() => {
      if (info) {
        setValue("address.street", info?.patientAddress.street ||  "");
        setValue("address.city", info?.patientAddress.city ||  "");
        setValue("address.state",  info?.patientAddress.state||"");
        setValue("address.zipCode",  info?.patientAddress.zipCode||  "");
      }
    }, [info, setValue]);
    const onSubmit = async (data) => {
      data.id = info?._id;
      const formattedData = {
        patientAddress: {
          street: data.address.street,
          city: data.address.city,
          state: data.address.state,
          country: data.address.country,
          zipCode: data.address.zipCode,
          // agar isme koi galti hai to vo meri galti nahi hai , me friday shyam ko aapse puch raha tha or aap 600 lines ki query me busy thy
        },
        id: data.id,
      };
      console.log(formattedData, "vformattedData====");
      // return
      try {
        const response = await PatientUpdate(formattedData);
        ShowToast(response?.data?.message);
console.log(response?.data?.data,"helloo-=====")
        getprofile()
      } catch (error) {
        // Handle error
      }
    }; 

    if (!isLoaded) return <div>Loading...</div>;

  return (
    <div style={{ padding: 20 }}>
    <h2 className="notifications__title">Addresses</h2>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-row">
        <div style={{ width: "100%" }}>
          <Autocomplete
            onLoad={(autocompleteInstance) =>
              setAutocomplete(autocompleteInstance)
            }
            onPlaceChanged={handlePlaceSelect}
          >
            <Inputbox
              label="Street"
              type="text"
              placeholder="Enter Street"
              name="address.street"
              register={register}
            />
          </Autocomplete>
        </div>
        <div style={{ width: "40px" }} />
        <div style={{ width: "100%" }}>
          <Inputbox
            label="City"
            type="text"
            placeholder="Enter City"
            name="address.city"
            register={register}
          />
          {errors.address?.city && (
            <div className="errorMessage">{errors.address.city.message}</div>
          )}
        </div>
        <div style={{ width: "40px" }} />
        <div style={{ width: "100%" }}>
          <Inputbox
            label="State"
            type="text"
            placeholder="Enter State"
            name="address.state"
            register={register}
          />
          {errors.address?.state && (
            <div className="errorMessage">{errors.address.state.message}</div>
          )}
        </div>
        <div style={{ width: "40px" }} />
        <div style={{ width: "100%" }}>
          <Inputbox
            label="ZIP Code"
            type="text"
            placeholder="Enter ZIP Code"
            name="address.zipCode"
            register={register}
          />
          {errors.address?.zipCode && (
            <div className="errorMessage">
              {errors.address.zipCode.message}
            </div>
          )}
        </div>
      </div>
      <button
        className="client-history__button client-history__button--save"
        style={{ alignSelf: "flex-start", display: "flex", marginTop: 5 }}
      >
        +Add More Addresses
      </button>
      <div className="client-history__buttons">
        <button className="client-history__button client-history__button--cancel">
          Cancel
        </button>
        <button className="client-history__button client-history__button--save">
          Save Changes
        </button>
      </div>
    </form>
  </div>
  )
}

export default PatientAddress