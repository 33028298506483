import React from 'react'
import logo from "../assest/Images/mindWellIcon.png";
import Successfull from "../assest/Images/successfull.png";
import { useNavigate } from 'react-router-dom';
// import Successfull from "./images/successfull.png";
function Newpasswordsuccess() {
  const navigate = useNavigate()
  return (
    <div>
    <div className="login custom_login--width">
      <div className="login_content">
        <div class="modal d-block" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered ">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={()=>{
                    navigate('/')
                  }}
                ></button>
              </div>
              <div class="modal-body text-center">
                {/* <img src={Successfull} alt="" /> */}
                <img src={logo} alt="" style={{height:200,}}/>
                <div className="mt-5">
                  <strong>New password created successfully.!</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Newpasswordsuccess