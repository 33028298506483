import React, { useState } from 'react'

function ClientInsurence() {

    const [activeTab, setActiveTab] = useState("insuranceDetails");

    const openTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            {/* Insurance Details Section with Tabs */}
            <div id="insurance-section"
            //  style={{ display: "none" }}
            >
                <h3>Insurance Details</h3>
                <div>
                    <div className="tabs">
                        <button
                            className={`tablinks ${activeTab === "insuranceDetails" ? "active" : ""}`}
                            onClick={() => openTab("insuranceDetails")}
                        >
                            Insurance Details
                        </button>
                        <button
                            className={`tablinks ${activeTab === "conditions" ? "active" : ""}`}
                            onClick={() => openTab("conditions")}
                        >
                            Conditions
                        </button>
                        <button
                            className={`tablinks ${activeTab === "authorizations" ? "active" : ""}`}
                            onClick={() => openTab("authorizations")}
                        >
                            Authorizations
                        </button>
                        <button
                            className={`tablinks ${activeTab === "otherInfo" ? "active" : ""}`}
                            onClick={() => openTab("otherInfo")}
                        >
                            Other Info
                        </button>
                        <button
                            className={`tablinks ${activeTab === "EligibilityChecks" ? "active" : ""}`}
                            onClick={() => openTab("EligibilityChecks")}
                        >
                            Eligibility Checks History
                        </button>
                    </div>

                    <div className="tabcontent" style={{ display: activeTab === "insuranceDetails" ? "block" : "none" }}>
                        {/* Content for Insurance Details */}

                        <div id="insuranceDetails" className="tabcontent">
                            {/* Insurance details content */}
                            <h3>
                                Insurance Details with Corresponding CMS 1500 Field Mapping/Reference
                            </h3>
                            <div className="insurance-grid-container">
                                <div className="insurance-grid">
                                    <div className="input-group">
                                        <label htmlFor="plan_type">Plan Type [Box 1]*</label>
                                        <select id="plan_type" name="plan_type">
                                            <option value="Medicare">Medicare</option>
                                            <option value="Medicaid">Medicaid</option>
                                            <option value="Tricare">Tricare</option>
                                            <option value="CHAMPVA">CHAMPVA</option>
                                            <option value="Group Health Plan">Group Health Plan</option>
                                            <option value="FECA Black Lung">FECA Black Lung</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="plan_name">Plan Name [Box 11c]*</label>
                                        <select id="plan_name" name="plan_name">
                                            <option value="Choice Fund OA Plus">Choice Fund OA Plus</option>
                                            <option value="PPO Blue Shield">PPO Blue Shield</option>
                                            <option value="Kaiser Permanente">Kaiser Permanente</option>
                                        </select>
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="policy_number">Policy Number [Box 1a]*</label>
                                        <input
                                            type="text"
                                            id="policy_number"
                                            name="policy_number"
                                            placeholder="U7541039301"
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="phone_number">Phone Number</label>
                                        <input
                                            type="text"
                                            id="phone_number"
                                            name="phone_number"
                                            placeholder="(800) 244-6224"
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="group_number">Group Number [Box 11]</label>
                                        <input
                                            type="text"
                                            id="group_number"
                                            name="group_number"
                                            placeholder={3333059}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="copay_amount">Copay Amount</label>
                                        <input
                                            type="text"
                                            id="copay_amount"
                                            name="copay_amount"
                                            placeholder="$0.00"
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="deductible_amount">Deductible Amount</label>
                                        <input
                                            type="text"
                                            id="deductible_amount"
                                            name="deductible_amount"
                                            placeholder="$0.00"
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="relationship">Patient's Relationship [Box 6]</label>
                                        <select id="relationship" name="relationship">
                                            <option value="self">Self</option>
                                            <option value="spouse">Spouse</option>
                                            <option value="child">Child</option>
                                            <option value="guardian">Guardian</option>
                                        </select>
                                    </div>
                                </div>
                                <div
                                    id="policy-holder-section"
                                    className="policy-holder-section"
                                    style={{ display: "none" }}
                                >
                                    <h3>Policy Holder Information</h3>
                                    <div className="insurance-grid">
                                        <div className="input-group">
                                            <label htmlFor="insured_name">Insured Name [Box 4]</label>
                                            <input
                                                type="text"
                                                id="insured_name"
                                                name="insured_name"
                                                placeholder="Full Name"
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="insured_id">Insured ID Number [Box 1a]</label>
                                            <input
                                                type="text"
                                                id="insured_id"
                                                name="insured_id"
                                                placeholder="ID Number"
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="address">Street Address [Box 7]</label>
                                            <input
                                                type="text"
                                                id="address"
                                                name="address"
                                                placeholder="123 Main St"
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="apt">Apt/Suite [Box 7]</label>
                                            <input type="text" id="apt" name="apt" placeholder="Apt 1B" />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="city">City [Box 7]</label>
                                            <input type="text" id="city" name="city" placeholder="City" />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="state">State [Box 7]</label>
                                            <select id="state" name="state">
                                                <option value="NY">New York</option>
                                                <option value="CA">California</option>
                                            </select>
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="zip">Zip Code [Box 7]</label>
                                            <input type="text" id="zip" name="zip" placeholder="Zip Code" />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="Telephone">Telephone Number [Box 7]</label>
                                            <input
                                                type="text"
                                                id="Telephone"
                                                name="Telephone"
                                                placeholder="Telephone"
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="ssn">SSN</label>
                                            <input
                                                type="text"
                                                id="ssn"
                                                name="ssn"
                                                placeholder="--- -- ----"
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="dob">DOB [Box 3]</label>
                                            <input type="date" id="dob" name="dob" />
                                        </div>
                                        <div className="input-group">
                                            <label htmlFor="gender">Gender [Box 3]</label>
                                            <select id="gender" name="gender">
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="undifferentiated">Undifferentiated</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group">
                                    <button id="check-eligibility-btn" className="button">
                                        Check Eligibility
                                    </button>
                                    <div id="eligibility-status" className="eligibility-status" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="tabcontent" style={{ display: activeTab === "conditions" ? "block" : "none" }}>
                        {/* Content for Conditions */}
                        <div id="conditions" className="tabcontent">
                            <h3>Conditions of Admission</h3>
                            <div className="radio-group">
                                <label htmlFor="condition">
                                    The patient condition is related to[Box 10a-c]:
                                </label>{" "}
                                <br />
                                <div className="radio-options">
                                    <label>
                                        <input
                                            type="radio"
                                            name="condition"
                                            defaultValue="none"
                                            defaultChecked=""
                                        />{" "}
                                        None
                                    </label>{" "}
                                    <br />
                                    <label>
                                        <input type="radio" name="condition" defaultValue="auto-accident" />{" "}
                                        Auto Accident
                                    </label>{" "}
                                    <br />
                                    <label>
                                        <input type="radio" name="condition" defaultValue="employment" />{" "}
                                        Employment
                                    </label>{" "}
                                    <br />
                                    <label>
                                        <input
                                            type="radio"
                                            name="condition"
                                            defaultValue="other-accident"
                                        />{" "}
                                        Other Accident
                                    </label>
                                    <br />
                                </div>
                            </div>
                            <div className="checkbox-group">
                                <label htmlFor="service">The service provided is related to:</label>{" "}
                                <br />
                                <div className="checkbox-options">
                                    <label>
                                        <input type="checkbox" name="service" defaultValue="pregnancy" />{" "}
                                        Pregnancy
                                    </label>{" "}
                                    <br />
                                    <label>
                                        <input type="checkbox" name="service" defaultValue="epsdt" /> EPSDT
                                    </label>{" "}
                                    <br />
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="service"
                                            defaultValue="family-planning"
                                        />{" "}
                                        Family Planning
                                    </label>{" "}
                                    <br />
                                    <label>
                                        <input type="checkbox" name="service" defaultValue="homebound" />{" "}
                                        Homebound
                                    </label>{" "}
                                    <br />
                                    <label>
                                        <input type="checkbox" name="service" defaultValue="emergency" /> An
                                        Emergency
                                    </label>{" "}
                                    <br />
                                </div>
                            </div>
                            <div className="events-container">
                                <label>Add events related to condition:</label> <br />
                                <div className="input-group">
                                    <select name="dateType" className='' >
                                        <option value="">Select date type</option>
                                        <option value="initial-treatment">Initial Treatment Date</option>
                                        <option value="onset-symptoms">
                                            Onset of Current Symptoms or Illness (Date of Injury)
                                        </option>
                                        <option value="similar-illness">
                                            Date of Same or Similar Illness
                                        </option>
                                        <option value="unable-work">
                                            Unable to Work in Current Occupation
                                        </option>
                                        <option value="disability-related">
                                            Disability Related to Condition
                                        </option>
                                        <option value="hospitalization-related">
                                            Hospitalization Related to Condition
                                        </option>
                                        <option value="last-menstrual">Last Menstrual Period</option>
                                        <option value="last-seen">Date Last Seen</option>
                                        <option value="acute-manifestation">
                                            Acute Manifestation Date
                                        </option>
                                        <option value="last-xray">Last X-ray Date</option>
                                        <option value="accident-date">Accident Date</option>
                                        <option value="transfer-care">Transfer of Care</option>
                                        <option value="prescription">Prescription</option>
                                        <option value="report-start">
                                            Report Start (Assumed Care Date)
                                        </option>
                                        <option value="report-end">
                                            Report End (Relinquished Care Date)
                                        </option>
                                        <option value="first-visit">First Visit or Consultation</option>
                                    </select>
                                </div>
                                <div className="event-row">

                                    <input type="date" name="eventDate" />
                                    <button type="button" className="delete-event">
                                        Delete
                                    </button>
                                </div>
                                <button type="button" className="add-event" onclick="addEvent()">
                                    Add event
                                </button>
                            </div>
                        </div>

                    </div>


                    <div className="tabcontent" style={{ display: activeTab === "authorizations" ? "block" : "none" }}>
                        {/* Content for Authorizations */}

                        <div id="authorizations" className="tabcontent">
                            <h3>Authorization Details</h3>
                            {/* Restored authorization fields */}
                            <div className="input-group">
                                <label htmlFor="authNumber">Auth #[Box 23]</label>
                                <input
                                    type="text"
                                    id="authNumber"
                                    name="authNumber"
                                    placeholder="Enter authorization number"
                                    required=""
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="serviceCode">Service Code</label>
                                <input
                                    type="text"
                                    id="serviceCode"
                                    name="serviceCode"
                                    placeholder="Enter service code"
                                    required=""
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="serviceDescription">Service Description</label>
                                <input
                                    type="text"
                                    id="serviceDescription"
                                    name="serviceDescription"
                                    placeholder="Enter service description"
                                    required=""
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="serviceStatus">Authorization Status</label>
                                <select id="serviceStatus" name="serviceStatus" required="">
                                    <option value="" disabled="" selected="">
                                        Select status
                                    </option>
                                    <option value="approved">Approved</option>
                                    <option value="pending">Pending</option>
                                    <option value="denied">Denied</option>
                                </select>
                            </div>
                            <div className="input-group">
                                <label htmlFor="dateApproved">Date Approved</label>
                                <input type="date" id="dateApproved" name="dateApproved" />
                            </div>
                            <div className="row">
                                <div className="input-group">
                                    <label htmlFor="startDate">Effective Start</label>
                                    <input type="date" id="startDate" name="startDate" required="" />
                                </div>
                                <div className="input-group">
                                    <label htmlFor="endDate">Effective End</label>
                                    <input type="date" id="endDate" name="endDate" required="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-group">
                                    <label htmlFor="unitsPer">
                                        Units Per
                                        <span className="tooltip">
                                            ?
                                            <span className="tooltip-text">
                                                Number of units allocated per time period (e.g., 1 unit per
                                                hour).
                                            </span>
                                        </span>
                                    </label>
                                    <input
                                        type="number"
                                        id="unitsPer"
                                        name="unitsPer"
                                        placeholder="e.g., 1.00"
                                        required=""
                                    />
                                </div>
                                <div className="input-group">
                                    <label htmlFor="unitOfMeasure">
                                        Unit of Measure
                                        <span className="tooltip">
                                            ?
                                            <span className="tooltip-text">
                                                Measurement type for units (e.g., Hour, Day, Month).
                                            </span>
                                        </span>
                                    </label>
                                    <select id="unitOfMeasure" name="unitOfMeasure" required="">
                                        <option value="" disabled="" selected="">
                                            Select unit of measure
                                        </option>
                                        <option value="quarter_hour">Quarter-Hour</option>
                                        <option value="hour">Hour</option>
                                        <option value="day">Day</option>
                                        <option value="week">Week</option>
                                        <option value="month">Month</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-group">
                                <label htmlFor="rate">Unit Rate</label>
                                <input
                                    type="text"
                                    id="rate"
                                    name="rate"
                                    placeholder="e.g., $2,440.65"
                                    required=""
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="maxUnits">Max Units</label>
                                <input
                                    type="number"
                                    id="maxUnits"
                                    name="maxUnits"
                                    placeholder="e.g., 12"
                                    required=""
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="amountApproved">Amount Approved</label>
                                <input
                                    type="text"
                                    id="amountApproved"
                                    name="amountApproved"
                                    placeholder="e.g., $29,287.80"
                                    required=""
                                />
                            </div>
                            <div className="buttons">
                                <button type="submit" className="save-button">
                                    Save Authorization
                                </button>
                                <button
                                    type="button"
                                    className="cancel-button"
                                    onclick="cancelAuthorization()"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="tabcontent" style={{ display: activeTab === "otherInfo" ? "block" : "none" }}>
                        {/* Content for Other Info */}


                        <div id="otherInfo" className="tabcontent">
                            <h3>Other Info</h3>
                            <p>Additional information goes here.</p>
                        </div>
                    </div>
                    <div className="tabcontent" style={{ display: activeTab === "EligibilityChecks" ? "block" : "none" }}>
                        {/* Content for Eligibility Checks History */}

                        <div id="EligibilityChecks" className="tabcontent">
                            <h3>Eligibility Checks History</h3>
                            <table border={1} style={{ width: "100%", borderCollapse: "collapse" }}>
                                <thead>
                                    <tr>
                                        <th>Date Run</th>
                                        <th>Eligibility Status</th>
                                        <th>In-Network Status</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>11/01/2024</td>
                                        <td>Eligible</td>
                                        <td>In-Network</td>
                                        <td>
                                            <a
                                                href="path/to/EligibilityCheck_11-01-2024.pdf"
                                                download="EligibilityCheck_11-01-2024.pdf"
                                            >
                                                Download
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10/01/2024</td>
                                        <td>Not Eligible</td>
                                        <td>Out-of-Network</td>
                                        <td>
                                            <a
                                                href="path/to/EligibilityCheck_10-01-2024.pdf"
                                                download="EligibilityCheck_10-01-2024.pdf"
                                            >
                                                Download
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>09/01/2024</td>
                                        <td>Eligible</td>
                                        <td>In-Network</td>
                                        <td>
                                            <a
                                                href="path/to/EligibilityCheck_09-01-2024.pdf"
                                                download="EligibilityCheck_09-01-2024.pdf"
                                            >
                                                Download
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            <button className="submit-btn">Submit Payment</button>
        </>

    )
}

export default ClientInsurence