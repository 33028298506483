import { apiClient, apiClientUpload } from "./api";
import {
  Agency_CHANGE_PASSWORD,
  AGENCY_LOGIN,
  AGENCY_SIGNUP,
  BASE_URL,
  CLINIC_PROFILE_GET,
  CLINIC_UPDATE,
  DOCTOR_CHANGE_PASSWORD,
  DOCTOR_LOGIN,
  DOCTOR_REQUETS,
  DOCTOR_SIGNUP,
  GET_CATEGORY,
  GET_DOCTOR_PROFILE,
  GET_METHOD,
  IMAGE_BASE_URL,
  PATIENT_GETPROFILE,
  PATIENT_LOGIN,
  PATIENT_SIGNUP,
  PATIENT_UPDATE,
  POST_METHOD,
  PUTMETHOD,
  REGISTER,
  UPDATE_DOCTOR_PROFILE,
  UPLOAD_IMAGE,
  PATIENT_LIST,
  AGENCY_LIST,
  GET_PATIENT_DETAIL,
  GET_Clinic_DETAIL,
  GET_PROVIDER_LIST,
  APPOINTMENTS,
  APPOINTMENTS_CONFIRM,
  GET_PROBLEM,
  ADD_PROBLEM,
  REQUEST,
  REQUEST_UPDATE,
  SLOT,
  CLINIC_LIST,
  PATIENT_REQUEST,
  AVAILABLE_DAYS,
  CREATE_AVAILABLE_DAYS,
  AGENCY_DOCTOR_REQUEST_UPDATE,
  AGENCY_DOCTOR_REQUEST_DETAILS,
  GET_AGENCY_REQUEST_LIST,
  GetJoinRequest,
  PATIENTUPDATEJOINREQUEST,
  SERVICECODE,
  SERVICELOCATION,
  DELETE,
  GET_PROVIDER_TYPE,
  GET_SERVICE_BOOKING,
  GET_AGENCY_BOOKING,
  GET_CONDITION_TREATED,
  GET_CREDENTIALS,
  GET_SPECIALTUES,
  GET_DELIVERY_MODE,
  GET_PAYMENT_SOURCES,
  MODALITIES,
  LANGUAGES,
  INSURANCE,
  FORGET,
  RESET_PASSWORD,
  SUBSCRIPTION,
  AGENCY_PLAN_MANAGEMENT,
  ADMIN_INSURENCE,
  PROVIDERREQUIREMENT,
  CLIENTREQUIREMENT,
  CPTCODE,
  PATIENT_UPDATE_REQUEST
} from "./url";

export const uploadImage = (payload) => {
  console.log("uploadImage--->", payload);
  return apiClientUpload({
    baseURL: IMAGE_BASE_URL,
    method: POST_METHOD,
    url: UPLOAD_IMAGE,
    data: payload,
  });
};

export const getSlots = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: SLOT,
  });
};

export const getAvailableDays =(payload) => {
  // console.log(payload,'====payload')
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: AVAILABLE_DAYS,
    data:payload
  });
};

export const createAvailableDays = (payload) => {
  console.log("createAvailableDays>=======>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: CREATE_AVAILABLE_DAYS,
    data: payload,
  });
};

export const updateAvailableDays =(payload) => {
  console.log(payload,'===payload')
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: AVAILABLE_DAYS,
    data:payload
  });
};

export const updateAgencyDoctor =(payload) => {
  console.log(payload,'===payload')
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: AGENCY_DOCTOR_REQUEST_UPDATE,
    data:payload
  });
};
export const GetProviderReq =(payload) => {
  console.log(payload,'===payload')
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: 'request/providerRequirements',
    data:payload
  });
};
export const getDetailsAgencyDoctor =(payload) => {
  console.log(payload,'===payload')
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${AGENCY_DOCTOR_REQUEST_DETAILS}?id=${payload}`,
    // data:payload
  });
};



export const register = (payload) => {
  // console.log("login_payload--->", payload);
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: REGISTER,
    data: payload,
  });
};

export const agencyLogin = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: AGENCY_LOGIN,
    data: payload,
  });
};


export const agencySignup = (payload) => {
  console.log("agencySignup>=======>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: AGENCY_SIGNUP,
    data: payload,
  });
};
export const ClinicUpdate = (payload) => {
  console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: CLINIC_UPDATE,
    data: payload,
  });
};
export const ClinicProfileGet = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: CLINIC_PROFILE_GET,
  });
};
// doctor flow 


export const DoctorSignup = (payload) => {
  // console.log("DoctorSignup>=======>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: DOCTOR_SIGNUP,
    data: payload,
  });
};


export const ForgetUser = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: FORGET,
    data: payload,
  });
};


export const RestUserPassword = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: RESET_PASSWORD,
    data: payload,
  });
};





export const DoctorLogin = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: DOCTOR_LOGIN,
    data: payload,
  });
};

export const DoctorChangePassword = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: DOCTOR_CHANGE_PASSWORD,
    data: payload,
  });
};



export const getDoctorProfile = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: GET_DOCTOR_PROFILE,
  });
};


export const updateDoctorProfile = (payload) => {
  // console.log("updateDoctorProfile==>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: UPDATE_DOCTOR_PROFILE,
    data: payload,
  });
};

export const AllPatientsGet = (id) => {
  // console.log(id,"payload======>")
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${PATIENT_LIST}${id}`,
  });
};

export const ClinicchangePassword = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: Agency_CHANGE_PASSWORD,
    data: payload,
  });
};




export const GetCategory = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: GET_CATEGORY,
  });
};

export const GetDoctorrequest= ( payload) => {
  // console.log(payload,'=====payload')
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `${DOCTOR_REQUETS}`,
    data:payload
  });
};


export const getAgencyRequestList= ( payload) => {
  // console.log(payload,'=====payload')
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `${GET_AGENCY_REQUEST_LIST}`,
    data:payload
  });
};

export const GetClinic= (  ) => {

  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${AGENCY_LIST}`,
  });
};

export const PatientDetails= (id) => {

  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_PATIENT_DETAIL}${id}`,
  });
};

export const ClinicDetail= (id,doctorId) => {
  // console.log(id,'===id')

  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_Clinic_DETAIL}${id}&doctorId=${doctorId}`,
  });
};
export const GetProblems= (id) => {

  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_PROBLEM}${id}`,
  });
};
export const Addproblems = (payload) => {
  console.log("ADD_PROBLEM==>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: ADD_PROBLEM,
    data: payload,
  });
};
export const Changeappointmentsstatus = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: APPOINTMENTS_CONFIRM,
    data: payload,
  });
};
export const JoinClinic = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: REQUEST,
    data: payload,
  });
};

export const JoinClinicUpdate = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `${REQUEST}?requestId=${payload.requestId}`,
    data: payload,
  });
};


export const GetDoctorsRequest = (payload={}) => {

  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${REQUEST}/getAgencyDoctor`,
    data: payload,
  });
};

export const createAgencyDoctor = (payload={}) => {
  console.log("createAgencyDoctor===>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${REQUEST}/createAgencyDoctor`,
    data: payload,
  });
};

export const createAgencyPatient = (payload={}) => {
  console.log("createAgencyDoctor===>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `patient/patinetJoinClinic`,
    data: payload,
  });
};

export const UpdateRequest = (payload,id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `${REQUEST_UPDATE}${id}`,
    data: payload,
  });
};
// 
// Patient flow =========>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export const PatientSignup = (payload) => {
  console.log("PatientSignup>=======>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_SIGNUP,
    data: payload,
  });
};

export const PatientRequest = (payload) => {
  console.log("PatientRequest>=======>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_REQUEST,
    data: payload,
  });
};


export const PatientLogin = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_LOGIN,
    data: payload,
  });
};

export const Patientgetprofile = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: PATIENT_GETPROFILE,
  });
};

export const PatientUpdate = (payload) => {
  console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_UPDATE,
    data: payload,
  });
};
export const Providerlist = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: GET_PROVIDER_LIST,
  });
};
export const Postappointments = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: APPOINTMENTS,
    data: payload,
  });
};
export const Clinicrequestlist = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: CLINIC_LIST,
  });
};
export const Getmyclinicpatient = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: GetJoinRequest,
    data: payload,
  });
};
export const Patientrequestupdate = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: PATIENTUPDATEJOINREQUEST,
    data: payload,
  });
};

export const GetPlaceservice = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: SERVICECODE,
  });
};

export const AddAddressbyagency = (payload={}) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${SERVICELOCATION}`,
    data: payload,
  });
};
export const Getserviceaddress = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: SERVICELOCATION,
    data: payload,
  });
};

export const GetProviderType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${GET_PROVIDER_TYPE}${payload}`,
  });
};



export const AddProviderType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_PROVIDER_TYPE,
    data: payload,
  });
};


export const DeleteProviderType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${GET_PROVIDER_TYPE}${payload}`,
  });
};



export const GetServiceBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${GET_SERVICE_BOOKING}${payload}`,
  });
};



export const AddServiceBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_SERVICE_BOOKING,
    data: payload,
  });
};


export const DeleteServiceBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${GET_SERVICE_BOOKING}${payload}`,
  });
};

//========================================


export const GetAgencyBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${GET_AGENCY_BOOKING}${payload}`,
  });
};



export const AddAgencyBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_AGENCY_BOOKING,
    data: payload,
  });
};


export const DeleteAgencyBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${GET_AGENCY_BOOKING}${payload}`,
  });
};
//============================================



export const GetCredentials = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${GET_CREDENTIALS}${payload}`,
  });
};
export const GetProviderRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${PROVIDERREQUIREMENT}${payload}`,
  });
};
export const GetClientRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${CLIENTREQUIREMENT}${payload}`,
  });
};
export const AddCredentials = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_CREDENTIALS,
    data: payload,
  });
};

export const AddProviderRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PROVIDERREQUIREMENT,
    data: payload,
  });
};
export const AddClientRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: CLIENTREQUIREMENT,
    data: payload,
  });
};
export const DeleteClientRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${CLIENTREQUIREMENT}${payload}`,
  });
};
export const DeleteProviderRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${PROVIDERREQUIREMENT}${payload}`,
  });
};
export const DeleteCredentials = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${GET_CREDENTIALS}${payload}`,
  });
};




//=============================================


export const GetSpecialties = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${GET_SPECIALTUES}${payload}`,
  });
};



export const AddSpecialties = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_SPECIALTUES,
    data: payload,
  });
};


export const DeleteSpecialties = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${GET_SPECIALTUES}${payload}`,
  });
};


//=============================================


export const GetDeliveryMode = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${GET_DELIVERY_MODE}${payload}`,
  });
};



export const AddDeliveryMode = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_DELIVERY_MODE,
    data: payload,
  });
};


export const DeleteDeliveryMode = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${GET_DELIVERY_MODE}${payload}`,
  });
};

//============================================


export const GetPaymentSources = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${GET_PAYMENT_SOURCES}${payload}`,
  });
};



export const AddPaymentSources = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_PAYMENT_SOURCES,
    data: payload,
  });
};


export const DeletePaymentSources = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${GET_PAYMENT_SOURCES}${payload}`,
  });
};

//============================================


export const GetConditionTreated = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${GET_CONDITION_TREATED}${payload}`,
  });
};



export const AddConditionTreated = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_CONDITION_TREATED,
    data: payload,
  });
};


export const DeleteConditionTreated = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${GET_CONDITION_TREATED}${payload}`,
  });
};


export const Deleteserviceaddress = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: SERVICELOCATION,
    data: payload,
  });
};

export const GetServiceParactiseaddress = (id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `doctor/clinicServiceLocation?id=${id}`,
  
  });
};


export const GetModalities = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`agency/${MODALITIES}${payload}`,
  });
};

export const GetInsurance = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`agency/${INSURANCE}${payload}`,
  });
};

export const GetLanguages= (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`agency/${LANGUAGES}${payload}`,
  });
};

export const AddInsurance = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/${INSURANCE}`,
    data: payload,
  });
};

export const DeleteInsurance = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `agency/${INSURANCE}${payload}`,
  });
};

export const AddModalities = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/${MODALITIES}`,
    data: payload,
  });
};

export const DeleteModalities = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `agency/${MODALITIES}${payload}`,
  });
};
export const FetchMODALITIES = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`agency/${MODALITIES}${payload}`,
  });
};
export const FetchPopulationServed = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`agency/PopulationServed${payload}`,
  });
};

export const AddPopulationServed = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url:`agency/PopulationServed`,
    data: payload,
  });
};
export const FetchAppointmentType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`agency/AppointmentType${payload}`,
  });
};
export const FetchDegreetType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`agency/degreeType${payload}`,
  });
};
export const FetchAddAppoientmentVisit = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`agency/AppointmentVisit${payload}`,
  });
};
export const AddAppoientmenttype = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url:`agency/AppointmentType`,
    data: payload,
  });
};
export const AddDegreeType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url:`agency/degreeType`,
    data: payload,
  });
};
export const FetchVisitReason = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`agency/VisitReason${payload}`,
  });
};
export const AddVisitReason = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url:`agency/VisitReason`,
    data: payload,
  });
};
export const EditVisitReason = (payload,id) => {
  console.log(`${BASE_URL}agency/VisitReason${id}`,"urll=========")
  console.log(payload,"payload--------")
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url:`agency/VisitReason?id=${id}`,
    data: payload,
  });
};

export const DeleteVisitReason = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`agency/VisitReason${payload}`,
  });
};
export const AddAppoientmentVisit = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url:`agency/AppointmentVisit`,
    data: payload,
  });
};
export const DeleteAppoientmenttype = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`agency/AppointmentType${payload}`,
  });
};
export const DeletedegreeType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`agency/degreeType${payload}`,
  });
};
export const DeleteAppointmentVisit = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`agency/AppointmentVisit${payload}`,
  });
};
export const DeletePopulationServed = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`agency/PopulationServed${payload}`,
  });
};


export const FetchLanguage = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`agency/Languages${payload}`,
  });
};

export const AddLanguage = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url:`agency/Languages`,
    data: payload,
  });
};

export const DeleteLanguage = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`agency/Languages${payload}`,
  });
};

export const Fetchsubscription = (id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    // url:`${SUBSCRIPTION}?id=${id}`,
    url:`subscriptions?id=${id}`
  });
};

export const Addsubscription = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url:SUBSCRIPTION,
    data: payload,
  });
};

export const Deletesubscription = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${SUBSCRIPTION}?id=${payload}`,
  });
};
// SUBSCRIPTION

//==========AGENCY_PLAN_MANAGEMENT=====


export const FetchAgencyPlanManagement = (id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${AGENCY_PLAN_MANAGEMENT}?id=${id}`
  });
};

export const AddAgencyPlanManagement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url:AGENCY_PLAN_MANAGEMENT,
    data: payload,
  });
};

export const DeleteAgencyPlanManagement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`${AGENCY_PLAN_MANAGEMENT}?id=${payload}`,
  });
};


// ADMIN INSURENCE 

export const GetAdminInsurence = (payload) => {
  // console.log(payload,"payload--====")
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url:`${ADMIN_INSURENCE}/search`,
    data:payload
  });
};
export const Addcptcode = (payload) => {
  // console.log(payload,"payload--====")
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url:`${CPTCODE}`,
    data:payload
  });
};

export const FetchCptcodes = (data) => {
  console.log(`${CPTCODE}?${data}`,"`${CPTCODE}?${data}`====")
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`${CPTCODE}?${data}`
  });
};

export const AddInsuranceContract = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url:`agency/insuranceContract`,
    data: payload,
  });
};

export const FetchInsuranceContract = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url:`agency/insuranceContract${payload}`,
  });
};
export const DeleteInsuranceContract  = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url:`agency/insuranceContract${payload}`,
  });
};

export const EditInsurance = (payload,id) => {
  console.log(`${BASE_URL}agency/VisitReason${id}`,"urll=========")
  console.log(payload,"payload--------")
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url:`agency/${INSURANCE}?id=${id}`,
    data: payload,
  });
};

export const Patientrequestupdates = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_UPDATE_REQUEST,
    data: payload,
  });
};

export const Getagencypatientdetails =(payload) => {
  console.log(payload,'===payload')
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `patient/patinetRequestDetails?id=${payload}`,
    // data:payload
  });
};