import React from 'react'
import { DropDownManuCustom } from '../../../Components/DropDownButton'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
const ClientHistory = () => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(validationSchema),
  });
  const onSubmit = async (data) => {
    // data.id = info?._id
    console.log(data, "datata======");
  }
    // return }
  return (
    <div className="patient_right">
  <form onSubmit={handleSubmit(onSubmit)}>

      
      <div className="Lifestyle_Determinants_main_div1">
        <div className="Lifestyle_Determinants_chart1">
          <div className="Lifestyle_Determinants_div1">
            Lifestyle Determinants
            <img
              src={require("../../../assest/Images/pen_icon.png")}
              alt="Pen Icon"
            />
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Smoking</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Alcohol</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Caffeine</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Recreational Drugs</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Special Diet</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Regularly</div>
            <div className="smoking_txt1">Yes</div>
          </div>
        </div>


        <div className="Lifestyle_Determinants_chart1">
          <div className="Lifestyle_Determinants_div1">
            Family History
            <img
              src={require("../../../assest/Images/plus_icon.png")}
              alt="Plus Icon"
            />
          </div>
          <p className="Overview_Mother_text">Mother</p>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Date of Birth</div>
            <div className="smoking_txt1">Jan 17, 1975 (49 years)</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Problems</div>
            <div className="smoking_txt1">-</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Life Status</div>
            <div className="smoking_txt1">Deceased</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Date of Death</div>
            <div className="smoking_txt1">Jul 17,2024</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Cause of Death</div>
            <div className="smoking_txt1">-</div>
          </div>
        </div>

        <div className="Lifestyle_Determinants_chart1">
          <div className="Lifestyle_Determinants_div1">
            Medical History
            <img
              src={require("../../../assest/Images/plus_icon.png")}
              alt="Plus Icon"
            />
          </div>
          <p className="Blood_txt1">Blood type & RAH</p>
          <div className="OverView_space-div" style={{ width: "100%", gap: "6%" , marginTop:-15}}>
            {/* <DropDownManuCustom 
              // label="Select"
              placeholder="Select"
              dropdownicon
              style={{ width: "47%" }}
              divstyle={{ backgroundColor: "#fff",height:35 }}
              options={[
                // "1199SEIU Funds",
                // "ACS Benefit Service",
                // "Aetna",
                // "Aither Health",
                // "Banner (Aetna)",
                // "Benefit Management Inc.",
                // "Benefits Administration Services",
                // "Assurant (Cigna)",
              ]}
              register={register}

            /> */}
             <DropDownManuCustom
          //  style={{ width: "47%" }}
          //  divstyle={{ backgroundColor: "#fff",height:35 }}
            placeholder="Select option"
            options={[
              { _id: "1199SEIU Funds", name: "1199SEIU Funds" },
              { _id: "ACS Benefit Service", name: "ACS Benefit Service" },
              { _id: "Aetna", name: "Aetna" },
              { _id: "Aither Health", name: "Aither Health" },
              { _id: "Banner (Aetna)", name: "Banner (Aetna)" },
              { _id: "Benefit Management Inc.", name: "Benefit Management Inc." },
              { _id: "Assurant (Cigna)", name: "Assurant (Cigna)" },
            ]}
            name="gender"
            dropdownicon
            register={register}
          
          />
           <DropDownManuCustom
              // label="Select"
              placeholder="Select option"
              options={[
                { _id: "1199SEIU Funds", name: "1199SEIU Funds" },
                { _id: "ACS Benefit Service", name: "ACS Benefit Service" },
                { _id: "Aetna", name: "Aetna" },
                { _id: "Aither Health", name: "Aither Health" },
                { _id: "Banner (Aetna)", name: "Banner (Aetna)" },
                { _id: "Benefit Management Inc.", name: "Benefit Management Inc." },
                { _id: "Assurant (Cigna)", name: "Assurant (Cigna)" },
              ]}
              name="gender"
              dropdownicon
              register={register}

            />
          </div>
          <p className="Blood_txt1">Custom Items</p>
          <div className="OverView_space-div" style={{ width: "100%", gap: "6%" , marginTop:-15}}>
          <DropDownManuCustom
              // label="Select"
              placeholder="Select option"
              options={[
                { _id: "1199SEIU Funds", name: "1199SEIU Funds" },
                { _id: "ACS Benefit Service", name: "ACS Benefit Service" },
                { _id: "Aetna", name: "Aetna" },
                { _id: "Aither Health", name: "Aither Health" },
                { _id: "Banner (Aetna)", name: "Banner (Aetna)" },
                { _id: "Benefit Management Inc.", name: "Benefit Management Inc." },
                { _id: "Assurant (Cigna)", name: "Assurant (Cigna)" },
              ]}
              name="gender"
              dropdownicon
              register={register}

            />
          <DropDownManuCustom
              // label="Select"
              placeholder="Select option"
              options={[
                { _id: "1199SEIU Funds", name: "1199SEIU Funds" },
                { _id: "ACS Benefit Service", name: "ACS Benefit Service" },
                { _id: "Aetna", name: "Aetna" },
                { _id: "Aither Health", name: "Aither Health" },
                { _id: "Banner (Aetna)", name: "Banner (Aetna)" },
                { _id: "Benefit Management Inc.", name: "Benefit Management Inc." },
                { _id: "Assurant (Cigna)", name: "Assurant (Cigna)" },
              ]}
              name="gender"
              dropdownicon
              register={register}

            />
          </div>
        </div>
  
      </div>



      <div className="Lifestyle_Determinants_main_div_two1">
        <div className="Lifestyle_Determinants_chart1">
          <div className="Lifestyle_Determinants_div1">
            Mental Health History
            <img
              src={require("../../../assest/Images/pen_icon.png")}
              alt="Pen Icon"
            />
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Mood Disorder</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Psychotic Disorder</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Eating Disorder</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1"> Anxiety Disorder</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Personality Disorder</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Other Conditions</div>
            <div className="smoking_txt1">Yes</div>
          </div>
        </div>
        <div
          className="Lifestyle_Determinants_chart1"
          style={{ marginLeft: 20 }}
        >
          <div className="Lifestyle_Determinants_div1">
            Substance Use History
            <img
              src={require("../../../assest/Images/plus_icon.png")}
              alt="Plus Icon"
            />
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Ecstasy</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Benzodiazepines</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Amphetamines</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1"> Opioids</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Buprenorphines</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Cannabis</div>
            <div className="smoking_txt1">Yes</div>
          </div>
        </div>
      </div>
      </form>
    </div>
  )
}

export default ClientHistory