import React, { useState } from 'react';

const CareTeam = () => {
 const [activeTab, setActiveTab] = useState("internal");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const setupSearch = (inputId, dropdownId, data) => {
    const input = document.getElementById(inputId);
    const dropdown = document.getElementById(dropdownId);
    input.addEventListener('input', () => {
      const query = input.value.toLowerCase();
      dropdown.innerHTML = '';
      if (query) {
        const results = data.filter(item => item.toLowerCase().includes(query));
        results.forEach(result => {
          const li = document.createElement('li');
          li.textContent = result;
          li.addEventListener('click', () => {
            input.value = result;
            dropdown.style.display = 'none';
          });
          dropdown.appendChild(li);
        });
        dropdown.style.display = 'block';
      } else {
        dropdown.style.display = 'none';
      }
    });
    input.addEventListener('blur', () => {
      setTimeout(() => dropdown.style.display = 'none', 200);
    });
  };

  const openModal = () => {
    document.getElementById('add-member-modal').style.display = 'block';
    document.getElementById('overlay').style.display = 'block';
  };

  const closeModal = () => {
    document.getElementById('add-member-modal').style.display = 'none';
    document.getElementById('overlay').style.display = 'none';
  };

  const saveMember = () => {
    const facility = document.getElementById('facility-search-internal').value || document.getElementById('facility-search-external').value || document.getElementById('facility-search-facility').value;
    const providerType = document.getElementById('provider-type-search-internal').value || document.getElementById('provider-type-search-external').value;
    const teamMember = document.getElementById('team-member-search-internal').value || document.getElementById('team-member-search-external').value;
    const fromDate = document.getElementById('from-date-internal').value || document.getElementById('from-date-facility').value;
    const toDate = document.getElementById('to-date-internal').value || document.getElementById('to-date-facility').value;
    if (facility && providerType && teamMember && fromDate && toDate) {
      const tableBody = document.querySelector('#member-table tbody');
      const row = document.createElement('tr');
      row.innerHTML = `
        <td>${facility}</td>
        <td>${providerType}</td>
        <td>${teamMember}</td> 
        <td>${fromDate}</td>
        <td>${toDate}</td>
      `;
      tableBody.appendChild(row);
      closeModal();
    } else {
      alert('Please fill in all fields.');
    }
  };

  return (
    <div className="container">
      <h1>Care Team</h1>
      <button className="add-member-button" onClick={openModal}>Add Member</button>

      <table className="table" id="member-table">
        <thead>
          <tr>
            <th>Facility</th>
            <th>Provider Type</th>
            <th>Team Member</th>
            <th>From Date</th>
            <th>To Date</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>

      <div className="modal_twooo" id="add-member-modal">
        <h2>Add Team Member</h2>
        <div className="tab-buttons_twoooo">
          <button
            className={activeTab === "internal" ? "active" : ""}
            onClick={() => handleTabClick("internal")}
          >
            Internal
          </button>
          <button
            className={activeTab === "external" ? "active" : ""}
            onClick={() => handleTabClick("external")}
          >
            External
          </button>
          <button
            className={activeTab === "facility" ? "active" : ""}
            onClick={() => handleTabClick("facility")}
          >
            Facility
          </button>
        </div>

        {activeTab === "internal" && (
          <div id="internal" className="tab-content active">
            <div className="form-group">
              <label htmlFor="facility-search-internal">Facility</label>
              <input type="search" id="facility-search-internal" placeholder="Search Facility" />
              <ul className="search-dropdown" id="facility-dropdown-internal"></ul>
            </div>
            <div className="form-group">
              <label htmlFor="provider-type-search-internal">Provider Type</label>
              <input type="search" id="provider-type-search-internal" placeholder="Search Provider Type" />
              <ul className="search-dropdown" id="provider-type-dropdown-internal"></ul>
            </div>
            <div className="form-group">
              <label htmlFor="team-member-search-internal">Team Member</label>
              <input type="search" id="team-member-search-internal" placeholder="Search Team Member" />
              <ul className="search-dropdown" id="team-member-dropdown-internal"></ul>
            </div>
            <div className="form-group">
              <label htmlFor="from-date-internal">From Date</label>
              <input type="date" id="from-date-internal" />
            </div>
            <div className="form-group">
              <label htmlFor="to-date-internal">To Date</label>
              <input type="date" id="to-date-internal" />
            </div>
            <div className="form-group">
              <label htmlFor="lastseen-date-internal">Last Seen Date</label>
              <input type="date" id="lastseen-date-internal" />
            </div>
          </div>
        )}

        {activeTab === "external" && (
          <div id="external" className="tab-content">
            <div className="form-group">
              <label htmlFor="facility-search-external">Practice</label>
              <input type="search" id="facility-search-external" placeholder="Search Practice" />
              <ul className="search-dropdown" id="facility-dropdown-external"></ul>
            </div>
            <div className="form-group">
              <label htmlFor="provider-type-search-external">Provider Type</label>
              <input type="search" id="provider-type-search-external" placeholder="Search Provider Type" />
              <ul className="search-dropdown" id="provider-type-dropdown-external"></ul>
            </div>
            <div className="form-group">
              <label htmlFor="team-member-search-external">Team Member</label>
              <input type="search" id="team-member-search-external" placeholder="Search Team Member" />
              <ul className="search-dropdown" id="team-member-dropdown-external"></ul>
            </div>
            <div className="form-group">
              <label htmlFor="from-date-external">From Date</label>
              <input type="date" id="from-date-external" />
            </div>
            <div className="form-group">
              <label htmlFor="to-date-external">To Date</label>
              <input type="date" id="to-date-external" />
            </div>
            <div className="form-group">
              <label htmlFor="lastseen-date-external">Last Seen Date</label>
              <input type="date" id="lastseen-date-external" />
            </div>
          </div>
        )}

        {activeTab === "facility" && (
          <div id="facility" className="tab-content">
            <div className="form-group">
              <label htmlFor="facility-search-facility">Facility</label>
              <input type="search" id="facility-search-facility" placeholder="Search Facility" />
              <ul className="search-dropdown" id="facility-dropdown-facility"></ul>
            </div>
            <div className="form-group">
              <label htmlFor="from-date-facility">From Date</label>
              <input type="date" id="from-date-facility" />
            </div>
            <div className="form-group">
              <label htmlFor="to-date-facility">To Date</label>
              <input type="date" id="to-date-facility" />
            </div>
            <div className="form-group">
              <label htmlFor="lastseen-date-facility">Last Seen Date</label>
              <input type="date" id="lastseen-date-facility" />
            </div>
          </div>
        )}

        <div className="form-buttons">
          <button className="cancel" onClick={closeModal}>Cancel</button>
          <button className="save" onClick={closeModal}>Save</button>
        </div>
      </div>

      <div className="overlay" id="overlay" onClick={closeModal}></div>
    </div>
  );
};

export default CareTeam;
