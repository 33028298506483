import React, { useEffect, useState } from "react";
import Inputbox from "../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  AddAppoientmenttype,
  AddProviderRequirement,
  AddProviderType,
  DeleteProviderRequirement,
  FetchAppointmentType,
  GetProviderRequirement,
  GetProviderType,
} from "../../../api/helper";
import { useSelector } from "react-redux";
import { ShowToast } from "../../../helpers/ToastService";
import { Box, Modal } from "@mui/material";
import moment from "moment";
import { DropDownManuCustom, DropDownManuCustomMulti } from "../../Components/DropDownButton";
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Requirement is required"),
  issueDate: Yup.string().required("Issue Date is required"),
  expirationDate: Yup.string().required("Expiration Date is required"),
  requirementType: Yup.string().required("Requirement Type Issued is required"),
  // ExpirationDate: Yup.string().required("Expiration Date is required"),
  // YearOfExperience: Yup.string().required("Year of Experience is required"),
});
const requirementTypedata =[
  { _id: "Identification & Personal Details", name: "Identification & Personal Details" },
  { _id: "Professional Credentials", name: "Professional Credentials" },
  { _id: "Education and Training", name: "Education and Training" },
  { _id: "Employment History", name: "Employment History" },
  { _id: "Insurance and Liability", name: "Insurance and Liability" },
  { _id: "Provider Agreements and Forms", name: "Provider Agreements and Forms" },
  { _id: "State and Federal Compliance", name: "State and Federal Compliance" },
  { _id: "Clinical Competence and Scope of Practice", name: "Clinical Competence and Scope of Practice" },
  { _id: "Health & Wellness", name: "Health & Wellness" },
  { _id: "Professional Affiliation", name: "Professional Affiliation" },
  { _id: "Practice Information", name: "Practice Information" },
  { _id: "Billing and Payment", name: "Billing and Payment" },
  { _id: "Insurance Panels and Credentialing", name: "Insurance Panels and Credentialing" },
  { _id: "Documentation Templates and Clinical Protocols", name: "Documentation Templates and Clinical Protocols" },
  { _id: "Digital Credentials", name: "Digital Credentials" },
  { _id: "Consent and Attestation", name: "Consent and Attestation" },
  { _id: "Compliance & Quality Improvement", name: "Compliance & Quality Improvement" },
  { _id: "Emergency Contact", name: "Emergency Contact" },
  { _id: "Other Documentation", name: "Other Documentation" },
]
const Credentials = () => {
  const [Placedata, setPlacedata] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [addressData, setaddressData] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [data, setdata] = useState([]);
  const [providertypedata, setprovidertypedata] = useState("");

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    data.agencyId = profiledata?._id;
    try {
      const response = await AddProviderRequirement(data);
      ShowToast(response?.data?.message);
      FetchAddresslist();
      setShowModal(false);
      reset();
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    console.log("Deleted ID:", id);

    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) {
      return;
    }

    try {
      // const response = await DeleteCredentials(`?id=${id}`);
      const response = await DeleteProviderRequirement(`?id=${id}`);

      if (response?.data?.success) {
        ShowToast(response?.data?.message);
        FetchAddresslist();
      } else {
        ShowToast("Error: Unable to delete address.");
      }
    } catch (error) {
      console.error("Delete operation failed:", error);
      ShowToast("An error occurred while deleting the address.");
    }
  };

  const FetchAddresslist = async () => {
    try {
      // const response = await GetCredentials(`?id=${profiledata?._id}`);
      const response = await GetProviderRequirement(`?id=${profiledata?._id}`);

      console.log(response?.data?.data, "GetProviderRequirement======");
      setaddressData(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    FetchAddresslist();
  }, []);
  const GetAppoientmenttype = async () => {
    const response = await GetProviderType(`?id=${profiledata?._id}`);
    // console.log(response?.data?.data, "data=====");
    setdata(response?.data?.data);
  };
  useEffect(() => {
    GetAppoientmenttype();
  }, []);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "90vh",
    overflowY: "auto", // Set a fixed height for the modal
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    outline: "none",
    // Enable vertical scrolling
  };
  const onProvidertypeSubmit = async (data) => {
    // data.agencyId = profiledata?._id;
    console.log(data, "data---======");

    // return
    if (data.name === "") {
      ShowToast("Please Enter Provider Type");
      return;
    }
    data.agencyId = profiledata?._id;
    console.log(data, "hiiii======");
    try {
      const response = await AddProviderType(data);
      console.log(response?.data?.data, "respones====");
      ShowToast(response?.data?.message);

      GetAppoientmenttype();
      setprovidertypedata("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleProvider = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("providerTypeId", ids, { shouldValidate: true });
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <div></div>
        <div
          className="Add_button__"
          style={{ width: 150 }}
          onClick={openModal}
        >
          Add Provider Requirement
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Provider Type</th>
            <th>Requirement Type</th>
            
            <th>Requirement</th>
            <th>Description</th>
            <th>Issue Date</th>
            <th>Expiration Date</th>
            <th>Within Validity</th>

            {/* <th>Years of Experience</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {addressData.length === 0 ? (
            <tr>
              <td colSpan="11" style={{ textAlign: "center", padding: "20px" }}>
                No Requirement found.
              </td>
            </tr>
          ) : (
            addressData.map((row, index) => (
              <tr key={index} style={tableRowStyle}>
                <td style={tableCellStyle}>  {row?.providerTypeId?.map((item) => item.name).join(", ")}</td>
                
                <td style={tableCellStyle}>{row?.requirementType}</td>
                <td style={tableCellStyle}>{row?.name}</td>
                <td style={tableCellStyle}>{row?.description}</td>
                <td style={tableCellStyle}>
                  {" "}
                  {moment(row?.issueDate).format("YYYY-MM-DD")}
                </td>
                <td style={tableCellStyle}>
                  {" "}
                  {moment(row?.expirationDate).format("YYYY-MM-DD")}{" "}
                </td>
                <td style={tableCellStyle}>
                  {row?.validity ? "True" : "False"}
                </td>
                <td style={tableCellStyle}>
                  <button
                    onClick={() => handleDelete(row._id)}
                    style={deleteButtonStyle}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>{" "}
      {/* Missing closing table tag */}
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-title"
      >
        <Box sx={modalStyle} className="Custom_servicer_modalMain">
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              alignItems: "center",
              justifyItems: "center",
              marginBottom: 20,
            }}
          >
            <h1 style={{ textAlign: "left" }}>New Provider Requirement</h1>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {/* <div style={{ fontWeight: "bold", fontSize: 17 }}>
                Select Provider Type
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                {data.map((item) => (
                  <div
                    key={item._id}
                    style={{
                      borderWidth: "1px",
                      borderColor: "grey",
                      borderStyle: "solid",
                      padding: "10px",
                      borderRadius: "10px",
                      width: "auto",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      color: providerTypeId.includes(item._id) ? "white" : null,
        backgroundColor: providerTypeId.includes(item._id)
          ? "#011434"
          : "transparent",
      }}
      onClick={() => toggleProviderType(item._id)}
                  >
                    {item.name}
                  </div>
                ))}
              </div> */}
                <DropDownManuCustomMulti
                label="Select Provider Type"
                placeholder="Select Provider Type"
                options={data}
                name="providerTypeId"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                // onSelect={(value) => setValue("language", value)}
                onSelect={handleProvider}
              />
              <div style={{height:16}}/>
               {/* <DropDownManuCustomMulti
                label="Select Requirement Type"
                placeholder="Select Provider Type"
                options={data}
                name="providerTypeId"
                register={register}
                id="_id"
              
                clearErrors={clearErrors}
                // onSelect={(value) => setValue("language", value)}
                onSelect={handleProvider}
              /> */}
                 <DropDownManuCustom
                label="Select Requirement Type"
                placeholder="Select Requirement Type"
                      options={requirementTypedata}
                      name="requirementType"
                      register={register}
                      clearErrors={clearErrors}
                     
                    /> 
                       <p style={{ color: "red", textAlign: "left" }}>
                {errors.requirementType?.message}
              </p>
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Inputbox
                  className="Custom_input"
                  type="text"
                  placeholder="Enter New Provider Type"
                  name="Providertype"
                  // style={{ marginBottom: -20 }}
                  value={providertypedata}
                  onChange={(e) => setprovidertypedata(e.target.value)}
                />
                <div
                  style={{
                    alignSelf: "center",
                    width: "40%",
                    height: "50px",
                    marginLeft: 10,
                    // marginBottom: 25,
                    cursor: "pointer",
                  }}
                  className="Save_buttonnn"
                  onClick={() =>
                    onProvidertypeSubmit({ name: providertypedata })
                  }
                >
                  Submit
                </div>
              </div> */}
              <div style={{ fontWeight: "bold", fontSize: 23,marginTop:15 }}>
                Add New Requirement
              </div>
              <Inputbox
                label="Requirement"
                className="Custom_input"
                type="text"
                placeholder="Enter Requirement"
                name="name"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.name?.message}
              </p>

              <Inputbox
                label="Description"
                className="Custom_input"
                type="text"
                placeholder="Enter Description"
                name="description"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.description?.message}
              </p>
              <Inputbox
                label="Issue Date"
                className="Custom_input"
                type="date"
                placeholder="Enter Requirement"
                name="issueDate"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.issueDate?.message}
              </p>

              <Inputbox
                label="Expiration Date"
                className="Custom_input"
                type="date"
                placeholder="Enter Description"
                name="expirationDate"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.expirationDate?.message}
              </p>
              {/* Within Validity */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <label style={{ marginLeft: 8 }}>Within Validity</label>
                <input
                  type="checkbox"
                  id="validity"
                  name="validity"
                  {...register("validity")}
                />
              </div>
              {/* 
    <Inputbox
      label="Credential Name"
      className="Custom_input"
      type="text"
      placeholder="Enter Credential Name"
      name="name"
      register={register}
    />
    <p style={{ color: "red", textAlign: "left" }}>{errors.name?.message}</p>

    <Inputbox
      label="Credential Type"
      className="Custom_input"
      type="text"
      placeholder="Enter Credential Type"
      name="type"
      register={register}
    />
    <p style={{ color: "red", textAlign: "left" }}>{errors.type?.message}</p>

    <Inputbox
      label="Issuing Body"
      className="Custom_input"
      type="text"
      placeholder="Enter Issuing Body"
      name="IssuingBody"
      register={register}
    />
    <p style={{ color: "red", textAlign: "left" }}>{errors.IssuingBody?.message}</p>

    <Inputbox
      label="State Issued"
      className="Custom_input"
      type="text"
      placeholder="Enter State Issued"
      name="StateIssued"
      register={register}
    />
    <p style={{ color: "red", textAlign: "left" }}>{errors.StateIssued?.message}</p>

    <Inputbox
      label="Expiration Date"
      className="Custom_input"
      type="text"
      placeholder="Enter Expiration Date"
      name="ExpirationDate"
      register={register}
    />
    <p style={{ color: "red", textAlign: "left" }}>{errors.ExpirationDate?.message}</p>

    <Inputbox
      label="Year of Experience"
      className="Custom_input"
      type="text"
      placeholder="Enter Year of Experience"
      name="YearOfExperience"
      register={register}
    />
    <p style={{ color: "red", textAlign: "left" }}>{errors.YearOfExperience?.message}</p> */}

              <button
                type="submit"
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  alignSelf: "center",
                  width: "100%",
                  height: "50px",
                }}
                className="Save_buttonnn"
              >
                Submit
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default Credentials;

const tableHeaderStyle = {
  padding: "10px",
  borderBottom: "2px solid #ddd",
  textAlign: "left",
  fontWeight: "bold",
  backgroundColor: "#f4f4f4",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};

const tableRowStyle = {
  borderBottom: "1px solid #ddd",
};

const deleteButtonStyle = {
  backgroundColor: "red",
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
