import React from 'react'
import logo from "../assest/Images/mindWellIcon.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { RestUserPassword } from '../../api/helper';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ShowToast } from '../../helpers/ToastService';
import Inputbox from '../Components/Inputbox';


const validationSchema = Yup.object().shape({
  password: Yup.string()
      .required('New Password is required')
      .min(5, 'Password must be at least 5 characters long'), 
  newPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'), 
});


function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('token');

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema),})

  const onSubmit = async (data) => {
    let saveData = {
      ...data,
      token:query
    }
    try {
      const response = await RestUserPassword(saveData)
      ShowToast(response.data.message,"success")
      reset()
      navigate('/success')
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <div className="login custom_login--width">
        <div className="login_content">
          <div className="modal d-block" tabindex="-1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={()=>{
                      navigate('/')
                    }}
                  ></button>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className='w-100'>
                  <div className="modal-body text-center w-100">
                    <img src={logo} alt="logo"style={{height:200,}} />
                    <p className="label">Please create your new password</p>

                    <div className="mb-3">
                      <label
                        for="exampleInputPassword1"
                        className="form-label label"
                      >
                        New Password
                      </label>

                      <Inputbox
                    // label="Password"
                    type="password"
                    // placeholder="Enter Password"
                    name="password"
                    register={register}
                    isPassword
                  />
                     
                     {errors.password && <span className="errorMessage">{errors.password.message}</span>}

                    </div>
                    <div className="mb-3">
                      <label
                        for="exampleInputPassword1"
                        className="form-label label"
                      >
                        Confirm Password
                      </label>
                      <Inputbox
                    // label="Password"
                    type="password"
                    // placeholder="Enter Password"
                    name="newPassword"
                    register={register}
                    isPassword
                  />
                    
                      {errors.newPassword && <span className="errorMessage">{errors.newPassword.message}</span>}
                    </div>

                  </div>
                  <div className="modal-footer border-0 text-center d-block w-100">
                    {/* <Link to="/new-password-success"> */}
                    <button type="submit" className="btn_dark">
                    Generate Password
                    </button>
                    {/* </Link> */}
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword