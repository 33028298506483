import { useState, useEffect, useRef } from "react";
import Inputbox from "./Inputbox";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Dropdowniconn } from "../assest/SvgImages";
import { Box, Modal } from "@mui/material";
import Changepassword from "./Changepassword";
import {
  FetchDegreetType,
  FetchLanguage,
  FetchMODALITIES,
  FetchPopulationServed,
  GetAgencyBooking,
  GetCategory,
  GetDeliveryMode,
  getDoctorProfile,
  GetInsurance,
  GetServiceParactiseaddress,
  GetSpecialties,
  updateDoctorProfile,
  uploadImage,
} from "../../api/helper";
import { clearEmptyData } from "../../helpers/helper";
import { ShowToast } from "../../helpers/ToastService";
import { setUserDetails } from "../../redux/cookiesReducer";
import { Store } from "../../redux";
import {
  DropDownManu,
  DropDownManuCustom,
  DropDownMultiStringSelect,
} from "./DropDownButton";
import moment from "moment";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { DropDownManuCustomMulti } from "./DropDownButton";

const schema = yup.object().shape({});

const licenseTypes = [
  { _id: "Nursing License", name: "Nursing License" },
  { _id: "Medical License", name: "Medical License" },
  { _id: "Psychology License", name: "Psychology License" },
  { _id: "Bachelor's in Nursing", name: "Bachelor's in Nursing" },
  { _id: "Master's in Psychology", name: "Master's in Psychology" },
  { _id: "Doctorate in Psychiatry", name: "Doctorate in Psychiatry" },
  { _id: "CPR Certification", name: "CPR Certification" },
  { _id: "Mental Health Certification", name: "Mental Health Certification" },
  {
    _id: "Addiction Treatment Certification",
    name: "Addiction Treatment Certification",
  },

  { _id: "Cognitive Behavioral (CBT)", name: "Cognitive Behavioral (CBT)" },
  { _id: "Dialectical Behavioral (DBT)", name: "Dialectical Behavioral (DBT)" },
  {
    _id: "Acceptance & Commitment (ACT)",
    name: "Acceptance & Commitment (ACT)",
  },
  { _id: "Mindfulness-Based (MBCT)", name: "Mindfulness-Based (MBCT)" },
  { _id: "Motivational Interviewing", name: "Motivational Interviewing" },
  { _id: "Psychodynamic Therapy", name: "Psychodynamic Therapy" },
  {
    _id: "Solution Focused Brief Therapy (SFBT)",
    name: "Solution Focused Brief Therapy (SFBT)",
  },
  { _id: "Trauma-Focused Therapy", name: "Trauma-Focused Therapy" },

  { _id: "Individual Therapy", name: "Individual Therapy" },
  { _id: "Group Therapy", name: "Group Therapy" },
  { _id: "Family Therapy", name: "Family Therapy" },
  { _id: "Couples Therapy", name: "Couples Therapy" },
  { _id: "Crisis Intervention", name: "Crisis Intervention" },
  { _id: "Medication Management", name: "Medication Management" },

  { _id: "Elders (65 and above)", name: "Elders (65 and above)" },
  { _id: "Adults (18 to 64)", name: "Adults (18 to 64)" },
  { _id: "Teenagers (13 to 17)", name: "Teenagers (13 to 17)" },
  { _id: "Children (6 to 12)", name: "Children (6 to 12)" },

  { _id: "Anxiety", name: "Anxiety" },
  { _id: "Depression", name: "Depression" },
  { _id: "ADHD", name: "ADHD" },
  { _id: "Bipolar Disorder", name: "Bipolar Disorder" },
  { _id: "PTSD", name: "PTSD" },
  { _id: "Eating Disorders", name: "Eating Disorders" },
  { _id: "Substance Use Disorders", name: "Substance Use Disorders" },
  { _id: "Sleep Disorders", name: "Sleep Disorders" },
  { _id: "Anger Management", name: "Anger Management" },
  { _id: "Grief and Loss", name: "Grief and Loss" },
  { _id: "Trauma", name: "Trauma" },
  { _id: "Relationship Issues", name: "Relationship Issues" },
];
const primaryStatesOfPractice = [
  { _id: "1", name: "Alabama", abbreviation: "AL" },
  { _id: "2", name: "Alaska", abbreviation: "AK" },
  { _id: "3", name: "Arizona", abbreviation: "AZ" },
  { _id: "4", name: "Arkansas", abbreviation: "AR" },
  { _id: "5", name: "California", abbreviation: "CA" },
  { _id: "6", name: "Colorado", abbreviation: "CO" },
  { _id: "7", name: "Connecticut", abbreviation: "CT" },
  { _id: "8", name: "Delaware", abbreviation: "DE" },
  { _id: "9", name: "Florida", abbreviation: "FL" },
  { _id: "10", name: "Georgia", abbreviation: "GA" },
  { _id: "11", name: "Hawaii", abbreviation: "HI" },
  { _id: "12", name: "Idaho", abbreviation: "ID" },
  { _id: "13", name: "Illinois", abbreviation: "IL" },
  { _id: "14", name: "Indiana", abbreviation: "IN" },
  { _id: "15", name: "Iowa", abbreviation: "IA" },
  { _id: "16", name: "Kansas", abbreviation: "KS" },
  { _id: "17", name: "Kentucky", abbreviation: "KY" },
  { _id: "18", name: "Louisiana", abbreviation: "LA" },
  { _id: "19", name: "Maine", abbreviation: "ME" },
  { _id: "20", name: "Maryland", abbreviation: "MD" },
  { _id: "21", name: "Massachusetts", abbreviation: "MA" },
  { _id: "22", name: "Michigan", abbreviation: "MI" },
  { _id: "23", name: "Minnesota", abbreviation: "MN" },
  { _id: "24", name: "Mississippi", abbreviation: "MS" },
  { _id: "25", name: "Missouri", abbreviation: "MO" },
  { _id: "26", name: "Montana", abbreviation: "MT" },
  { _id: "27", name: "Nebraska", abbreviation: "NE" },
  { _id: "28", name: "Nevada", abbreviation: "NV" },
  { _id: "29", name: "New Hampshire", abbreviation: "NH" },
  { _id: "30", name: "New Jersey", abbreviation: "NJ" },
  { _id: "31", name: "New Mexico", abbreviation: "NM" },
  { _id: "32", name: "New York", abbreviation: "NY" },
  { _id: "33", name: "North Carolina", abbreviation: "NC" },
  { _id: "34", name: "North Dakota", abbreviation: "ND" },
  { _id: "35", name: "Ohio", abbreviation: "OH" },
  { _id: "36", name: "Oklahoma", abbreviation: "OK" },
  { _id: "37", name: "Oregon", abbreviation: "OR" },
  { _id: "38", name: "Pennsylvania", abbreviation: "PA" },
  { _id: "39", name: "Rhode Island", abbreviation: "RI" },
  { _id: "40", name: "South Carolina", abbreviation: "SC" },
  { _id: "41", name: "South Dakota", abbreviation: "SD" },
  { _id: "42", name: "Tennessee", abbreviation: "TN" },
  { _id: "43", name: "Texas", abbreviation: "TX" },
  { _id: "44", name: "Utah", abbreviation: "UT" },
  { _id: "45", name: "Vermont", abbreviation: "VT" },
  { _id: "46", name: "Virginia", abbreviation: "VA" },
  { _id: "47", name: "Washington", abbreviation: "WA" },
  { _id: "48", name: "West Virginia", abbreviation: "WV" },
  { _id: "49", name: "Wisconsin", abbreviation: "WI" },
  { _id: "50", name: "Wyoming", abbreviation: "WY" },
];
export const GeneralSettings = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      clinicName: "",
      email: "",
      phone: "",
      specialty: [],
      licenseNumber: "",
      experience: "",
      clinicRequest: "",
      gender: "",
      DOB: "",
      EnumerationDate: "",
      categoryId: "",
    },
  });
  const [showavailibleday, setShowavailibleday] = useState(false);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((store) => store.sliceReducer);
  const [availableDays, setAvailableDays] = useState([]);
  const [clinicData, setclinicData] = useState(null);
  const [specialtydata, setspecialtydata] = useState(null);
  const fileInputRef = useRef(null);
  const [image, setImages] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [defcat, setDefCat] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectlanguage, setselectlanguage] = useState([]);
  const [servicelocation, setservicelocation] = useState([]);
  const [isAgencySelected, setIsAgencySelected] = useState(false);
  const [deleveryMode, setDeleveryMode] = useState([]);
  const [Insurance, setInsurance] = useState([]);
  const [Treatment, setTreatment] = useState([]);
  const [PopulationServed, setPopulationServed] = useState();
  const [Modalities, setModalities] = useState();
  const [Specialties, setSpecialties] = useState();
  const [Specialpopulations, setSpecialpopulations] = useState([]);
  const [Modalitiesdefault, setModalitiesdefault] = useState([])
  const [Populationdefault, setPopulationdefault] = useState([])
  const [Insurancedefault, setInsurancedefault] = useState([])
  const [TreatmentTypedefault, setTreatmentTypedefault] = useState([])
  const [DeliveryModedefault, setDeliveryModedefault] = useState([])
  const [data, setdata] = useState([])
  const [Degreetypedata, setDegreetypedata] = useState([])

  const GetDegreetype = async () => {
    const response = await FetchDegreetType(`?id=${clinicData?.agencyIds[0]?._id}`);
    console.log(response?.data?.data, "data=====");
    setDegreetypedata(response?.data?.data);
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM", // Replace with your Google Maps API key
    libraries: ["places"], // Include 'places' library for autocomplete
  });

  const handlePlaceSelect = async () => {
    const place = autocomplete?.getPlace();
    const addressComponents = place.address_components;

    // Function to get address component by type
    const getAddressComponent = (type) => {
      const component = addressComponents?.find((c) => c.types.includes(type));
      return component ? component.long_name : "";
    };

    // Set country, state, and city
    setValue("address.country", getAddressComponent("country"));
    setValue(
      "address.state",
      getAddressComponent("administrative_area_level_1")
    );
    setValue("address.city", getAddressComponent("locality"));

    // Extract street number, route, sublocality (for sectors), neighborhood, premise (building), establishment (business)
    const streetNumber = getAddressComponent("street_number");
    const route = getAddressComponent("route");
    const sublocality = getAddressComponent("sublocality");
    const neighborhood = getAddressComponent("neighborhood");
    const premise = getAddressComponent("premise"); // Building name or room number
    const establishment = getAddressComponent("establishment"); // Business names

    // Declare fullStreet and build it
    let fullStreet = "";

    if (establishment) {
      fullStreet = establishment;
    } else if (premise) {
      fullStreet = `${premise}, ${route || sublocality || neighborhood}`;
    } else if (streetNumber && route) {
      fullStreet = `${streetNumber} ${route}`;
    } else if (sublocality) {
      fullStreet = sublocality; // For sectors
    } else if (neighborhood) {
      fullStreet = neighborhood; // If categorized as neighborhood
    }

    // Fallback: if no fullStreet found, use locality, state, country
    if (!fullStreet) {
      fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent(
        "administrative_area_level_1"
      )}, ${getAddressComponent("country")}`;
    }

    // Set the full street address in the form
    setValue("address.street", fullStreet);

    // Set the zip code
    setValue("address.zipCode", getAddressComponent("postal_code"));
  };
  const handleChange = (e) => {
    reset({
      phone: e,
    });
  };
  const FetchAddresslist = async () => {
    if (clinicData?.agencyIds[0]?._id) {
      try {
        const response = await GetServiceParactiseaddress(
          clinicData?.agencyIds[0]?._id
        );
        console.log(
          response?.data?.data?.data,
          "helloFetchAddresslistFetchAddresslist"
        );
        setservicelocation(
          Array.isArray(response?.data?.data?.data)
            ? response?.data?.data?.data
            : []
        );
        // setservicelocation(response);       
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getSpecialty();
    getprofile();
    FetchAddresslist();
  }, []);

  const handleSelect = (selectedOptions) => {
    console.log("selectedOptions:", selectedOptions); // This will log the value
    let ids = Array.isArray(selectedOptions)
      ? selectedOptions.map((e) => e?._id)
      : [];
    setDefCat(ids);
    setValue("categoryId", ids);
  };
  const getSpecialty = async () => {
    try {
      const resposne = await GetCategory();
      setspecialtydata(resposne?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getprofile = async () => {
    try {
      const response = await getDoctorProfile();
      Store.dispatch(setUserDetails(response?.data?.data));
      setclinicData(response.data.data);
      setAvailableDays(response.data.data.availableDays.availableDays);
      // console.log("Fetched Clinic Data:", response.data.data); // Log the fetched data
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectlin = (value) => {
    reset({
      LicenseType: value,
    });
  };
  const handlelanguageselect = (value) => {
    reset({
      LanguagesId: value,
    });
  };
  console.log(clinicData, "clinicData========");
  useEffect(() => {}, [
    setSelectedValues,
    setselectlanguage,
    selectedValues,
    selectlanguage,
    setSpecialpopulations,
    setModalitiesdefault,
    setPopulationdefault,
    setInsurancedefault,
    setTreatmentTypedefault,
    setDeliveryModedefault,
    Specialpopulations,
    Modalitiesdefault,
    Populationdefault,
    Insurancedefault,
    TreatmentTypedefault,
    DeliveryModedefault,
  ]);
  useEffect(() => {
    if (clinicData) {
      const categoryIds = clinicData?.categoryId?.map((e) => e._id) || [];
      // const defaultVal = clinicData.degreeType
      //   ? clinicData.degreeType?.split(",").map((item) => item.trim())
      //   : [];
      const defaultVal=clinicData?.degreeTypeId?.map((e) => e._id) || [];
        const defaultlanguage = clinicData?.LanguagesId?.map((e) => e._id) || [];
      // const defaultlanguage = clinicData.LanguagesId
      //   ? clinicData.LanguagesId?.split(",").map((item) => item.trim())
      //   : [];
      const defaultspecial = clinicData?.Specialties?.map((e) => e._id) || [];
      const defaultmodalities = clinicData?.Modalities?.map((e) => e._id) || [];
      const defaultpopluationn = clinicData?.PopulationServed?.map((e) => e._id) || [];
      const   defaultinsurance=clinicData?.Insurance?.map((e) => e._id) || [];
      const defaulttreatment = clinicData?.ServiceType?.map((e) => e._id) || [];
      const  defaultdelivery= clinicData?.DeliveryMode?.map((e) => e._id) || [];
      setDefCat(categoryIds);
      setSelectedValues(defaultVal);
      setselectlanguage(defaultlanguage);
      setSpecialpopulations(defaultspecial);
      setModalitiesdefault(defaultmodalities)
      setPopulationdefault(defaultpopluationn)
      setInsurancedefault(defaultinsurance)
      setTreatmentTypedefault(defaulttreatment)
      setDeliveryModedefault(defaultdelivery)
      reset({
        clinicName: `${clinicData.firstName || ""} ${
          clinicData.lastName || ""
        }`.trim(),
        email: clinicData.email || "",
        phone: clinicData.phone || "",
        specialty: clinicData.specialty || "",
        licenseNumber: clinicData.licenseNumber || "",
        experience: clinicData.experience || "",
        clinicRequest: clinicData.clinicRequest || "",
        categoryId: categoryIds,
        gender: clinicData.gender || "",
        DOB: moment(clinicData?.DOB).format("YYYY-MM-DD") || "",
        ServiceLocationId: clinicData?.ServiceLocationId?.name || "",
        ProviderNPI: clinicData?.ProviderNPI || "",
        primaryStateOfPractice: clinicData?.primaryStateOfPractice || "",
        PrimaryLicenseNo: clinicData?.PrimaryLicenseNo || "",

        // /////////////////////////////
        ServiceType: clinicData?.ServiceType || "",
        DeliveryMode: clinicData?.DeliveryMode || "",
        PopulationServed: clinicData?.PopulationServed || "",
        Modalities: clinicData?.Modalities || "",
        Insurance: clinicData?.Insurance || "",
        Specialties: clinicData?.Specialties || "",

        EnumerationDate:
          moment(clinicData?.EnumerationDate).format("YYYY-MM-DD") || "",
        info: clinicData?.info || "",
        LanguagesId: clinicData?.LanguagesId || "",

        // LicenseType: defaultValues,
        address: {
          street: clinicData?.address?.street || "",
          city: clinicData?.address?.city || "",
          state: clinicData?.address?.state || "",
          country: clinicData?.address?.country || "",
          zipCode: clinicData?.address?.zipCode || "",
        },
      });
      // console.log('Default values set from clinicData:', defaultValues);
      // setDefCat(clinicData?.categoryId?.map((e) => e._id))
      //  clinicData?.categoryId?.map((e) => e._id)
    }
  }, [clinicData, reset]);
// console.log(clinicData?.agencyIds[0]?._id,)
  const DeliveryMode = async () => {

    try {
      const response = await GetDeliveryMode(`?id=${clinicData?.agencyIds[0]?._id}`);
      setDeleveryMode(response?.data?.data || []);
      console.log(response?.data?.data,"response?.data?.data===GetDeliveryMode")
    } catch (error) {
      console.log(error);
    }
  };

  const InsuranceType = async () => {
   
    try {
      const response = await GetInsurance(`?id=${clinicData?.agencyIds[0]?._id}`);
      let resData = response?.data?.data
      resData = resData?.map((e)=>e.adminInsurenceId ? {...e, name:e.adminInsurenceId.PayerName}:e)
      setInsurance(resData);
      // setInsurance(response?.data?.data);
    } catch (error) {
      console.log(error, "===e");
    }
  };

  const TreatmentTypeslist = async () => {
 
    try {
      const response = await GetAgencyBooking(`?id=${clinicData?.agencyIds[0]?._id}`);
      setTreatment(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const PopulationServedGet = async () => {

    try {
      const response = await FetchPopulationServed(
        `?id=${clinicData?.agencyIds[0]?._id}`
      );
      console.log(response?.data?.data, "esponse?.data?.data======");
      setPopulationServed(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(clinicData?.agencyIds[0]?._id,"clinicData?.agencyIds[0]?._id===")

  const GetLanguage = async () => {
    try {
    const response = await FetchLanguage(`?id=${clinicData?.agencyIds[0]?._id}`);
    console.log(response?.data?.data, "data=====language");
    setdata(response?.data?.data);
    }catch (error){
      console.log(error);
    }
  };

  
 
  const getModalities = async () => {
   
    try {
      const response = await FetchMODALITIES(`?id=${clinicData?.agencyIds[0]?._id}`);
      setModalities(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetSpecialtiesList = async () => {
   
    try {
      const response = await GetSpecialties(`?id=${clinicData?.agencyIds[0]?._id}`);

      setSpecialties(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(clinicData?.agencyId, "clinicData?.agencyId===");


  useEffect(()=>{
    if (clinicData?.agencyIds[0] && clinicData?.agencyIds[0]?._id ) {
      console.log(clinicData?.agencyIds[0]?._id,'====clinicData?.agencyIds[0]?._i>>>>>>>>')
      GetLanguage();
      getModalities();
      GetSpecialtiesList();
      TreatmentTypeslist();
      PopulationServedGet();
      DeliveryMode();
      InsuranceType();
      GetDegreetype()
    }
  },[clinicData])

  // const selectTreatmentType = (selectedOptions) => {
  //   let ids = selectedOptions?.map((e) => e?._id);
  //   setValue("ServiceType", ids, { shouldValidate: true });
  // };

  // const selectDeliveryMode = (selectedOptions) => {
  //   let ids = selectedOptions?.map((e) => e?._id);
  //   setValue("DeliveryMode", ids, { shouldValidate: true });
  // };
  const selectDeliveryMode = (value) => {
    reset({
      DeliveryMode: value,
    });
  };

  const selectTreatmentType = (value) => {
    reset({
      ServiceType: value,
    });
  };

  const selectPopulationServed = (value) => {
    reset({
      PopulationServed: value,
    });
  };
  const selectModalities = (value) => {
    reset({
      Modalities: value,
    });
  };
  //   let ids = selectedOptions?.map((e) => e?._id);
  //   setValue("Modalities", ids, { shouldValidate: true });
  // };

  // const selectInsurance = (selectedOptions) => {
  //   let ids = selectedOptions?.map((e) => e?._id);
  //   setValue("Insurance", ids, { shouldValidate: true });
  // };

  // const selectSpecialties = (selectedOptions) => {
  //   let ids = selectedOptions?.map((e) => e?._id);
  //   setValue("Specialties", ids, { shouldValidate: true });
  // };
  const selectInsurance = (value) => {
    reset({
      Insurance: value,
    });
  }
  const selectSpecialties = (value) => {
    reset({
      Specialties: value,
    });
  };
  const handleClose = () => {
    setShowavailibleday(false);
  };

  const splitFullName = (fullName) => {
    const nameParts = fullName.trim().split(" ");

    if (nameParts.length > 2) {
      return {
        firstName: nameParts.slice(0, -1).join(" "),
        lastName: nameParts[nameParts.length - 1],
      };
    }

    return {
      firstName: nameParts[0] || "",
      lastName: nameParts[1] || "",
    };
  };

  const onSubmit = async (data) => {
    if (data.clinicName) {
      const { firstName, lastName } = splitFullName(data.clinicName);
      data.firstName = firstName;
      data.lastName = lastName;
      delete data.clinicName;
    }
    if (image) {
      data.image = image;
    }
    if (availableDays) {
      data.availableDays = availableDays;
    }
    const newData = clearEmptyData(data);
    console.log("Form Data:", newData);

    try {
      const response = await updateDoctorProfile(newData);

      ShowToast(response.data.message, "success");
      getprofile();
    } catch (error) {
      console.log(error, "===error");
    }
  };
  const toggleChangePassword = () => {
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };

  const clickImageData = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const imagePicker = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        console.log(response?.data?.data[0]?.URL, "====response");
        setImages(response?.data?.data[0]?.URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error, "===");
      }
    }
  };

  const days = [
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
    { name: "Sunday" },
  ];
  const handleSave = () => {
    console.log(availableDays);
    handleClose();
  };
  const handleToggle = (dayName) => {
    setAvailableDays((prev) => {
      const isActive = prev.some((day) => day.day === dayName);
      if (isActive) {
        return prev.filter((day) => day.day !== dayName);
      } else {
        return [
          ...prev,
          { day: dayName, shifts: [{ startTime: "10:00", endTime: "18:00" }] },
        ];
      }
    });
  };

  const handleTimeChange = (dayName, newStartTime, newEndTime) => {
    setAvailableDays((prev) =>
      prev?.map((day) => {
        if (day.day === dayName) {
          return {
            ...day,
            shifts: [{ startTime: newStartTime, endTime: newEndTime }],
          };
        }
        return day;
      })
    );
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "70vh", // Updated height to 70% of the viewport height
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto", // Enables scrolling inside the modal box
  };
  const handleavailibledays = () => {
    setShowavailibleday(true);
  };
  if (!isLoaded) return <div>Loading...</div>;


  return (
    <div>
      {isLoading ? null : clinicData ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              className="Clinical_Settingstwo"
              onClick={toggleChangePassword}
            >
              <img
                src={require("../assest/Images/change_pass.png")}
                style={{ marginRight: 10 }}
              />
              Change Password{" "}
            </div>
          </div>
          <div className="flex-row" style={{ width: "100%" }}>
            {clinicData && clinicData?.image != null ? (
              <img
                src={image ? image : clinicData?.image}
                style={{ height: 105, width: 105, borderRadius: 60 }}
                height={60}
                width={60}
              />
            ) : (
              <img
                src={
                  image
                    ? image
                    : "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                }
                style={{ height: 105, width: 105, borderRadius: 60 }}
              />
            )}

            <div style={{ marginLeft: 70, width: "80%" }}>
              <div className="clinicnametxtt">Full Name</div>
              <input type="text" {...register("clinicName")} />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              marginTop: 20,
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            <div
              style={{
                borderWidth: 1,
                borderColor: "#011434",
                borderStyle: "solid",
                padding: 10,
                borderRadius: 30,
              }}
              className="Contact_Information_hhh"
              onClick={clickImageData}
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={imagePicker}
                style={{ display: "none" }}
              />
              Change Picture
            </div>
          </div>
          <div>
            <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
                <div className="Email_Address_txt">Email Address</div>
                <input
                  {...register("email")}
                  placeholder="clinic@gmail.com"
                  disabled
                />
                {errors.email && <p>{errors.email.message}</p>}
              </div>
              <div style={{ width: "47%" }}>
                <div
                  className="Email_Address_txt"
                  style={{ display: "flex", marginBottom: -20 }}
                >
                  Phone Number
                </div>
                {/* <input {...register("phone")} placeholder="9876543210" /> */}
                {/* {errors.phone && <p>{errors.phone.message}</p>} */}
                <div className="form_div_two">
                  <Inputbox
                    style={{ width: "100%", height: 48 }}
                    type="text"
                    placeholder="Enter Number"
                    name="phone"
                    value={clinicData?.phone}
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <span className="errorMessage">{errors.phone.message}</span>
                  )}
                </div>
              </div>
            </div>

            <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
                <div className="Email_Address_txt" style={{ marginBottom: 2 }}>
                  Specialty
                </div>

                {/* <Controller
                name="categoryId"
                {...register("categoryId")}
                control={control}
                render={({ field }) => (
                  <select
                    {...field}
                    style={{
                      height: 45,
                      width: "100%",
                      borderRadius: 4,
                      paddingLeft: 10,
                      fontSize: 17,
                    }}
                  >
                    <option value="" selected disabled>
                      Select Options
                    </option>
                    {specialtydata &&
                      specialtydata?.map((e) => (
                        <option key={e?._id} value={e?._id}>
                          {e?.name}
                        </option>
                      ))}
                  </select>
                )}
              /> */}

                <DropDownManuCustomMulti
                  // label="Specialty"
                  placeholder="Select option"
                  options={
                    specialtydata &&
                    specialtydata?.map((e) => ({
                      _id: e._id,
                      name: e.name,
                    }))
                  }
                  name="categoryId"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={handleSelect}
                  defaultValue={defcat}
                />

                {errors.categoryId && <p>{errors.categoryId.message}</p>}
              </div>
              {/* <div style={{ width: "47%" }}>
                <div className="Email_Address_txt">License Number</div>
                <input
                  {...register("licenseNumber")}
                  placeholder="asdfg987654"
                />
                {errors.licenseNumber && <p>{errors.licenseNumber.message}</p>}
              </div> */}
              <div style={{ width: "47%" }}>
                <Inputbox
                  label="Short Biography"
                  placeholder="Enter Short Biography"
                  name="info"
                  register={register}
                  style={{ borderColor: errors.info ? "red" : "initial" }}
                />
                {errors.info && (
                  <p className="errorMessage">{errors.info.message}</p>
                )}
              </div>
            </div>
            <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
                <div className="Email_Address_txt">Experience (Years)</div>
                <input {...register("experience")} placeholder="5 Years" />
                {errors.experience && <p>{errors.experience.message}</p>}
              </div>
              <div style={{ width: "47%" }}>
                <div className="Email_Address_txt">Clinic Association </div>
                <input
                  value={
                    clinicData?.agencyIds?.length > 0
                      ? `${clinicData?.agencyIds[0].firstName} ${clinicData?.agencyIds[0].lastName}`
                      : "NA"
                  }
                  disabled
                  placeholder="Request"
                />
                {errors.clinicRequest && <p>{errors.clinicRequest.message}</p>}
              </div>
            </div>

            <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
                <Inputbox
                  label="Provider NPI"
                  type="text"
                  placeholder="Enter Provider NPI"
                  name="ProviderNPI"
                  register={register}
                />
                {errors.ProviderNPI && (
                  <span className="errorMessage">
                    {errors.ProviderNPI.message}
                  </span>
                )}
              </div>
              <div style={{ width: "47%" }}>
                <DropDownManuCustom
                  label="Provider Primary Pratice Address"
                  placeholder="Select Provider Primary Pratice Address"
                  options={servicelocation}
                  name="ServiceLocationId"
                  dropdownicon
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  disabled={!clinicData?.agencyIds[0]?._id}
                  defaultValue={clinicData?.ServiceLocationId[0]?.name}
                />
                {errors.ServiceLocationId && (
                  <span className="errorMessage">
                    {errors.ServiceLocationId.message}
                  </span>
                )}
              </div>
            </div>
            {/* <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
                <Inputbox
                  label="Primary State License Number"
                  type="text"
                  placeholder="Enter Primary State License Number"
                  name="PrimaryLicenseNo"
                  register={register}
                />
                {errors.PrimaryLicenseNo && (
                  <span className="errorMessage">
                    {errors.PrimaryLicenseNo.message}
                  </span>
                )}
              </div>
              <div style={{ width: "47%" }}>
                <DropDownManuCustom
                  label="Primary State Of Practice"
                  placeholder="Select option"
                  options={primaryStatesOfPractice}
                  name="primaryStateOfPractice"
                  defaultValue={clinicData?.primaryStateOfPractice}
                  dropdownicon
                  register={register}
                />

                {errors.primaryStateOfPractice && (
                  <span className="errorMessage">
                    {errors.primaryStateOfPractice.message}
                  </span>
                )}
              </div>
            </div> */}

            {/* <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
              <Inputbox
                label="Enumeration Date"
                type="date"
                name="EnumerationDate"
                register={register}
                style={{
                  borderColor: errors.EnumerationDate ? "red" : "initial",
                }}
              />
              {errors.EnumerationDate && (
                <p className="errorMessage">{errors.EnumerationDate.message}</p>
              )}
            </div>
              <div style={{ width: "47%" }}>
              <Inputbox
                label="Info"
                placeholder="Enter info"
                name="info"
                register={register}
                style={{ borderColor: errors.info ? "red" : "initial" }}
              />
              {errors.info && (
                <p className="errorMessage">{errors.info.message}</p>
              )}
            </div>
</div> */}
            <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
              
                <DropDownManuCustomMulti
                  label="Language"
                  placeholder="Select option"
                  options={data}
                  name="LanguagesId"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  // onSelect={(value) => setValue("language", value)}
                  onSelect={handlelanguageselect}
                  defaultValue={selectlanguage}
                />
                {errors.language && (
                  <p className="errorMessage">{errors.language.message}</p>
                )}
              </div>

              <div style={{ width: "47%" }}>
                <DropDownManuCustomMulti
                  label="Degree Type"
                  placeholder="Select option"
                  options={Degreetypedata}
                  name="degreeTypeId"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={handleSelectlin}
                  defaultValue={selectedValues}
                />
                {/* <DropDownMultiStringSelect
      label="Credentials Type"
      placeholder="Select option"
      options={licenseTypes} // Options passed to the select component
      name="LicenseType"
      register={register}
      id="_id"
      clearErrors={clearErrors}
      onSelect={handleSelectlin} // Handle the selection logic
      defaultValue={Specialpopulations} // Display the selected values
    /> */}
              </div>
            </div>

            <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
                <DropDownManu
                  label="Gender"
                  placeholder="Select option"
                  options={[
                    { name: "Male" },
                    { name: "Female" },
                    { name: "Other" },
                  ]}
                  name="gender"
                  dropdownicon
                  register={register}
                />

                {errors.gender && (
                  <span className="errorMessage">{errors.gender.message}</span>
                )}
              </div>

              <div style={{ width: "47%", marginTop: -20 }}>
                <Inputbox
                  label="Date of Birth"
                  type="date"
                  name="DOB"
                  register={register}
                  style={{
                    borderColor: errors.DOB ? "red" : "initial",
                    height: 40,
                  }}
                  // max={new Date().toISOString().split('T')[0]}
                />
                {errors.DOB && (
                  <p className="errorMessage">{errors.DOB.message}</p>
                )}
              </div>
            </div>
            <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
                <DropDownManuCustomMulti
                  label="Specialties"
                  placeholder="Select option"
                  options={Specialties}
                  name="Specialties"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={selectSpecialties}
                  defaultValue={Specialpopulations}
                />

                {errors.SpecialPopulationExpertise && (
                  <div className="errorMessage" style={{ marginTop: 5 }}>
                    {errors.SpecialPopulationExpertise.message}
                  </div>
                )}
              </div>
              <div style={{ width: "47%" }}>
                <DropDownManuCustomMulti
                  label="Modalities (Treatment Approaches)"
                  placeholder="Select option"
                  options={Modalities}
                  name="Modalities"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={selectModalities}
                  defaultValue={Modalitiesdefault}
                />

                {errors.Modalities && (
                  <div className="errorMessage">
                    {errors.Modalities.message}
                  </div>
                )}
              </div>
            </div>

            <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
                <DropDownManuCustomMulti
                  label="Population (Ages Served)"
                  placeholder="Select option"
                  options={PopulationServed}
                  name="PopulationServed"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={selectPopulationServed}
                  defaultValue={Populationdefault}
                />

                {errors.Population && (
                  <div className="errorMessage">
                    {errors.Population.message}
                  </div>
                )}
              </div>
              <div style={{ width: "47%" }}>
                <DropDownManuCustomMulti
                  label="Insurance Currently Accepted"
                  placeholder="Select option"
                  options={Insurance}
                  name="Insurance"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={selectInsurance}
                  defaultValue={Insurancedefault}
                />

                {errors.Insurance && (
                  <div className="errorMessage">{errors.Insurance.message}</div>
                )}
              </div>
            </div>

            <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
                <DropDownManuCustomMulti
                  label="Treatment Type (Service Type)"
                  placeholder="Select option"
                  options={Treatment}
                  name="ServiceType"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={selectTreatmentType}
                  defaultValue={TreatmentTypedefault}
                />

                {errors.ServiceType && (
                  <div className="errorMessage">
                    {errors.ServiceType.message}
                  </div>
                )}
              </div>
              <div style={{ width: "47%" }}>
                <DropDownManuCustomMulti
                  label="Delivery Mode"
                  placeholder="Select option"
                  options={deleveryMode}
                  name="DeliveryMode"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={selectDeliveryMode}
                  defaultValue={DeliveryModedefault}
                />
                {errors.DeliveryMode && (
                  <div className="errorMessage">
                    {errors.DeliveryMode.message}
                  </div>
                )}
              </div>
            </div>
            <div style={{ width: "96%", marginTop: 30, marginBottom: 20 }}>
              <div className="Email_Address_txt">Available Days</div>
              {/* <input
            {...register("availableDays")}
            placeholder="Monday, Tuesday, Wednesday, Friday"
          /> */}
              <div
                className="Available_Days_divv"
                onClick={handleavailibledays}
              >
                <div>
                  {" "}
                  {(availableDays?.length > 0 && (
                    <div className="selected-days">
                      {availableDays?.map((e) => e.day).join(", ")}
                    </div>
                  )) ||
                    clinicData?.availableDays[0]?.availableDays
                      ?.map((e) => e.day)
                      .join(", ") ||
                    ","}{" "}
                </div>
                <Dropdowniconn />
              </div>
              {errors.availableDays && <p>{errors.availableDays.message}</p>}
            </div>
            <Autocomplete
              onLoad={(autocompleteInstance) =>
                setAutocomplete(autocompleteInstance)
              }
              onPlaceChanged={handlePlaceSelect}
            >
              <div style={{ width: "96%", marginTop: 30, marginBottom: 20 }}>
                <div className="Email_Address_txt">Street</div>
                <input
                  {...register("address.street")}
                  placeholder="51A New Birmingham Road Oldbury, B692JQ"
                />
                {errors.street && <p>{errors.street.message}</p>}
              </div>
            </Autocomplete>
            <div
              className="space-div"
              style={{ width: "100%", paddingRight: "4%" }}
            >
              <div style={{ width: "47%" }}>
                <div className="Email_Address_txt">City</div>
                <input
                  {...register("address.city")}
                  placeholder="Select City"
                />
                {errors.city && <p>{errors.city.message}</p>}
              </div>
              <div style={{ width: "47%" }}>
                <div className="Email_Address_txt">State</div>
                <input
                  {...register("address.state")}
                  placeholder="Select State"
                />
                {errors.state && <p>{errors.state.message}</p>}
              </div>
            </div>
            <div
              className="space-div"
              style={{
                width: "100%",
                paddingRight: "4%",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <div style={{ width: "47%" }}>
                <div className="Email_Address_txt">Country</div>
                <input
                  {...register("address.country")}
                  placeholder="Select Country"
                />
                {errors.country && <p>{errors.country.message}</p>}
              </div>
              <div style={{ width: "47%" }}>
                <div className="Email_Address_txt">Zip Code</div>
                <input {...register("address.zipCode")} placeholder="12345" />
                {errors.zipCode && <p>{errors.zipCode.message}</p>}
              </div>
            </div>
            <div
              style={{
                alignSelf: "center",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              <div className="cancelButton">Cancel</div>
              <button className="savebuttonn" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div>No data available</div>
      )}
      {isChangePasswordVisible && (
        <Changepassword toggleChangePassword={toggleChangePassword} />
      )}
      <Modal
        open={showavailibleday}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Select your opening days</h3>
          {days?.map((day) => {
            const isActive = availableDays?.some((d) => d.day === day.name);
            const dayData = availableDays?.find((d) => d.day === day.name);
            const startTime = dayData ? dayData?.shifts[0].startTime : "10:00";
            const endTime = dayData ? dayData?.shifts[0].endTime : "18:00";

            return (
              <DayItem
                key={day.name}
                day={day}
                isActive={isActive}
                onToggle={() => handleToggle(day.name)}
                startTime={startTime}
                endTime={endTime}
                onTimeChange={handleTimeChange}
              />
            );
          })}
          <div className="logout-buttons" style={{ marginTop: 20 }}>
            <button className="cancel-button" onClick={handleClose}>
              Cancel
            </button>
            <button className="confirm-button" onClick={handleSave}>
              Save
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const DayItem = ({
  day,
  isActive,
  onToggle,
  startTime,
  endTime,
  onTimeChange,
}) => {
  return (
    <div className={`unique-day-item ${isActive ? "unique-active-day" : ""}`}>
      <div className="unique-day-header">
        <span>{day.name}</span>
        <label className="unique-switch">
          <input type="checkbox" checked={isActive} onChange={onToggle} />
          <span className="unique-slider unique-round"></span>
        </label>
      </div>

      {isActive && (
        <div className="unique-time-selector">
          <div className="unique-time-group">
            <div className="unique-time-input-wrapper">
              <input
                type="time"
                className="unique-time-input"
                value={startTime}
                onChange={(e) =>
                  onTimeChange(day.name, e.target.value, endTime)
                }
              />
            </div>
            <div className="unique-time-input-wrapper">
              <input
                type="time"
                className="unique-time-input"
                value={endTime}
                onChange={(e) =>
                  onTimeChange(day.name, startTime, e.target.value)
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
