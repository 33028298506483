import React, { useEffect, useMemo, useState } from "react";
import {
  DropDownManuCustom,
  DropDownManuCustomMulti,
  DropDownMultiStringSelect,
} from "../../Components/DropDownButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  AddAddressbyagency,
  Deleteserviceaddress,
  GetPlaceservice,
  Getserviceaddress,
  GetServiceParactiseaddress,
  updateAgencyDoctor,
} from "../../../api/helper";
import Inputbox from "../../Components/Inputbox";
import { ShowToast } from "../../../helpers/ToastService";

const validationSchema = Yup.object().shape({});

const primaryStatesOfPractice = [
  { _id: "1", name: "West Virginia", abbreviation: "WV" },
  { _id: "2", name: "Wisconsin", abbreviation: "WI" },
  { _id: "3", name: "Wyoming", abbreviation: "WY" },

  { _id: "4", name: "Arkansas", abbreviation: "AR" },
  { _id: "5", name: "California", abbreviation: "CA" },
  { _id: "6", name: "Colorado", abbreviation: "CO" },
  { _id: "7", name: "Connecticut", abbreviation: "CT" },
  { _id: "8", name: "Delaware", abbreviation: "DE" },
  { _id: "9", name: "Florida", abbreviation: "FL" },
  { _id: "10", name: "Georgia", abbreviation: "GA" },
  { _id: "11", name: "Hawaii", abbreviation: "HI" },
  { _id: "12", name: "Idaho", abbreviation: "ID" },
  { _id: "13", name: "Illinois", abbreviation: "IL" },
  { _id: "14", name: "Indiana", abbreviation: "IN" },
  { _id: "15", name: "Iowa", abbreviation: "IA" },
  { _id: "16", name: "Kansas", abbreviation: "KS" },
  { _id: "17", name: "Kentucky", abbreviation: "KY" },
  { _id: "18", name: "Louisiana", abbreviation: "LA" },
  { _id: "19", name: "Maine", abbreviation: "ME" },
  { _id: "20", name: "Maryland", abbreviation: "MD" },
  { _id: "21", name: "Massachusetts", abbreviation: "MA" },
  { _id: "22", name: "Michigan", abbreviation: "MI" },
  { _id: "23", name: "Minnesota", abbreviation: "MN" },
  { _id: "24", name: "Mississippi", abbreviation: "MS" },

  { _id: "25", name: "Alabamaa", abbreviation: "AK" },
  { _id: "26", name: "Montana", abbreviation: "MT" },
  { _id: "27", name: "Nebraska", abbreviation: "NE" },
  { _id: "28", name: "Nevada", abbreviation: "NV" },
  { _id: "29", name: "New Hampshire", abbreviation: "NH" },
  { _id: "30", name: "New Jersey", abbreviation: "NJ" },
  { _id: "31", name: "New Mexico", abbreviation: "NM" },
  { _id: "32", name: "New York", abbreviation: "NY" },
  // 
  { _id: "33", name: "Alabama", abbreviation: "AL" },
  { _id: "34", name: "North Dakota", abbreviation: "ND" },
  { _id: "35", name: "Ohio", abbreviation: "OH" },
  { _id: "36", name: "Oklahoma", abbreviation: "OK" },
  { _id: "37", name: "Oregon", abbreviation: "OR" },
  { _id: "38", name: "Pennsylvania", abbreviation: "PA" },
  { _id: "39", name: "Rhode Island", abbreviation: "RI" },
  { _id: "40", name: "South Carolina", abbreviation: "SC" },
  { _id: "41", name: "South Dakota", abbreviation: "SD" },
  { _id: "42", name: "Tennessee", abbreviation: "TN" },
  { _id: "43", name: "Texas", abbreviation: "TX" },
  { _id: "44", name: "Utah", abbreviation: "UT" },
  { _id: "45", name: "Vermont", abbreviation: "VT" },
  { _id: "46", name: "Virginia", abbreviation: "VA" },
  { _id: "47", name: "Washington", abbreviation: "WA" },
  { _id: "48", name: "North Carolina", abbreviation: "NC" },
  { _id: "49", name: "Missouri", abbreviation: "MO" },
  { _id: "50", name: "Alabamaaa", abbreviation: "AZ" },
];
const requirementTypedata = [
  {
    _id: "Configure Internal System Telehealth",
    name: "Configure Internal System Telehealth",
  },
  { _id: "Configure Zoom", name: "Configure Zoom" },
  { _id: "Configure Google Meet", name: "Configure Google Meet" },
  { _id: "Configure Microsoft Teams", name: "Configure Microsoft Teams" },
];
const Practicelocations = ({ info, getDteails }) => {
  const [addressData, setaddressData] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [Placedata, setPlacedata] = useState(null);
  const [servicelocation, setservicelocation] = useState([]);

  const [Specialpopulations, setSpecialpopulations] = useState([]);


  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      ServiceLocationId: info?.ServiceLocationId || '',
      primaryStateOfPractice: info?.primaryStateOfPractice || '',
      stateOfPractice: info?.stateOfPractice || [],
      TelehealthURL: info?.TelehealthURL || '',
      TelehealthPlatform: info?.TelehealthPlatform || '',
    },
  });
  useEffect(() => {
    if (info) {
      setValue("ServiceLocationId", info.ServiceLocationId || '');
      setValue("primaryStateOfPractice", info.primaryStateOfPractice || '');
      // setValue("stateOfPractice", Specialpopulations || []);
      setValue("TelehealthURL", info.TelehealthURL || '');
      setValue("TelehealthPlatform", info.TelehealthPlatform || '');
      setSpecialpopulations(info.stateOfPractice||[])
    }
  }, [info, setValue, Specialpopulations]);


  const PraticeAddress = async () => {
    try {
      const response = await GetServiceParactiseaddress(profiledata._id);
      setservicelocation(
        Array.isArray(response?.data?.data?.data)
          ? response?.data?.data?.data
          : []
      );
    } catch (error) {
      console.log(error);
    }
  };
  const Fetchclinic = async () => {
    try {
      const response = await GetPlaceservice();
      const data = response?.data?.data?.map((e) => {
        return {
          name: `${e.ServiceName} `,
          ...e,
        };
      });
      setPlacedata(data);
    } catch (error) {
      console.log(error);
    }
  };
  const FetchAddresslist = async () => {
    const data = {
      id: profiledata?._id,
    };
    try {
      const response = await Getserviceaddress(data);
      setaddressData(response?.data?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    FetchAddresslist();
    Fetchclinic();
    PraticeAddress();
  }, []);


  const onSubmit = async (data) => {
    data.id = info?._id;
    try {
      const response = await updateAgencyDoctor(data);
      ShowToast(response.data.message, "success");
      getDteails();
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectData = (value) => {
    let ids = value?.map((e) => e?.name);
    setValue("stateOfPractice", ids, { shouldValidate: true });
  }




  const selectedAddress = useMemo(() => {
    if (!info?.ServiceLocationId?.[0] || !addressData.length) return null;
    return addressData.find((addr) => addr._id === info.ServiceLocationId[0]);
  }, [info, addressData]);
  return (
    <div className="practice-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="practice-locations-section">
          <h4
            className="practice-locations-title"
            style={{ textAlign: "left" }}
          >
            Practice Locations
          </h4>
          <ul className="practice-locations-list">
            <div className="practice-location-item">
              {selectedAddress ? (
                <div className="address-details">
                  <span>
                    {selectedAddress.name +
                      " ," +
                      selectedAddress.city +
                      "," +
                      selectedAddress.state +
                      "," +
                      selectedAddress.country}
                  </span>
                </div>
              ) : (
                <p>No address found for the selected Service Location.</p>
              )}

              <div className="practice-location-actions">
                {/* <button
                      onClick={() => handleDelete()}
                      style={deleteButtonStyle}
                    >
                      Delete
                    </button> */}
              </div>
            </div>
          </ul>
          <div
            className="practice-location-actions"
            style={{ alignSelf: "flex-start", display: "flex" }}
          >
            {/* <div className="practice-edit-btn" onClick={openModal}>
              Add address
            </div> */}
            {/* <button className="practice-archive-btn">
              Show Archived Offices
            </button> */}
          </div>
        </div>
        <div className="practice-locations-section">
          <div className="input-row">
            <DropDownManuCustom
              label="Provider Primary Pratice Address"
              placeholder="Select Practice Location"
              options={servicelocation}
              name="ServiceLocationId"
              dropdownicon
              register={register}
              id="_id"
              clearErrors={clearErrors}
              defaultValue={info?.ServiceLocationId}
            />
            <div style={{ width: "40px" }} />
            <DropDownManuCustom
              label="Primary State Of Practice"
              placeholder="Select option"
              options={primaryStatesOfPractice}
              name="primaryStateOfPractice"
              defaultValue={info?.primaryStateOfPractice}
              dropdownicon
              register={register}
            />
            <div style={{ width: "40px" }} />

            <DropDownManuCustomMulti
              label="Other States of Practice"
              placeholder="Select option"
              options={primaryStatesOfPractice}
              name="stateOfPractice"
              register={register}
              id="name"
              clearErrors={clearErrors}
              onSelect={onSelectData}
              defaultValue={info?.stateOfPractice||[]}
            />
          </div>
        </div>
        <div className="telehealth-section">
          <h4 className="telehealth-title" style={{ textAlign: "left" }}>
            Virtual (Telehealth)
          </h4>
          <p className="telehealth-description" style={{ textAlign: "left" }}>
            If you use the same telehealth link for all of your clients, add it
            here and we’ll share it with them ahead of their session.
          </p>
          <div className="telehealth-input-container">
            <label htmlFor="telehealth-url" className="telehealth-input-label">
              Telehealth URL
            </label>
            <input
              id="telehealth-platform"
              type="text"
              placeholder="Telehealth Platform"
              className="telehealth-input"
              name="TelehealthURL"
              {...register("TelehealthURL", { required: false })}
            />
          </div>
          <p className="telehealth-note" style={{ textAlign: "left" }}>
            For sessions, your telehealth platform MUST be:
          </p>
          <ul className="telehealth-requirements">
            <li style={{ textAlign: "left" }}>HIPAA compliant</li>
            <li style={{ textAlign: "left" }}> Secured via HTTPS</li>
            <li style={{ textAlign: "left" }}>
              Set up with a default waiting room to ensure clients never overlap
            </li>
          </ul>
          <button
            className="practice-edit-btn"
            style={{ alignSelf: "flex-start", display: "flex" }}
          >
            Learn how to set up a compliant video link here
          </button>
          <DropDownManuCustom
            label="Configure Telehealth Platform"
            placeholder="Configure Telehealth Platform"
            options={requirementTypedata}
            name="TelehealthPlatform"
            dropdownicon
            register={register}
            clearErrors={clearErrors}
            defaultValue={info?.TelehealthPlatform}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 30,
          }}
        >
          <div className="cancelButton">Cancel</div>
          <button type="submit" className="savebuttonn" style={{ width: 340 }}>
            Save
          </button>
        </div>
      </form>

    </div>
  );
};

export default Practicelocations;
