import React from 'react'

const Consents = () => {
  return (
    <div className="client-history">
    <h2 className="client-history__title">Consents</h2>
    <table className="client-history__table">
      <thead>
        <tr>
          <th className="client-history__header">Consent Type	</th>
          <th className="client-history__header">Status</th>
          <th className="client-history__header">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="client-history__cell">Consent for Treatment	</td>
          <td className="client-history__cell">
          Granted
          </td>
          <td className="client-history__cell client-history__actions">
            <a  className="client-history__link">View</a>
            <span> | </span>
            <a  className="client-history__link">Edit</a>
          </td>
        </tr>
        <tr>
          <td className="client-history__cell">HIPAA Consent	</td>
          <td className="client-history__cell">
          Pending	
          </td>
          <td className="client-history__cell client-history__actions">
            <a  className="client-history__link">View</a>
            <span> | </span>
            <a  className="client-history__link">Edit</a>
          </td>
        </tr>
        <tr>
          <td className="client-history__cell">Telehealth Consent	</td>
          <td className="client-history__cell">
          Granted
          </td>
          <td className="client-history__cell client-history__actions">
            <a  className="client-history__link">View</a>
            <span> | </span>
            <a  className="client-history__link">Edit</a>
          </td>
        </tr>

        <tr>
          <td className="client-history__cell"> Consent for Release of Information 	</td>
          <td className="client-history__cell">
          Granted
          </td>
          <td className="client-history__cell client-history__actions">
            <a  className="client-history__link">View</a>
            <span> | </span>
            <a  className="client-history__link">Edit</a>
          </td>
        </tr>
        <tr>
          <td className="client-history__cell">Emergency Treatment Consent	</td>
          <td className="client-history__cell">
          Granted
          </td>
          <td className="client-history__cell client-history__actions">
            <a  className="client-history__link">View</a>
            <span> | </span>
            <a  className="client-history__link">Edit</a>
          </td>
        </tr>
        <tr>
          <td className="client-history__cell">Consent for SMS/Email communication	</td>
          <td className="client-history__cell">
          Granted
          </td>
          <td className="client-history__cell client-history__actions">
            <a  className="client-history__link">View</a>
            <span> | </span>
            <a  className="client-history__link">Edit</a>
          </td>
        </tr>
        <tr>
          <td className="client-history__cell">Emergency Treatment Consent	</td>
          <td className="client-history__cell">
          Granted
          </td>
          <td className="client-history__cell client-history__actions">
            <a  className="client-history__link">View</a>
            <span> | </span>
            <a  className="client-history__link">Edit</a>
          </td>
        </tr>
        <tr>
          <td className="client-history__cell">Medication Management Consent 	</td>
          <td className="client-history__cell">
          Granted
          </td>
          <td className="client-history__cell client-history__actions">
            <a  className="client-history__link">View</a>
            <span> | </span>
            <a  className="client-history__link">Edit</a>
          </td>
        </tr>
        <tr>
          <td className="client-history__cell">Financial Responsibility Consent	</td>
          <td className="client-history__cell">
          Granted
          </td>
          <td className="client-history__cell client-history__actions">
            <a  className="client-history__link">View</a>
            <span> | </span>
            <a  className="client-history__link">Edit</a>
          </td>
        </tr>
      </tbody>
    </table>
    <div className="client-history__buttons">
      <button className="client-history__button client-history__button--cancel">
        Cancel
      </button>
      <button className="client-history__button client-history__button--save">
        Save Changes
      </button>
    </div>
  </div>
  )
}

export default Consents