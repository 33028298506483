import React from 'react'

function PatinetSubscriptionPlan() {
  return (
    <>
    {/* Subscription Plans Section */}
    <div id="subscription-section">
      <h3>Subscription Plans</h3>
      <div className="enroll-item">
        <div className="plan-name">Basic Plan</div>
        <div className="plan-description">
          Bi-weekly one-on-one therapy sessions, 24/7 crisis support, wellness
          programs.
        </div>
        <div className="plan-price">$149/month</div>
        <button className="button" onclick="enrollInPlan('Basic Plan')">
          Enroll Now
        </button>
        <button className="button" onclick="showPlanDetails('Basic Plan')">
          View Details
        </button>
      </div>
      <div className="enroll-item">
        <div className="plan-name">Advanced Plan</div>
        <div className="plan-description">
          Everything in Basic plus access to exclusive webinars and fitness
          programs.
        </div>
        <div className="plan-price">$199/month</div>
        <button className="button" onclick="enrollInPlan('Advanced Plan')">
          Enroll Now
        </button>
        <button className="button" onclick="showPlanDetails('Advanced Plan')">
          View Details
        </button>
      </div>
      <div className="enroll-item">
        <div className="plan-name">Ultimate Plan</div>
        <div className="plan-description">
          Everything in Advanced plus access to Lifestyle Medicine.
        </div>
        <div className="plan-price">$299/month</div>
        <button className="button" onclick="enrollInPlan('Ultimate Plan')">
          Enroll Now
        </button>
        <button className="button" onclick="showPlanDetails('Ultimate Plan')">
          View Details
        </button>
      </div>
    </div>

    {/* Modals for Subscription Plans */}
    <div id="plan-details-modal" className="modal">
      <div className="modal-close" onclick="closeModal()">
        X
      </div>
      <h3 id="plan-title">Plan Details</h3>
      <p id="plan-description">Plan description goes here.</p>
    </div>
    <button className="submit-btn">Submit Payment</button>
  </>
  
  )
}

export default PatinetSubscriptionPlan