import React from 'react'

function PatinetPrivatePay() {
  return (
    <>
    {/* Private Pay Section */}
    <div id="private-pay-section" >
      <h3>Private Pay Options</h3>
      <div className="input-group">
        <label htmlFor="card_number">Card Number</label>
        <input
          type="text"
          id="card_number"
          name="card_number"
          placeholder="Enter your card number"
        />
      </div>
      <div className="input-group">
        <label htmlFor="exp_month">Exp Month</label>
        <select id="exp_month" name="exp_month">
          <option value={1}>01</option>
          <option value={2}>02</option>
          <option value={3}>03</option>
          <option value={4}>04</option>
          <option value={5}>05</option>
          <option value={6}>06</option>
          <option value={7}>07</option>
          <option value={8}>08</option>
          <option value={9}>09</option>
          <option value={10}>10</option>
          <option value={11}>11</option>
          <option value={12}>12</option>
        </select>
      </div>
      <div className="input-group">
        <label htmlFor="exp_year">Exp Year</label>
        <select id="exp_year" name="exp_year">
          <option value={2024}>2024</option>
          <option value={2025}>2025</option>
          <option value={2026}>2026</option>
          <option value={2027}>2027</option>
        </select>
      </div>
      <div className="input-group">
        <label htmlFor="cvv">CVV</label>
        <input type="text" id="cvv" name="cvv" placeholder="CVV" />
      </div>
    </div>
    <button className="submit-btn">Submit Payment</button>
  </>
  
  )
}

export default PatinetPrivatePay