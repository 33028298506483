import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Inputbox from "../../../Components/Inputbox";
import { Patientrequestupdates, PatientUpdate } from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  ContactName: Yup.string().required("Contact Name is required"),
  
  PhoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits") // Add a regex for phone number validation
    .required("Phone number is required"),
  
  Relation: Yup.string().required("Relation is required"),
  
  Email: Yup.string()
    .email("Invalid email address") // Ensures the email format is valid
    .required("Email is required")
});
function PatientEmergencyContacts({ info,getprofile }) {
  console.log(info,"info=====")
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),

  });
  useEffect(() => {
    if (info) {
      setValue("ContactName", info?.EmergencyContacts?.ContactName || "");
      setValue("PhoneNumber", info?.EmergencyContacts?.PhoneNumber || "");
      setValue("Relation", info?.EmergencyContacts?.Relation || "");
      setValue("Email", info?.EmergencyContacts?.Email || "");
    }
  }, [info, setValue]);

  const onSubmit = async (data) => {
    data.id = info?._id; // Add the id from 'info'

    const formattedData = {
      EmergencyContacts: {
        ContactName: data.ContactName,
        PhoneNumber: data.PhoneNumber,
        Relation: data.Relation,
        Email: data.Email,
      },
      id: data.id,
    };
    try {
      const response = await PatientUpdate(formattedData);
      ShowToast(response?.data?.message);
      getprofile()
     
    } catch (error) {
      // console.log(error)
    }
  };
  return (
    <div style={{ padding: 20 }}>
      <h2 className="notifications__title">Emergency Contacts</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-row">
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Contact Name"
              type="text"
              placeholder="Enter Contact Name"
              name="ContactName"
              register={register}
            />
            {errors.ContactName && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {errors.ContactName.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Phone Number"
              type="text"
              placeholder="Enter Phone Number"
              name="PhoneNumber"
              register={register}
            />
            {errors.PhoneNumber && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {errors.PhoneNumber.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Relation"
              type="text"
              placeholder="Enter Relation"
              name="Relation"
              register={register}
            />
            {errors.Relation && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {errors.Relation.message}
              </p>
            )}
          </div>
          <div style={{ width: "40px" }} />
          <div style={{ width: "100%" }}>
            <Inputbox
              label="Email"
              type="text"
              placeholder="Enter Email"
              name="Email"
              register={register}
            />
            {errors.Email && (
              <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                {errors.Email.message}
              </p>
            )}
          </div>
        </div>
        <div className="client-history__buttons">
          <button className="client-history__button client-history__button--cancel">
            Cancel
          </button>
          <button className="client-history__button client-history__button--save">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}

export default PatientEmergencyContacts;
