import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import Inputbox from '../../Components/Inputbox';
import { ClinicchangePassword } from '../../../api/helper';
import { ShowToast } from '../../../helpers/ToastService';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is required').min(5, 'Password must be at least 5 characters'),
  newPassword: Yup.string()
  .required("New Password is required")
  .min(12, "New Password must be at least 12 characters")
  .matches(/[A-Z]/, "New Password must contain at least one uppercase letter")
  .matches(/[a-z]/, "New Password must contain at least one lowercase letter")
  .matches(/[0-9]/, "New Password must contain at least one number")
  .matches(/[!@#$%^&*_,]/, "New Password must contain at least one special character (!, @, #, $, %, ^, &, *, _)")
  .notOneOf(["password", "12345", "qwerty"], "New Password is too common or easy to guess") // Add more common words as needed
  .test("no-repeated-characters", "New Password should not contain repeated characters like 'aaaa' or '1111'", value => {
    return !/(.)\1{3,}/.test(value); 
  })
  .test("no-simple-patterns", "Password should not contain common patterns like 'abcd' or '1234'", value => {
    const patterns = ["abcd", "1234", "qwerty"]; // Add more patterns as needed
    return !patterns.some(pattern => value.includes(pattern));
  }),

  confirmPassword: Yup.string()
  .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
  .required("Confirm password is required"),

  
});

const ClinicChangepass = ({ toggleChangePassword }) => {
  const userType = useSelector((state) => state.cookies.loginType);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    console.log('Form Data:', data);
   

    let response = {};
    try {
      if (userType === "Clinic") {
        response = await ClinicchangePassword(data);
      }
      ShowToast(response.data.message, "success");
      toggleChangePassword(); 
    } catch (error) {
      console.log(error, '===ee');
    }
  };

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
      <div className="HelpSupport_div" style={{ maxWidth: '40%' }}>
        <div className="Contact_Us_txt" style={{ marginBottom: 20 }}>Change Password</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 20 }}>
            <Inputbox label="Old Password" type="password" placeholder="**********" isPassword name="oldPassword" style={{ backgroundColor: '#D9D9D9' }} lebalstyle={{ color: 'black' }} register={register} />
            {errors.oldPassword && <p style={{ color: 'red',textAlign:'left' }}>{errors.oldPassword.message}</p>}
          </div>

          <div style={{ marginBottom: 20 }}>
            <Inputbox label="New Password" type="password" isPassword placeholder="Enter New Password" name="newPassword" style={{ backgroundColor: '#D9D9D9' }} lebalstyle={{ color: 'black' }} register={register} />
            {errors.newPassword && <p style={{ color: 'red',textAlign:'left'  }}>{errors.newPassword.message}</p>}
          </div>

          <div style={{ marginBottom: 20 }}>
            <Inputbox label="Confirm New Password" type="password" isPassword placeholder="Confirm New Password" name="confirmPassword" style={{ backgroundColor: '#D9D9D9' }} lebalstyle={{ color: 'black' }} register={register} />
            {errors.confirmPassword && <p style={{ color: 'red',textAlign:'left'  }}>{errors.confirmPassword.message}</p>}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
            <div className="cancelButton" onClick={toggleChangePassword}>Cancel</div>
            <button type="submit" className="savebuttonn"  >Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ClinicChangepass;
